// source: mlmbox/types/account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mlmbox_types_asset_pb = require('../../mlmbox/types/asset_pb.js');
goog.object.extend(proto, mlmbox_types_asset_pb);
var mlmbox_types_relationship_pb = require('../../mlmbox/types/relationship_pb.js');
goog.object.extend(proto, mlmbox_types_relationship_pb);
var mlmbox_types_google_authenticator_pb = require('../../mlmbox/types/google_authenticator_pb.js');
goog.object.extend(proto, mlmbox_types_google_authenticator_pb);
goog.exportSymbol('proto.mlmbox.types.Account', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Id.UniqueFieldCase', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Info', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Info.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile.Ava', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile.Ava.File', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile.Ava.File.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile.Ava.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Profile.Telegram', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.RefLink', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Statistics', null, global);
goog.exportSymbol('proto.mlmbox.types.Account.Username', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.BranchSelectStrategy', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.BranchSelectStrategy.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.BranchSelectStrategy.Request', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.Config', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.Info', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.Info.Item', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.Position', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.Position.Branch', null, global);
goog.exportSymbol('proto.mlmbox.types.Binary.View', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix.Info', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix.Position', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix.Reinvest', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix.Reinvest.Request', null, global);
goog.exportSymbol('proto.mlmbox.types.Matrix.View', null, global);
goog.exportSymbol('proto.mlmbox.types.PositionView', null, global);
goog.exportSymbol('proto.mlmbox.types.PositionView.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.PositionView.Team', null, global);
goog.exportSymbol('proto.mlmbox.types.PositionView.Team.QueryCase', null, global);
goog.exportSymbol('proto.mlmbox.types.Tree', null, global);
goog.exportSymbol('proto.mlmbox.types.Tree.Group', null, global);
goog.exportSymbol('proto.mlmbox.types.Tree.Mode', null, global);
goog.exportSymbol('proto.mlmbox.types.TreeAccount', null, global);
goog.exportSymbol('proto.mlmbox.types.TreeAccount.Entity', null, global);
goog.exportSymbol('proto.mlmbox.types.TreeAccount.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.TreeAccount.List', null, global);
goog.exportSymbol('proto.mlmbox.types.TreeAccount.Set', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Account.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Account, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.displayName = 'proto.mlmbox.types.Account';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.types.Account.Id.oneofGroups_);
};
goog.inherits(proto.mlmbox.types.Account.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Id.displayName = 'proto.mlmbox.types.Account.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.RefLink = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.RefLink, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.RefLink.displayName = 'proto.mlmbox.types.Account.RefLink';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Username = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Username, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Username.displayName = 'proto.mlmbox.types.Account.Username';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.displayName = 'proto.mlmbox.types.Account.Profile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile.Ava = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile.Ava, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.Ava.displayName = 'proto.mlmbox.types.Account.Profile.Ava';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile.Ava.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Account.Profile.Ava.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile.Ava.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.Ava.List.displayName = 'proto.mlmbox.types.Account.Profile.Ava.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile.Ava.File = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile.Ava.File, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.Ava.File.displayName = 'proto.mlmbox.types.Account.Profile.Ava.File';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile.Ava.File.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Account.Profile.Ava.File.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile.Ava.File.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.Ava.File.List.displayName = 'proto.mlmbox.types.Account.Profile.Ava.File.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Profile.Telegram = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Profile.Telegram, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Profile.Telegram.displayName = 'proto.mlmbox.types.Account.Profile.Telegram';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Statistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Account.Statistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Statistics.displayName = 'proto.mlmbox.types.Account.Statistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Account.Info.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Account.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Info.displayName = 'proto.mlmbox.types.Account.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Account.Info.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Account.Info.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Account.Info.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Account.Info.List.displayName = 'proto.mlmbox.types.Account.Info.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Tree = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Tree.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Tree, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Tree.displayName = 'proto.mlmbox.types.Tree';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Tree.Group = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Tree.Group, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Tree.Group.displayName = 'proto.mlmbox.types.Tree.Group';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.TreeAccount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.TreeAccount.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.TreeAccount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.TreeAccount.displayName = 'proto.mlmbox.types.TreeAccount';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.TreeAccount.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.TreeAccount.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.TreeAccount.Id.displayName = 'proto.mlmbox.types.TreeAccount.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.TreeAccount.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.TreeAccount.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.TreeAccount.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.TreeAccount.List.displayName = 'proto.mlmbox.types.TreeAccount.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.TreeAccount.Entity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.TreeAccount.Entity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.TreeAccount.Entity.displayName = 'proto.mlmbox.types.TreeAccount.Entity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.TreeAccount.Set = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.TreeAccount.Set, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.TreeAccount.Set.displayName = 'proto.mlmbox.types.TreeAccount.Set';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.PositionView = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.PositionView, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.PositionView.displayName = 'proto.mlmbox.types.PositionView';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.PositionView.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.PositionView.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.PositionView.Id.displayName = 'proto.mlmbox.types.PositionView.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.PositionView.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.types.PositionView.Team.oneofGroups_);
};
goog.inherits(proto.mlmbox.types.PositionView.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.PositionView.Team.displayName = 'proto.mlmbox.types.PositionView.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Matrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.displayName = 'proto.mlmbox.types.Matrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix.Reinvest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Matrix.Reinvest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.Reinvest.displayName = 'proto.mlmbox.types.Matrix.Reinvest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix.Reinvest.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Matrix.Reinvest.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.Reinvest.Request.displayName = 'proto.mlmbox.types.Matrix.Reinvest.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Matrix.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.Position.displayName = 'proto.mlmbox.types.Matrix.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix.View = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Matrix.View.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Matrix.View, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.View.displayName = 'proto.mlmbox.types.Matrix.View';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Matrix.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Matrix.Info.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Matrix.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Matrix.Info.displayName = 'proto.mlmbox.types.Matrix.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.displayName = 'proto.mlmbox.types.Binary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.BranchSelectStrategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.BranchSelectStrategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.BranchSelectStrategy.displayName = 'proto.mlmbox.types.Binary.BranchSelectStrategy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.BranchSelectStrategy.Request, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.BranchSelectStrategy.Request.displayName = 'proto.mlmbox.types.Binary.BranchSelectStrategy.Request';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.Config = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.Config, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.Config.displayName = 'proto.mlmbox.types.Binary.Config';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.Position = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.Position, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.Position.displayName = 'proto.mlmbox.types.Binary.Position';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.Position.Branch = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.Position.Branch, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.Position.Branch.displayName = 'proto.mlmbox.types.Binary.Position.Branch';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.View = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Binary.View.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Binary.View, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.View.displayName = 'proto.mlmbox.types.Binary.View';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Binary.Info.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Binary.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.Info.displayName = 'proto.mlmbox.types.Binary.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Binary.Info.Item = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Binary.Info.Item, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Binary.Info.Item.displayName = 'proto.mlmbox.types.Binary.Info.Item';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Account.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.mlmbox.types.Account.Info.toObject(includeInstance, f),
    googleAuthenticator: (f = msg.getGoogleAuthenticator()) && mlmbox_types_google_authenticator_pb.GoogleAuthenticator.toObject(includeInstance, f),
    treeAccountsList: jspb.Message.toObjectList(msg.getTreeAccountsList(),
    proto.mlmbox.types.TreeAccount.toObject, includeInstance),
    locale: jspb.Message.getFieldWithDefault(msg, 5, ""),
    isAdmin: jspb.Message.getBooleanFieldWithDefault(msg, 6, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account}
 */
proto.mlmbox.types.Account.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account;
  return proto.mlmbox.types.Account.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account}
 */
proto.mlmbox.types.Account.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Account.Info;
      reader.readMessage(value,proto.mlmbox.types.Account.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new mlmbox_types_google_authenticator_pb.GoogleAuthenticator;
      reader.readMessage(value,mlmbox_types_google_authenticator_pb.GoogleAuthenticator.deserializeBinaryFromReader);
      msg.setGoogleAuthenticator(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.TreeAccount;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader);
      msg.addTreeAccounts(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAdmin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getGoogleAuthenticator();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_types_google_authenticator_pb.GoogleAuthenticator.serializeBinaryToWriter
    );
  }
  f = message.getTreeAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.types.TreeAccount.serializeBinaryToWriter
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIsAdmin();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.types.Account.Id.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.mlmbox.types.Account.Id.UniqueFieldCase = {
  UNIQUE_FIELD_NOT_SET: 0,
  ACCOUNT_ID: 1,
  ACCOUNT_LOGIN: 2,
  REF_LINK_ID: 3
};

/**
 * @return {proto.mlmbox.types.Account.Id.UniqueFieldCase}
 */
proto.mlmbox.types.Account.Id.prototype.getUniqueFieldCase = function() {
  return /** @type {proto.mlmbox.types.Account.Id.UniqueFieldCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.types.Account.Id.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountLogin: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refLinkId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Id}
 */
proto.mlmbox.types.Account.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Id;
  return proto.mlmbox.types.Account.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Id}
 */
proto.mlmbox.types.Account.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefLinkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Id.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.setAccountId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.Account.Id.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.clearAccountId = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.Account.Id.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Id.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_login = 2;
 * @return {string}
 */
proto.mlmbox.types.Account.Id.prototype.getAccountLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.setAccountLogin = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.Account.Id.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.clearAccountLogin = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.Account.Id.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Id.prototype.hasAccountLogin = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string ref_link_id = 3;
 * @return {string}
 */
proto.mlmbox.types.Account.Id.prototype.getRefLinkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.setRefLinkId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.mlmbox.types.Account.Id.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Account.Id} returns this
 */
proto.mlmbox.types.Account.Id.prototype.clearRefLinkId = function() {
  return jspb.Message.setOneofField(this, 3, proto.mlmbox.types.Account.Id.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Id.prototype.hasRefLinkId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.RefLink.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.RefLink.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.RefLink} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.RefLink.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourceId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.RefLink}
 */
proto.mlmbox.types.Account.RefLink.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.RefLink;
  return proto.mlmbox.types.Account.RefLink.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.RefLink} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.RefLink}
 */
proto.mlmbox.types.Account.RefLink.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.RefLink.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.RefLink.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.RefLink} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.RefLink.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSourceId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 account_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.RefLink.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.RefLink} returns this
 */
proto.mlmbox.types.Account.RefLink.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 source_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Account.RefLink.prototype.getSourceId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.RefLink} returns this
 */
proto.mlmbox.types.Account.RefLink.prototype.setSourceId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Username.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Username.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Username} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Username.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    login: jspb.Message.getFieldWithDefault(msg, 2, ""),
    refLinkId: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Username}
 */
proto.mlmbox.types.Account.Username.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Username;
  return proto.mlmbox.types.Account.Username.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Username} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Username}
 */
proto.mlmbox.types.Account.Username.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogin(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefLinkId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Username.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Username.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Username} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Username.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getLogin();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRefLinkId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Username.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Username} returns this
 */
proto.mlmbox.types.Account.Username.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string login = 2;
 * @return {string}
 */
proto.mlmbox.types.Account.Username.prototype.getLogin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Username} returns this
 */
proto.mlmbox.types.Account.Username.prototype.setLogin = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ref_link_id = 3;
 * @return {string}
 */
proto.mlmbox.types.Account.Username.prototype.getRefLinkId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Username} returns this
 */
proto.mlmbox.types.Account.Username.prototype.setRefLinkId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.toObject = function(includeInstance, msg) {
  var f, obj = {
    firstName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    telegram: (f = msg.getTelegram()) && proto.mlmbox.types.Account.Profile.Telegram.toObject(includeInstance, f),
    ava: (f = msg.getAva()) && proto.mlmbox.types.Account.Profile.Ava.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile}
 */
proto.mlmbox.types.Account.Profile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile;
  return proto.mlmbox.types.Account.Profile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile}
 */
proto.mlmbox.types.Account.Profile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.Account.Profile.Telegram;
      reader.readMessage(value,proto.mlmbox.types.Account.Profile.Telegram.deserializeBinaryFromReader);
      msg.setTelegram(value);
      break;
    case 4:
      var value = new proto.mlmbox.types.Account.Profile.Ava;
      reader.readMessage(value,proto.mlmbox.types.Account.Profile.Ava.deserializeBinaryFromReader);
      msg.setAva(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFirstName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTelegram();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.types.Account.Profile.Telegram.serializeBinaryToWriter
    );
  }
  f = message.getAva();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.types.Account.Profile.Ava.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.Ava.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile.Ava} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile.Ava}
 */
proto.mlmbox.types.Account.Profile.Ava.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile.Ava;
  return proto.mlmbox.types.Account.Profile.Ava.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile.Ava} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile.Ava}
 */
proto.mlmbox.types.Account.Profile.Ava.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.Ava.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile.Ava} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollectionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Account.Profile.Ava.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.Ava.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.Ava.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile.Ava.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Account.Profile.Ava.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.List}
 */
proto.mlmbox.types.Account.Profile.Ava.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile.Ava.List;
  return proto.mlmbox.types.Account.Profile.Ava.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.List}
 */
proto.mlmbox.types.Account.Profile.Ava.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Account.Profile.Ava;
      reader.readMessage(value,proto.mlmbox.types.Account.Profile.Ava.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.Ava.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.Ava.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Account.Profile.Ava.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Ava items = 1;
 * @return {!Array<!proto.mlmbox.types.Account.Profile.Ava>}
 */
proto.mlmbox.types.Account.Profile.Ava.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Profile.Ava>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Account.Profile.Ava, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Profile.Ava>} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava.List} returns this
*/
proto.mlmbox.types.Account.Profile.Ava.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Profile.Ava=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Profile.Ava}
 */
proto.mlmbox.types.Account.Profile.Ava.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Account.Profile.Ava, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.List} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.Ava.File.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.File.toObject = function(includeInstance, msg) {
  var f, obj = {
    collectionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    entityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    value: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File}
 */
proto.mlmbox.types.Account.Profile.Ava.File.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile.Ava.File;
  return proto.mlmbox.types.Account.Profile.Ava.File.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File}
 */
proto.mlmbox.types.Account.Profile.Ava.File.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCollectionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEntityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.Ava.File.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.File.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCollectionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getEntityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.Ava.File.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Account.Profile.Ava.File.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File.List}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile.Ava.File.List;
  return proto.mlmbox.types.Account.Profile.Ava.File.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File.List}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Account.Profile.Ava.File;
      reader.readMessage(value,proto.mlmbox.types.Account.Profile.Ava.File.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.Ava.File.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Account.Profile.Ava.File.serializeBinaryToWriter
    );
  }
};


/**
 * repeated File items = 1;
 * @return {!Array<!proto.mlmbox.types.Account.Profile.Ava.File>}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Profile.Ava.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Account.Profile.Ava.File, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Profile.Ava.File>} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File.List} returns this
*/
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Profile.Ava.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File}
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Account.Profile.Ava.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File.List} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.File.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 collection_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.getCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.setCollectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 entity_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string value = 3;
 * @return {string}
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava.File} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.File.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 collection_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.getCollectionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.setCollectionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 entity_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.getEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Profile.Ava} returns this
 */
proto.mlmbox.types.Account.Profile.Ava.prototype.setEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Profile.Telegram.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Profile.Telegram} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Telegram.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Profile.Telegram}
 */
proto.mlmbox.types.Account.Profile.Telegram.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Profile.Telegram;
  return proto.mlmbox.types.Account.Profile.Telegram.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Profile.Telegram} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Profile.Telegram}
 */
proto.mlmbox.types.Account.Profile.Telegram.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Profile.Telegram.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Profile.Telegram} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Profile.Telegram.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint64 id = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Profile.Telegram} returns this
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Profile.Telegram} returns this
 */
proto.mlmbox.types.Account.Profile.Telegram.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string first_name = 1;
 * @return {string}
 */
proto.mlmbox.types.Account.Profile.prototype.getFirstName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Profile} returns this
 */
proto.mlmbox.types.Account.Profile.prototype.setFirstName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string last_name = 2;
 * @return {string}
 */
proto.mlmbox.types.Account.Profile.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account.Profile} returns this
 */
proto.mlmbox.types.Account.Profile.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Telegram telegram = 3;
 * @return {?proto.mlmbox.types.Account.Profile.Telegram}
 */
proto.mlmbox.types.Account.Profile.prototype.getTelegram = function() {
  return /** @type{?proto.mlmbox.types.Account.Profile.Telegram} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Profile.Telegram, 3));
};


/**
 * @param {?proto.mlmbox.types.Account.Profile.Telegram|undefined} value
 * @return {!proto.mlmbox.types.Account.Profile} returns this
*/
proto.mlmbox.types.Account.Profile.prototype.setTelegram = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Profile} returns this
 */
proto.mlmbox.types.Account.Profile.prototype.clearTelegram = function() {
  return this.setTelegram(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Profile.prototype.hasTelegram = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Ava ava = 4;
 * @return {?proto.mlmbox.types.Account.Profile.Ava}
 */
proto.mlmbox.types.Account.Profile.prototype.getAva = function() {
  return /** @type{?proto.mlmbox.types.Account.Profile.Ava} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Profile.Ava, 4));
};


/**
 * @param {?proto.mlmbox.types.Account.Profile.Ava|undefined} value
 * @return {!proto.mlmbox.types.Account.Profile} returns this
*/
proto.mlmbox.types.Account.Profile.prototype.setAva = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Profile} returns this
 */
proto.mlmbox.types.Account.Profile.prototype.clearAva = function() {
  return this.setAva(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Profile.prototype.hasAva = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Statistics.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Statistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Statistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Statistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    partnersQuantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    teamQuantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    marketingPartnersQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    marketingTeamQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Statistics}
 */
proto.mlmbox.types.Account.Statistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Statistics;
  return proto.mlmbox.types.Account.Statistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Statistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Statistics}
 */
proto.mlmbox.types.Account.Statistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartnersQuantity(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarketingPartnersQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarketingTeamQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Statistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Statistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Statistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Statistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPartnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTeamQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getMarketingPartnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getMarketingTeamQuantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional uint32 partners_quantity = 1;
 * @return {number}
 */
proto.mlmbox.types.Account.Statistics.prototype.getPartnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Statistics} returns this
 */
proto.mlmbox.types.Account.Statistics.prototype.setPartnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 team_quantity = 2;
 * @return {number}
 */
proto.mlmbox.types.Account.Statistics.prototype.getTeamQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Statistics} returns this
 */
proto.mlmbox.types.Account.Statistics.prototype.setTeamQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 marketing_partners_quantity = 3;
 * @return {number}
 */
proto.mlmbox.types.Account.Statistics.prototype.getMarketingPartnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Statistics} returns this
 */
proto.mlmbox.types.Account.Statistics.prototype.setMarketingPartnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 marketing_team_quantity = 4;
 * @return {number}
 */
proto.mlmbox.types.Account.Statistics.prototype.getMarketingTeamQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Statistics} returns this
 */
proto.mlmbox.types.Account.Statistics.prototype.setMarketingTeamQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Account.Info.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    profile: (f = msg.getProfile()) && proto.mlmbox.types.Account.Profile.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && proto.mlmbox.types.Account.Username.toObject(includeInstance, f),
    referral: (f = msg.getReferral()) && proto.mlmbox.types.Account.Username.toObject(includeInstance, f),
    relationship: (f = msg.getRelationship()) && mlmbox_types_relationship_pb.Relationship.toObject(includeInstance, f),
    registrationAt: jspb.Message.getFieldWithDefault(msg, 5, 0),
    marketingStartedAt: jspb.Message.getFieldWithDefault(msg, 6, 0),
    treeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 7, 0),
    referralBranch: jspb.Message.getFieldWithDefault(msg, 8, 0),
    statistics: (f = msg.getStatistics()) && proto.mlmbox.types.Account.Statistics.toObject(includeInstance, f),
    channelsList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Account.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Info;
  return proto.mlmbox.types.Account.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Account.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Account.Profile;
      reader.readMessage(value,proto.mlmbox.types.Account.Profile.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Account.Username;
      reader.readMessage(value,proto.mlmbox.types.Account.Username.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.Account.Username;
      reader.readMessage(value,proto.mlmbox.types.Account.Username.deserializeBinaryFromReader);
      msg.setReferral(value);
      break;
    case 4:
      var value = new mlmbox_types_relationship_pb.Relationship;
      reader.readMessage(value,mlmbox_types_relationship_pb.Relationship.deserializeBinaryFromReader);
      msg.setRelationship(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRegistrationAt(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMarketingStartedAt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTreeIdsBitMask(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setReferralBranch(value);
      break;
    case 9:
      var value = new proto.mlmbox.types.Account.Statistics;
      reader.readMessage(value,proto.mlmbox.types.Account.Statistics.deserializeBinaryFromReader);
      msg.setStatistics(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addChannels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.Account.Profile.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Account.Username.serializeBinaryToWriter
    );
  }
  f = message.getReferral();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.types.Account.Username.serializeBinaryToWriter
    );
  }
  f = message.getRelationship();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      mlmbox_types_relationship_pb.Relationship.serializeBinaryToWriter
    );
  }
  f = message.getRegistrationAt();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getMarketingStartedAt();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTreeIdsBitMask();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getReferralBranch();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getStatistics();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.mlmbox.types.Account.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getChannelsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      10,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Account.Info.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Account.Info.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Account.Info.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Account.Info.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Info.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Account.Info.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Account.Info.List}
 */
proto.mlmbox.types.Account.Info.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Account.Info.List;
  return proto.mlmbox.types.Account.Info.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Account.Info.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Account.Info.List}
 */
proto.mlmbox.types.Account.Info.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Account.Info;
      reader.readMessage(value,proto.mlmbox.types.Account.Info.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Account.Info.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Account.Info.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Account.Info.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Account.Info.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Account.Info.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Info items = 1;
 * @return {!Array<!proto.mlmbox.types.Account.Info>}
 */
proto.mlmbox.types.Account.Info.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Account.Info, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Info>} value
 * @return {!proto.mlmbox.types.Account.Info.List} returns this
*/
proto.mlmbox.types.Account.Info.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Account.Info.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Account.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Account.Info.List} returns this
 */
proto.mlmbox.types.Account.Info.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional Profile profile = 1;
 * @return {?proto.mlmbox.types.Account.Profile}
 */
proto.mlmbox.types.Account.Info.prototype.getProfile = function() {
  return /** @type{?proto.mlmbox.types.Account.Profile} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Profile, 1));
};


/**
 * @param {?proto.mlmbox.types.Account.Profile|undefined} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
*/
proto.mlmbox.types.Account.Info.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Info.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Username account = 2;
 * @return {?proto.mlmbox.types.Account.Username}
 */
proto.mlmbox.types.Account.Info.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.types.Account.Username} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Username, 2));
};


/**
 * @param {?proto.mlmbox.types.Account.Username|undefined} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
*/
proto.mlmbox.types.Account.Info.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Info.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Username referral = 3;
 * @return {?proto.mlmbox.types.Account.Username}
 */
proto.mlmbox.types.Account.Info.prototype.getReferral = function() {
  return /** @type{?proto.mlmbox.types.Account.Username} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Username, 3));
};


/**
 * @param {?proto.mlmbox.types.Account.Username|undefined} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
*/
proto.mlmbox.types.Account.Info.prototype.setReferral = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearReferral = function() {
  return this.setReferral(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Info.prototype.hasReferral = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Relationship relationship = 4;
 * @return {?proto.mlmbox.types.Relationship}
 */
proto.mlmbox.types.Account.Info.prototype.getRelationship = function() {
  return /** @type{?proto.mlmbox.types.Relationship} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_relationship_pb.Relationship, 4));
};


/**
 * @param {?proto.mlmbox.types.Relationship|undefined} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
*/
proto.mlmbox.types.Account.Info.prototype.setRelationship = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearRelationship = function() {
  return this.setRelationship(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Info.prototype.hasRelationship = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional uint32 registration_at = 5;
 * @return {number}
 */
proto.mlmbox.types.Account.Info.prototype.getRegistrationAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.setRegistrationAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 marketing_started_at = 6;
 * @return {number}
 */
proto.mlmbox.types.Account.Info.prototype.getMarketingStartedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.setMarketingStartedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional int32 tree_ids_bit_mask = 7;
 * @return {number}
 */
proto.mlmbox.types.Account.Info.prototype.getTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.setTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 referral_branch = 8;
 * @return {number}
 */
proto.mlmbox.types.Account.Info.prototype.getReferralBranch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.setReferralBranch = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional Statistics statistics = 9;
 * @return {?proto.mlmbox.types.Account.Statistics}
 */
proto.mlmbox.types.Account.Info.prototype.getStatistics = function() {
  return /** @type{?proto.mlmbox.types.Account.Statistics} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Statistics, 9));
};


/**
 * @param {?proto.mlmbox.types.Account.Statistics|undefined} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
*/
proto.mlmbox.types.Account.Info.prototype.setStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearStatistics = function() {
  return this.setStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.Info.prototype.hasStatistics = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * repeated uint32 channels = 10;
 * @return {!Array<number>}
 */
proto.mlmbox.types.Account.Info.prototype.getChannelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.setChannelsList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.addChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Account.Info} returns this
 */
proto.mlmbox.types.Account.Info.prototype.clearChannelsList = function() {
  return this.setChannelsList([]);
};


/**
 * optional Info info = 1;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Account.prototype.getInfo = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Account.Info, 1));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.types.Account} returns this
*/
proto.mlmbox.types.Account.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account} returns this
 */
proto.mlmbox.types.Account.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional GoogleAuthenticator google_authenticator = 2;
 * @return {?proto.mlmbox.types.GoogleAuthenticator}
 */
proto.mlmbox.types.Account.prototype.getGoogleAuthenticator = function() {
  return /** @type{?proto.mlmbox.types.GoogleAuthenticator} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_google_authenticator_pb.GoogleAuthenticator, 2));
};


/**
 * @param {?proto.mlmbox.types.GoogleAuthenticator|undefined} value
 * @return {!proto.mlmbox.types.Account} returns this
*/
proto.mlmbox.types.Account.prototype.setGoogleAuthenticator = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Account} returns this
 */
proto.mlmbox.types.Account.prototype.clearGoogleAuthenticator = function() {
  return this.setGoogleAuthenticator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Account.prototype.hasGoogleAuthenticator = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated TreeAccount tree_accounts = 3;
 * @return {!Array<!proto.mlmbox.types.TreeAccount>}
 */
proto.mlmbox.types.Account.prototype.getTreeAccountsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount, 3));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount>} value
 * @return {!proto.mlmbox.types.Account} returns this
*/
proto.mlmbox.types.Account.prototype.setTreeAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.Account.prototype.addTreeAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.types.TreeAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Account} returns this
 */
proto.mlmbox.types.Account.prototype.clearTreeAccountsList = function() {
  return this.setTreeAccountsList([]);
};


/**
 * optional string locale = 5;
 * @return {string}
 */
proto.mlmbox.types.Account.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Account} returns this
 */
proto.mlmbox.types.Account.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool is_admin = 6;
 * @return {boolean}
 */
proto.mlmbox.types.Account.prototype.getIsAdmin = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Account} returns this
 */
proto.mlmbox.types.Account.prototype.setIsAdmin = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Tree.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Tree.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Tree.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Tree} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Tree.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    groupId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    positionsQuantity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    defaultPositionWidth: jspb.Message.getFieldWithDefault(msg, 5, 0),
    amount: (f = msg.getAmount()) && mlmbox_types_asset_pb.Asset.Amount.toObject(includeInstance, f),
    bookingEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    treeModeId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    requireTreeIdsList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Tree}
 */
proto.mlmbox.types.Tree.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Tree;
  return proto.mlmbox.types.Tree.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Tree} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Tree}
 */
proto.mlmbox.types.Tree.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setGroupId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionsQuantity(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDefaultPositionWidth(value);
      break;
    case 6:
      var value = new mlmbox_types_asset_pb.Asset.Amount;
      reader.readMessage(value,mlmbox_types_asset_pb.Asset.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBookingEnabled(value);
      break;
    case 8:
      var value = /** @type {!proto.mlmbox.types.Tree.Mode} */ (reader.readEnum());
      msg.setTreeModeId(value);
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRequireTreeIds(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Tree.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Tree.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Tree} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Tree.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGroupId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPositionsQuantity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getDefaultPositionWidth();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      mlmbox_types_asset_pb.Asset.Amount.serializeBinaryToWriter
    );
  }
  f = message.getBookingEnabled();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getTreeModeId();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getRequireTreeIdsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      9,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.types.Tree.Mode = {
  UNKNOWN: 0,
  MATRIX: 1,
  BINARY: 2
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Tree.Group.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Tree.Group.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Tree.Group} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Tree.Group.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    title: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Tree.Group}
 */
proto.mlmbox.types.Tree.Group.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Tree.Group;
  return proto.mlmbox.types.Tree.Group.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Tree.Group} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Tree.Group}
 */
proto.mlmbox.types.Tree.Group.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Tree.Group.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Tree.Group.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Tree.Group} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Tree.Group.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.mlmbox.types.Tree.Group.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Tree.Group} returns this
 */
proto.mlmbox.types.Tree.Group.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.mlmbox.types.Tree.Group.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Tree.Group} returns this
 */
proto.mlmbox.types.Tree.Group.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.mlmbox.types.Tree.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.mlmbox.types.Tree.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 group_id = 3;
 * @return {number}
 */
proto.mlmbox.types.Tree.prototype.getGroupId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setGroupId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 positions_quantity = 4;
 * @return {number}
 */
proto.mlmbox.types.Tree.prototype.getPositionsQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setPositionsQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 default_position_width = 5;
 * @return {number}
 */
proto.mlmbox.types.Tree.prototype.getDefaultPositionWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setDefaultPositionWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional Asset.Amount amount = 6;
 * @return {?proto.mlmbox.types.Asset.Amount}
 */
proto.mlmbox.types.Tree.prototype.getAmount = function() {
  return /** @type{?proto.mlmbox.types.Asset.Amount} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_asset_pb.Asset.Amount, 6));
};


/**
 * @param {?proto.mlmbox.types.Asset.Amount|undefined} value
 * @return {!proto.mlmbox.types.Tree} returns this
*/
proto.mlmbox.types.Tree.prototype.setAmount = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Tree.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional bool booking_enabled = 7;
 * @return {boolean}
 */
proto.mlmbox.types.Tree.prototype.getBookingEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setBookingEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional Mode tree_mode_id = 8;
 * @return {!proto.mlmbox.types.Tree.Mode}
 */
proto.mlmbox.types.Tree.prototype.getTreeModeId = function() {
  return /** @type {!proto.mlmbox.types.Tree.Mode} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.mlmbox.types.Tree.Mode} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setTreeModeId = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};


/**
 * repeated uint32 require_tree_ids = 9;
 * @return {!Array<number>}
 */
proto.mlmbox.types.Tree.prototype.getRequireTreeIdsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.setRequireTreeIdsList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.addRequireTreeIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Tree} returns this
 */
proto.mlmbox.types.Tree.prototype.clearRequireTreeIdsList = function() {
  return this.setRequireTreeIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.TreeAccount.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.TreeAccount.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.TreeAccount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.TreeAccount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activatedQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activatedCapacity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    poolValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bookingQuantity: jspb.Message.getFieldWithDefault(msg, 6, 0),
    entitiesList: jspb.Message.toObjectList(msg.getEntitiesList(),
    proto.mlmbox.types.TreeAccount.Entity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.TreeAccount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.TreeAccount;
  return proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.TreeAccount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivatedQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivatedCapacity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoolValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBookingQuantity(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.TreeAccount.Entity;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader);
      msg.addEntities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.TreeAccount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.TreeAccount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.TreeAccount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActivatedQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActivatedCapacity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPoolValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBookingQuantity();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.TreeAccount.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.TreeAccount.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.TreeAccount.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.TreeAccount.Id}
 */
proto.mlmbox.types.TreeAccount.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.TreeAccount.Id;
  return proto.mlmbox.types.TreeAccount.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.TreeAccount.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.TreeAccount.Id}
 */
proto.mlmbox.types.TreeAccount.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.TreeAccount.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.TreeAccount.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.TreeAccount.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Id.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Id} returns this
 */
proto.mlmbox.types.TreeAccount.Id.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 account_id = 2;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Id.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Id} returns this
 */
proto.mlmbox.types.TreeAccount.Id.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.TreeAccount.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.TreeAccount.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.TreeAccount.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.TreeAccount.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.TreeAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.TreeAccount.List}
 */
proto.mlmbox.types.TreeAccount.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.TreeAccount.List;
  return proto.mlmbox.types.TreeAccount.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.TreeAccount.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.TreeAccount.List}
 */
proto.mlmbox.types.TreeAccount.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.TreeAccount;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.TreeAccount.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.TreeAccount.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.TreeAccount.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.TreeAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TreeAccount items = 1;
 * @return {!Array<!proto.mlmbox.types.TreeAccount>}
 */
proto.mlmbox.types.TreeAccount.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount>} value
 * @return {!proto.mlmbox.types.TreeAccount.List} returns this
*/
proto.mlmbox.types.TreeAccount.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.TreeAccount.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.TreeAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.TreeAccount.List} returns this
 */
proto.mlmbox.types.TreeAccount.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.TreeAccount.Entity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.TreeAccount.Entity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Entity.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    treeChainId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    treeEntityId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    treeAccountEntityId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    filled2w2lBitMask: jspb.Message.getFieldWithDefault(msg, 5, 0),
    filled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    viewAccess: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.TreeAccount.Entity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.TreeAccount.Entity;
  return proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.TreeAccount.Entity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeChainId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeEntityId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeAccountEntityId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFilled2w2lBitMask(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilled(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setViewAccess(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.TreeAccount.Entity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTreeChainId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTreeEntityId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTreeAccountEntityId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getFilled2w2lBitMask();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getFilled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getViewAccess();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 tree_chain_id = 2;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getTreeChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setTreeChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 tree_entity_id = 3;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getTreeEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setTreeEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 tree_account_entity_id = 4;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getTreeAccountEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setTreeAccountEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 filled_2w2l_bit_mask = 5;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getFilled2w2lBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setFilled2w2lBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bool filled = 6;
 * @return {boolean}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getFilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setFilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool view_access = 7;
 * @return {boolean}
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.getViewAccess = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.TreeAccount.Entity} returns this
 */
proto.mlmbox.types.TreeAccount.Entity.prototype.setViewAccess = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.TreeAccount.Set.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.TreeAccount.Set} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Set.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    quantity: jspb.Message.getFieldWithDefault(msg, 2, 0),
    activatedQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0),
    activatedCapacity: jspb.Message.getFieldWithDefault(msg, 4, 0),
    poolValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    bookingQuantity: jspb.Message.getFieldWithDefault(msg, 6, 0),
    entity: (f = msg.getEntity()) && proto.mlmbox.types.TreeAccount.Entity.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.TreeAccount.Set}
 */
proto.mlmbox.types.TreeAccount.Set.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.TreeAccount.Set;
  return proto.mlmbox.types.TreeAccount.Set.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.TreeAccount.Set} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.TreeAccount.Set}
 */
proto.mlmbox.types.TreeAccount.Set.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantity(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivatedQuantity(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setActivatedCapacity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoolValue(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBookingQuantity(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.TreeAccount.Entity;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader);
      msg.setEntity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.TreeAccount.Set.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.TreeAccount.Set} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.TreeAccount.Set.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQuantity();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getActivatedQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getActivatedCapacity();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPoolValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBookingQuantity();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getEntity();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 activated_quantity = 3;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getActivatedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setActivatedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 activated_capacity = 4;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getActivatedCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setActivatedCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string pool_value = 5;
 * @return {string}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getPoolValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setPoolValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 booking_quantity = 6;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getBookingQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.setBookingQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional Entity entity = 7;
 * @return {?proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.getEntity = function() {
  return /** @type{?proto.mlmbox.types.TreeAccount.Entity} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.TreeAccount.Entity, 7));
};


/**
 * @param {?proto.mlmbox.types.TreeAccount.Entity|undefined} value
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
*/
proto.mlmbox.types.TreeAccount.Set.prototype.setEntity = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.TreeAccount.Set} returns this
 */
proto.mlmbox.types.TreeAccount.Set.prototype.clearEntity = function() {
  return this.setEntity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.TreeAccount.Set.prototype.hasEntity = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 quantity = 2;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.prototype.getQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 activated_quantity = 3;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.prototype.getActivatedQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setActivatedQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 activated_capacity = 4;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.prototype.getActivatedCapacity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setActivatedCapacity = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string pool_value = 5;
 * @return {string}
 */
proto.mlmbox.types.TreeAccount.prototype.getPoolValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setPoolValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional uint32 booking_quantity = 6;
 * @return {number}
 */
proto.mlmbox.types.TreeAccount.prototype.getBookingQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.setBookingQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated Entity entities = 7;
 * @return {!Array<!proto.mlmbox.types.TreeAccount.Entity>}
 */
proto.mlmbox.types.TreeAccount.prototype.getEntitiesList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount.Entity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount.Entity, 7));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount.Entity>} value
 * @return {!proto.mlmbox.types.TreeAccount} returns this
*/
proto.mlmbox.types.TreeAccount.prototype.setEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount.Entity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.TreeAccount.prototype.addEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mlmbox.types.TreeAccount.Entity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.TreeAccount} returns this
 */
proto.mlmbox.types.TreeAccount.prototype.clearEntitiesList = function() {
  return this.setEntitiesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.PositionView.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.PositionView.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.PositionView} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.PositionView}
 */
proto.mlmbox.types.PositionView.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.PositionView;
  return proto.mlmbox.types.PositionView.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.PositionView} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.PositionView}
 */
proto.mlmbox.types.PositionView.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.PositionView.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.PositionView.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.PositionView} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.PositionView.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.PositionView.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.PositionView.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.PositionView.Id}
 */
proto.mlmbox.types.PositionView.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.PositionView.Id;
  return proto.mlmbox.types.PositionView.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.PositionView.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.PositionView.Id}
 */
proto.mlmbox.types.PositionView.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.PositionView.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.PositionView.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.PositionView.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 view_position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.PositionView.Id.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.PositionView.Id} returns this
 */
proto.mlmbox.types.PositionView.Id.prototype.setViewPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.types.PositionView.Team.oneofGroups_ = [[2,3]];

/**
 * @enum {number}
 */
proto.mlmbox.types.PositionView.Team.QueryCase = {
  QUERY_NOT_SET: 0,
  VIEW_POSITION_ID: 2,
  TREE_ACCOUNT_ID: 3
};

/**
 * @return {proto.mlmbox.types.PositionView.Team.QueryCase}
 */
proto.mlmbox.types.PositionView.Team.prototype.getQueryCase = function() {
  return /** @type {proto.mlmbox.types.PositionView.Team.QueryCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.types.PositionView.Team.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.PositionView.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.PositionView.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.PositionView.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewLevel: jspb.Message.getFieldWithDefault(msg, 1, 0),
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    treeAccountId: (f = msg.getTreeAccountId()) && proto.mlmbox.types.TreeAccount.Id.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.PositionView.Team}
 */
proto.mlmbox.types.PositionView.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.PositionView.Team;
  return proto.mlmbox.types.PositionView.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.PositionView.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.PositionView.Team}
 */
proto.mlmbox.types.PositionView.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewLevel(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.TreeAccount.Id;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.Id.deserializeBinaryFromReader);
      msg.setTreeAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.PositionView.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.PositionView.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.PositionView.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.PositionView.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewLevel();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTreeAccountId();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.types.TreeAccount.Id.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 view_level = 1;
 * @return {number}
 */
proto.mlmbox.types.PositionView.Team.prototype.getViewLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.PositionView.Team} returns this
 */
proto.mlmbox.types.PositionView.Team.prototype.setViewLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 view_position_id = 2;
 * @return {number}
 */
proto.mlmbox.types.PositionView.Team.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.PositionView.Team} returns this
 */
proto.mlmbox.types.PositionView.Team.prototype.setViewPositionId = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.PositionView.Team.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.PositionView.Team} returns this
 */
proto.mlmbox.types.PositionView.Team.prototype.clearViewPositionId = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.PositionView.Team.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.PositionView.Team.prototype.hasViewPositionId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional TreeAccount.Id tree_account_id = 3;
 * @return {?proto.mlmbox.types.TreeAccount.Id}
 */
proto.mlmbox.types.PositionView.Team.prototype.getTreeAccountId = function() {
  return /** @type{?proto.mlmbox.types.TreeAccount.Id} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.TreeAccount.Id, 3));
};


/**
 * @param {?proto.mlmbox.types.TreeAccount.Id|undefined} value
 * @return {!proto.mlmbox.types.PositionView.Team} returns this
*/
proto.mlmbox.types.PositionView.Team.prototype.setTreeAccountId = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mlmbox.types.PositionView.Team.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.PositionView.Team} returns this
 */
proto.mlmbox.types.PositionView.Team.prototype.clearTreeAccountId = function() {
  return this.setTreeAccountId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.PositionView.Team.prototype.hasTreeAccountId = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix}
 */
proto.mlmbox.types.Matrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix;
  return proto.mlmbox.types.Matrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix}
 */
proto.mlmbox.types.Matrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.Reinvest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.Reinvest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix.Reinvest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Reinvest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix.Reinvest}
 */
proto.mlmbox.types.Matrix.Reinvest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix.Reinvest;
  return proto.mlmbox.types.Matrix.Reinvest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix.Reinvest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix.Reinvest}
 */
proto.mlmbox.types.Matrix.Reinvest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.Reinvest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.Reinvest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix.Reinvest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Reinvest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.Reinvest.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix.Reinvest.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Reinvest.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    reinvestEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix.Reinvest.Request}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix.Reinvest.Request;
  return proto.mlmbox.types.Matrix.Reinvest.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix.Reinvest.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix.Reinvest.Request}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReinvestEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.Reinvest.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix.Reinvest.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Reinvest.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getReinvestEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Reinvest.Request} returns this
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool reinvest_enabled = 2;
 * @return {boolean}
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.getReinvestEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Matrix.Reinvest.Request} returns this
 */
proto.mlmbox.types.Matrix.Reinvest.Request.prototype.setReinvestEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionRefId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionRefBranch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    positionWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    treeChainId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    treeEntityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    treeAccountEntityId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    partnersQuantity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    teamQuantity: jspb.Message.getFieldWithDefault(msg, 10, 0),
    poolValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    reinvestEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    filled: jspb.Message.getBooleanFieldWithDefault(msg, 13, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix.Position}
 */
proto.mlmbox.types.Matrix.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix.Position;
  return proto.mlmbox.types.Matrix.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix.Position}
 */
proto.mlmbox.types.Matrix.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefBranch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeChainId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeEntityId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeAccountEntityId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartnersQuantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamQuantity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPoolValue(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReinvestEnabled(value);
      break;
    case 13:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFilled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPositionRefId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPositionRefBranch();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPositionWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTreeChainId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTreeEntityId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTreeAccountEntityId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPartnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTeamQuantity();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPoolValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getReinvestEnabled();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getFilled();
  if (f) {
    writer.writeBool(
      13,
      f
    );
  }
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 position_ref_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPositionRefId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPositionRefId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 position_ref_branch = 3;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPositionRefBranch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPositionRefBranch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 position_width = 4;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPositionWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPositionWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 account_id = 5;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 tree_chain_id = 6;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getTreeChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setTreeChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 tree_entity_id = 7;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getTreeEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setTreeEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 tree_account_entity_id = 8;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getTreeAccountEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setTreeAccountEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 partners_quantity = 9;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPartnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPartnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 team_quantity = 10;
 * @return {number}
 */
proto.mlmbox.types.Matrix.Position.prototype.getTeamQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setTeamQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string pool_value = 11;
 * @return {string}
 */
proto.mlmbox.types.Matrix.Position.prototype.getPoolValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setPoolValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional bool reinvest_enabled = 12;
 * @return {boolean}
 */
proto.mlmbox.types.Matrix.Position.prototype.getReinvestEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setReinvestEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional bool filled = 13;
 * @return {boolean}
 */
proto.mlmbox.types.Matrix.Position.prototype.getFilled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 13, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Matrix.Position} returns this
 */
proto.mlmbox.types.Matrix.Position.prototype.setFilled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 13, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Matrix.View.repeatedFields_ = [6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.View.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.View.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix.View} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.View.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    selfPositionId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    selfTreeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 3, 0),
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 4, 0),
    viewTreeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 5, 0),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.mlmbox.types.Matrix.Position.toObject, includeInstance),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.mlmbox.types.Account.Info.toObject, includeInstance),
    viewTreeAccountEntitiesList: jspb.Message.toObjectList(msg.getViewTreeAccountEntitiesList(),
    proto.mlmbox.types.TreeAccount.Entity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix.View}
 */
proto.mlmbox.types.Matrix.View.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix.View;
  return proto.mlmbox.types.Matrix.View.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix.View} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix.View}
 */
proto.mlmbox.types.Matrix.View.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelfPositionId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelfTreeIdsBitMask(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewTreeIdsBitMask(value);
      break;
    case 6:
      var value = new proto.mlmbox.types.Matrix.Position;
      reader.readMessage(value,proto.mlmbox.types.Matrix.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.Account.Info;
      reader.readMessage(value,proto.mlmbox.types.Account.Info.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 8:
      var value = new proto.mlmbox.types.TreeAccount.Entity;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader);
      msg.addViewTreeAccountEntities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.View.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.View.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix.View} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.View.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSelfPositionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSelfTreeIdsBitMask();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getViewPositionId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getViewTreeIdsBitMask();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      proto.mlmbox.types.Matrix.Position.serializeBinaryToWriter
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mlmbox.types.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getViewTreeAccountEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Matrix.View.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 self_position_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Matrix.View.prototype.getSelfPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.setSelfPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 self_tree_ids_bit_mask = 3;
 * @return {number}
 */
proto.mlmbox.types.Matrix.View.prototype.getSelfTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.setSelfTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 view_position_id = 4;
 * @return {number}
 */
proto.mlmbox.types.Matrix.View.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.setViewPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 view_tree_ids_bit_mask = 5;
 * @return {number}
 */
proto.mlmbox.types.Matrix.View.prototype.getViewTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.setViewTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * repeated Position positions = 6;
 * @return {!Array<!proto.mlmbox.types.Matrix.Position>}
 */
proto.mlmbox.types.Matrix.View.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Matrix.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Matrix.Position, 6));
};


/**
 * @param {!Array<!proto.mlmbox.types.Matrix.Position>} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
*/
proto.mlmbox.types.Matrix.View.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.mlmbox.types.Matrix.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Matrix.Position}
 */
proto.mlmbox.types.Matrix.View.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.mlmbox.types.Matrix.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated Account.Info accounts = 7;
 * @return {!Array<!proto.mlmbox.types.Account.Info>}
 */
proto.mlmbox.types.Matrix.View.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Account.Info, 7));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Info>} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
*/
proto.mlmbox.types.Matrix.View.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Matrix.View.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mlmbox.types.Account.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * repeated TreeAccount.Entity view_tree_account_entities = 8;
 * @return {!Array<!proto.mlmbox.types.TreeAccount.Entity>}
 */
proto.mlmbox.types.Matrix.View.prototype.getViewTreeAccountEntitiesList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount.Entity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount.Entity, 8));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount.Entity>} value
 * @return {!proto.mlmbox.types.Matrix.View} returns this
*/
proto.mlmbox.types.Matrix.View.prototype.setViewTreeAccountEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount.Entity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.Matrix.View.prototype.addViewTreeAccountEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.mlmbox.types.TreeAccount.Entity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Matrix.View} returns this
 */
proto.mlmbox.types.Matrix.View.prototype.clearViewTreeAccountEntitiesList = function() {
  return this.setViewTreeAccountEntitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Matrix.Info.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Matrix.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Matrix.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Matrix.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.TreeAccount.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Matrix.Info}
 */
proto.mlmbox.types.Matrix.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Matrix.Info;
  return proto.mlmbox.types.Matrix.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Matrix.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Matrix.Info}
 */
proto.mlmbox.types.Matrix.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.TreeAccount;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Matrix.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Matrix.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Matrix.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Matrix.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.TreeAccount.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TreeAccount items = 1;
 * @return {!Array<!proto.mlmbox.types.TreeAccount>}
 */
proto.mlmbox.types.Matrix.Info.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount>} value
 * @return {!proto.mlmbox.types.Matrix.Info} returns this
*/
proto.mlmbox.types.Matrix.Info.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.Matrix.Info.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.TreeAccount, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Matrix.Info} returns this
 */
proto.mlmbox.types.Matrix.Info.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary}
 */
proto.mlmbox.types.Binary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary;
  return proto.mlmbox.types.Binary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary}
 */
proto.mlmbox.types.Binary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.BranchSelectStrategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    branchSelectStrategyId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.BranchSelectStrategy;
  return proto.mlmbox.types.Binary.BranchSelectStrategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (reader.readEnum());
      msg.setBranchSelectStrategyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.BranchSelectStrategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBranchSelectStrategyId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Id = {
  UNKNOWN: 0,
  LEFT: 1,
  RIGHT: 2,
  EVENLY: 3
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.BranchSelectStrategy.Request.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    branchSelectStrategyId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.BranchSelectStrategy.Request;
  return proto.mlmbox.types.Binary.BranchSelectStrategy.Request.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (reader.readEnum());
      msg.setBranchSelectStrategyId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.BranchSelectStrategy.Request.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBranchSelectStrategyId();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request} returns this
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Id branch_select_strategy_id = 2;
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.getBranchSelectStrategyId = function() {
  return /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} value
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Request} returns this
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.Request.prototype.setBranchSelectStrategyId = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Id branch_select_strategy_id = 1;
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id}
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.prototype.getBranchSelectStrategyId = function() {
  return /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} value
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy} returns this
 */
proto.mlmbox.types.Binary.BranchSelectStrategy.prototype.setBranchSelectStrategyId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.Config.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.Config.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.Config} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Config.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusBinaryPercent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bonusMatchingPercent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    treeEntitiesQuantity: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.Config}
 */
proto.mlmbox.types.Binary.Config.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.Config;
  return proto.mlmbox.types.Binary.Config.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.Config} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.Config}
 */
proto.mlmbox.types.Binary.Config.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusBinaryPercent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBonusMatchingPercent(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeEntitiesQuantity(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.Config.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.Config.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.Config} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Config.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusBinaryPercent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBonusMatchingPercent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTreeEntitiesQuantity();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional string bonus_binary_percent = 1;
 * @return {string}
 */
proto.mlmbox.types.Binary.Config.prototype.getBonusBinaryPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Binary.Config} returns this
 */
proto.mlmbox.types.Binary.Config.prototype.setBonusBinaryPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bonus_matching_percent = 2;
 * @return {string}
 */
proto.mlmbox.types.Binary.Config.prototype.getBonusMatchingPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Binary.Config} returns this
 */
proto.mlmbox.types.Binary.Config.prototype.setBonusMatchingPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 tree_entities_quantity = 3;
 * @return {number}
 */
proto.mlmbox.types.Binary.Config.prototype.getTreeEntitiesQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Config} returns this
 */
proto.mlmbox.types.Binary.Config.prototype.setTreeEntitiesQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.Position.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.Position.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.Position} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Position.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionRefId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionRefBranch: jspb.Message.getFieldWithDefault(msg, 3, 0),
    positionWidth: jspb.Message.getFieldWithDefault(msg, 4, 0),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    treeChainId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    treeEntityId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    treeAccountEntityId: jspb.Message.getFieldWithDefault(msg, 8, 0),
    partnersQuantity: jspb.Message.getFieldWithDefault(msg, 9, 0),
    teamQuantity: jspb.Message.getFieldWithDefault(msg, 10, 0),
    earnedValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    branchSelectStrategy: jspb.Message.getFieldWithDefault(msg, 12, 0),
    branchLeft: (f = msg.getBranchLeft()) && proto.mlmbox.types.Binary.Position.Branch.toObject(includeInstance, f),
    branchRight: (f = msg.getBranchRight()) && proto.mlmbox.types.Binary.Position.Branch.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.Position}
 */
proto.mlmbox.types.Binary.Position.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.Position;
  return proto.mlmbox.types.Binary.Position.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.Position} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.Position}
 */
proto.mlmbox.types.Binary.Position.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionRefBranch(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionWidth(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeChainId(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeEntityId(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeAccountEntityId(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPartnersQuantity(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamQuantity(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarnedValue(value);
      break;
    case 12:
      var value = /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (reader.readEnum());
      msg.setBranchSelectStrategy(value);
      break;
    case 13:
      var value = new proto.mlmbox.types.Binary.Position.Branch;
      reader.readMessage(value,proto.mlmbox.types.Binary.Position.Branch.deserializeBinaryFromReader);
      msg.setBranchLeft(value);
      break;
    case 14:
      var value = new proto.mlmbox.types.Binary.Position.Branch;
      reader.readMessage(value,proto.mlmbox.types.Binary.Position.Branch.deserializeBinaryFromReader);
      msg.setBranchRight(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.Position.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.Position.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.Position} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Position.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPositionRefId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPositionRefBranch();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPositionWidth();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getTreeChainId();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getTreeEntityId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getTreeAccountEntityId();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getPartnersQuantity();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getTeamQuantity();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getEarnedValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getBranchSelectStrategy();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
  f = message.getBranchLeft();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      proto.mlmbox.types.Binary.Position.Branch.serializeBinaryToWriter
    );
  }
  f = message.getBranchRight();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.mlmbox.types.Binary.Position.Branch.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.Position.Branch.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.Position.Branch} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Position.Branch.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamQuantity: jspb.Message.getFieldWithDefault(msg, 1, 0),
    balanceValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    turnoverValue: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.Position.Branch}
 */
proto.mlmbox.types.Binary.Position.Branch.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.Position.Branch;
  return proto.mlmbox.types.Binary.Position.Branch.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.Position.Branch} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.Position.Branch}
 */
proto.mlmbox.types.Binary.Position.Branch.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTeamQuantity(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnoverValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.Position.Branch.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.Position.Branch} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Position.Branch.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamQuantity();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getBalanceValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTurnoverValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 team_quantity = 1;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.getTeamQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position.Branch} returns this
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.setTeamQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string balance_value = 2;
 * @return {string}
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.getBalanceValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Binary.Position.Branch} returns this
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.setBalanceValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string turnover_value = 3;
 * @return {string}
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.getTurnoverValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Binary.Position.Branch} returns this
 */
proto.mlmbox.types.Binary.Position.Branch.prototype.setTurnoverValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 position_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 position_ref_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getPositionRefId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setPositionRefId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 position_ref_branch = 3;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getPositionRefBranch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setPositionRefBranch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 position_width = 4;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getPositionWidth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setPositionWidth = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 account_id = 5;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 tree_chain_id = 6;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getTreeChainId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setTreeChainId = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional uint32 tree_entity_id = 7;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getTreeEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setTreeEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional uint32 tree_account_entity_id = 8;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getTreeAccountEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setTreeAccountEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 partners_quantity = 9;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getPartnersQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setPartnersQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional uint32 team_quantity = 10;
 * @return {number}
 */
proto.mlmbox.types.Binary.Position.prototype.getTeamQuantity = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setTeamQuantity = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional string earned_value = 11;
 * @return {string}
 */
proto.mlmbox.types.Binary.Position.prototype.getEarnedValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setEarnedValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional BranchSelectStrategy.Id branch_select_strategy = 12;
 * @return {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id}
 */
proto.mlmbox.types.Binary.Position.prototype.getBranchSelectStrategy = function() {
  return /** @type {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.mlmbox.types.Binary.BranchSelectStrategy.Id} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.setBranchSelectStrategy = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};


/**
 * optional Branch branch_left = 13;
 * @return {?proto.mlmbox.types.Binary.Position.Branch}
 */
proto.mlmbox.types.Binary.Position.prototype.getBranchLeft = function() {
  return /** @type{?proto.mlmbox.types.Binary.Position.Branch} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Binary.Position.Branch, 13));
};


/**
 * @param {?proto.mlmbox.types.Binary.Position.Branch|undefined} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
*/
proto.mlmbox.types.Binary.Position.prototype.setBranchLeft = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.clearBranchLeft = function() {
  return this.setBranchLeft(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Binary.Position.prototype.hasBranchLeft = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional Branch branch_right = 14;
 * @return {?proto.mlmbox.types.Binary.Position.Branch}
 */
proto.mlmbox.types.Binary.Position.prototype.getBranchRight = function() {
  return /** @type{?proto.mlmbox.types.Binary.Position.Branch} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Binary.Position.Branch, 14));
};


/**
 * @param {?proto.mlmbox.types.Binary.Position.Branch|undefined} value
 * @return {!proto.mlmbox.types.Binary.Position} returns this
*/
proto.mlmbox.types.Binary.Position.prototype.setBranchRight = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Binary.Position} returns this
 */
proto.mlmbox.types.Binary.Position.prototype.clearBranchRight = function() {
  return this.setBranchRight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Binary.Position.prototype.hasBranchRight = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Binary.View.repeatedFields_ = [7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.View.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.View.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.View} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.View.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    config: (f = msg.getConfig()) && proto.mlmbox.types.Binary.Config.toObject(includeInstance, f),
    selfPositionId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    selfTreeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 4, 0),
    viewPositionId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    viewTreeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 6, 0),
    positionsList: jspb.Message.toObjectList(msg.getPositionsList(),
    proto.mlmbox.types.Binary.Position.toObject, includeInstance),
    accountsList: jspb.Message.toObjectList(msg.getAccountsList(),
    proto.mlmbox.types.Account.Info.toObject, includeInstance),
    viewTreeAccountEntitiesList: jspb.Message.toObjectList(msg.getViewTreeAccountEntitiesList(),
    proto.mlmbox.types.TreeAccount.Entity.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.View}
 */
proto.mlmbox.types.Binary.View.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.View;
  return proto.mlmbox.types.Binary.View.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.View} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.View}
 */
proto.mlmbox.types.Binary.View.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Binary.Config;
      reader.readMessage(value,proto.mlmbox.types.Binary.Config.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelfPositionId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSelfTreeIdsBitMask(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewPositionId(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setViewTreeIdsBitMask(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.Binary.Position;
      reader.readMessage(value,proto.mlmbox.types.Binary.Position.deserializeBinaryFromReader);
      msg.addPositions(value);
      break;
    case 8:
      var value = new proto.mlmbox.types.Account.Info;
      reader.readMessage(value,proto.mlmbox.types.Account.Info.deserializeBinaryFromReader);
      msg.addAccounts(value);
      break;
    case 9:
      var value = new proto.mlmbox.types.TreeAccount.Entity;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.Entity.deserializeBinaryFromReader);
      msg.addViewTreeAccountEntities(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.View.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.View.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.View} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.View.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Binary.Config.serializeBinaryToWriter
    );
  }
  f = message.getSelfPositionId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getSelfTreeIdsBitMask();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getViewPositionId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getViewTreeIdsBitMask();
  if (f !== 0) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.mlmbox.types.Binary.Position.serializeBinaryToWriter
    );
  }
  f = message.getAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      8,
      f,
      proto.mlmbox.types.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getViewTreeAccountEntitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      9,
      f,
      proto.mlmbox.types.TreeAccount.Entity.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Binary.View.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Config config = 2;
 * @return {?proto.mlmbox.types.Binary.Config}
 */
proto.mlmbox.types.Binary.View.prototype.getConfig = function() {
  return /** @type{?proto.mlmbox.types.Binary.Config} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Binary.Config, 2));
};


/**
 * @param {?proto.mlmbox.types.Binary.Config|undefined} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
*/
proto.mlmbox.types.Binary.View.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Binary.View.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 self_position_id = 3;
 * @return {number}
 */
proto.mlmbox.types.Binary.View.prototype.getSelfPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.setSelfPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 self_tree_ids_bit_mask = 4;
 * @return {number}
 */
proto.mlmbox.types.Binary.View.prototype.getSelfTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.setSelfTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 view_position_id = 5;
 * @return {number}
 */
proto.mlmbox.types.Binary.View.prototype.getViewPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.setViewPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 view_tree_ids_bit_mask = 6;
 * @return {number}
 */
proto.mlmbox.types.Binary.View.prototype.getViewTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.setViewTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * repeated Position positions = 7;
 * @return {!Array<!proto.mlmbox.types.Binary.Position>}
 */
proto.mlmbox.types.Binary.View.prototype.getPositionsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Binary.Position>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Binary.Position, 7));
};


/**
 * @param {!Array<!proto.mlmbox.types.Binary.Position>} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
*/
proto.mlmbox.types.Binary.View.prototype.setPositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.mlmbox.types.Binary.Position=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Binary.Position}
 */
proto.mlmbox.types.Binary.View.prototype.addPositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.mlmbox.types.Binary.Position, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.clearPositionsList = function() {
  return this.setPositionsList([]);
};


/**
 * repeated Account.Info accounts = 8;
 * @return {!Array<!proto.mlmbox.types.Account.Info>}
 */
proto.mlmbox.types.Binary.View.prototype.getAccountsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Account.Info, 8));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Info>} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
*/
proto.mlmbox.types.Binary.View.prototype.setAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 8, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Binary.View.prototype.addAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 8, opt_value, proto.mlmbox.types.Account.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.clearAccountsList = function() {
  return this.setAccountsList([]);
};


/**
 * repeated TreeAccount.Entity view_tree_account_entities = 9;
 * @return {!Array<!proto.mlmbox.types.TreeAccount.Entity>}
 */
proto.mlmbox.types.Binary.View.prototype.getViewTreeAccountEntitiesList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount.Entity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.TreeAccount.Entity, 9));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount.Entity>} value
 * @return {!proto.mlmbox.types.Binary.View} returns this
*/
proto.mlmbox.types.Binary.View.prototype.setViewTreeAccountEntitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 9, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount.Entity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount.Entity}
 */
proto.mlmbox.types.Binary.View.prototype.addViewTreeAccountEntities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 9, opt_value, proto.mlmbox.types.TreeAccount.Entity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Binary.View} returns this
 */
proto.mlmbox.types.Binary.View.prototype.clearViewTreeAccountEntitiesList = function() {
  return this.setViewTreeAccountEntitiesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Binary.Info.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Binary.Info.Item.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.Info}
 */
proto.mlmbox.types.Binary.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.Info;
  return proto.mlmbox.types.Binary.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.Info}
 */
proto.mlmbox.types.Binary.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Binary.Info.Item;
      reader.readMessage(value,proto.mlmbox.types.Binary.Info.Item.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Binary.Info.Item.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Binary.Info.Item.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Binary.Info.Item} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Info.Item.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeAccount: (f = msg.getTreeAccount()) && proto.mlmbox.types.TreeAccount.toObject(includeInstance, f),
    config: (f = msg.getConfig()) && proto.mlmbox.types.Binary.Config.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Binary.Info.Item}
 */
proto.mlmbox.types.Binary.Info.Item.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Binary.Info.Item;
  return proto.mlmbox.types.Binary.Info.Item.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Binary.Info.Item} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Binary.Info.Item}
 */
proto.mlmbox.types.Binary.Info.Item.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.TreeAccount;
      reader.readMessage(value,proto.mlmbox.types.TreeAccount.deserializeBinaryFromReader);
      msg.setTreeAccount(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Binary.Config;
      reader.readMessage(value,proto.mlmbox.types.Binary.Config.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Binary.Info.Item.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Binary.Info.Item} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Binary.Info.Item.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.TreeAccount.serializeBinaryToWriter
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Binary.Config.serializeBinaryToWriter
    );
  }
};


/**
 * optional TreeAccount tree_account = 1;
 * @return {?proto.mlmbox.types.TreeAccount}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.getTreeAccount = function() {
  return /** @type{?proto.mlmbox.types.TreeAccount} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.TreeAccount, 1));
};


/**
 * @param {?proto.mlmbox.types.TreeAccount|undefined} value
 * @return {!proto.mlmbox.types.Binary.Info.Item} returns this
*/
proto.mlmbox.types.Binary.Info.Item.prototype.setTreeAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Binary.Info.Item} returns this
 */
proto.mlmbox.types.Binary.Info.Item.prototype.clearTreeAccount = function() {
  return this.setTreeAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.hasTreeAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Config config = 2;
 * @return {?proto.mlmbox.types.Binary.Config}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.getConfig = function() {
  return /** @type{?proto.mlmbox.types.Binary.Config} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Binary.Config, 2));
};


/**
 * @param {?proto.mlmbox.types.Binary.Config|undefined} value
 * @return {!proto.mlmbox.types.Binary.Info.Item} returns this
*/
proto.mlmbox.types.Binary.Info.Item.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Binary.Info.Item} returns this
 */
proto.mlmbox.types.Binary.Info.Item.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Binary.Info.Item.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated Item items = 1;
 * @return {!Array<!proto.mlmbox.types.Binary.Info.Item>}
 */
proto.mlmbox.types.Binary.Info.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Binary.Info.Item>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Binary.Info.Item, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Binary.Info.Item>} value
 * @return {!proto.mlmbox.types.Binary.Info} returns this
*/
proto.mlmbox.types.Binary.Info.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Binary.Info.Item=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Binary.Info.Item}
 */
proto.mlmbox.types.Binary.Info.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Binary.Info.Item, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Binary.Info} returns this
 */
proto.mlmbox.types.Binary.Info.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


goog.object.extend(exports, proto.mlmbox.types);
