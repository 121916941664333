import { arrowBinary } from '../../../assets';
import Ava from '../../../components/Common/Ava/Ava';
import { getColor, PositionNode } from '../../../utils/helper';

export const TreeNodes = ({
    node,
    setViewPosotionId,
    setFetching,
    matrix,
}: {
    node: PositionNode;
    setViewPosotionId: Function;
    setFetching: Function;
    matrix?: boolean;
}) => {
    return (
        <div className="tree-node">
            {node.children &&
                node.children.length > 0 &&
                node.children.map((childNode, index) => (
                    <div
                        key={childNode.position.positionId ? childNode.position.positionId : index + childNode.position.accountId}
                        className="tree-col"
                        id={'ind' + index}>
                        <div
                            onClick={() => {
                                if (childNode.position.positionId) {
                                    setViewPosotionId(childNode.position.positionId);

                                    setFetching(true);
                                }
                            }}
                            className={`circle-group__circle ${childNode.position.level !== 1 ? '' : 'small'} ${matrix ? 'matrix' : ''}`}
                            style={{ border: !childNode.position.positionId ? '1px solid #0462F9' : 'unset' }}>
                            {childNode.position.positionId ? (
                                <Ava
                                    matrix={matrix}
                                    width={!matrix && childNode.position.level !== 1 ? '80px' : '60px'}
                                    height={!matrix && childNode.position.level !== 1 ? '80px' : '60px'}
                                    borderColor={getColor(childNode.position.relationship?.kind, childNode.position.relationship?.level)}
                                    collectionId={childNode.position.ava?.collectionId}
                                    entityId={childNode.position.ava?.entityId}
                                />
                            ) : (
                                <></>
                            )}

                            {childNode?.position?.drop && (
                                <img
                                    className="arrow"
                                    src={arrowBinary}
                                    alt=""
                                />
                            )}

                            {childNode?.position.loginOrId && childNode.position.level !== 1 && (
                                <div className="login-or-id">
                                    <h1>{childNode?.position.loginOrId}</h1>
                                </div>
                            )}

                            {childNode.position.level === 1 && (
                                <>
                                    <div className="cirlce-1"></div>
                                    <div className="cirlce-2"></div>
                                </>
                            )}
                        </div>
                        {childNode.children ? (
                            <TreeNodes
                                setViewPosotionId={setViewPosotionId}
                                setFetching={setFetching}
                                node={childNode}
                                matrix={matrix}
                            />
                        ) : (
                            <></>
                        )}
                    </div>
                ))}
        </div>
    );
};
