import { format } from 'date-fns';
import { de, enGB, ka, ko, pt, ru, uk, zhCN } from 'date-fns/locale';
import i18next, { t } from 'i18next';
import Cookies from 'universal-cookie';
import { AccountClient } from '../generated/mlmbox/client/account/AccountServiceClientPb';
import { Account, Binary, Matrix, TreeAccount } from '../generated/mlmbox/types/account_pb';
import { Relationship } from '../generated/mlmbox/types/relationship_pb';
import i18n from '../i18n';
import { setAvatars } from '../store/avatars/avatars.slice';
import { store } from '../store/store';
import { API_URL } from './constants';

declare global {
    interface Window {
        Telegram: any;
    }
}

export enum haptic {
    light = 'light',
    medium = 'medium',
}

export function Haptick(stage: haptic) {
    window?.Telegram?.WebApp?.HapticFeedback?.impactOccurred(stage);
    return stage;
}

export enum type {
    success = 'success',
    error = 'error',
    warning = 'warning',
}

export function Notification(type: type) {
    window?.Telegram?.WebApp?.HapticFeedback?.notificationOccurred(type);
    return type;
}

export async function copyToClipboard(text: string) {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error('Не удалось скопировать: ', err);
    }
}

export class TreeNode {
    id: number | null;
    children: TreeNode[];
    constructor(id: number | null) {
        this.id = id;
        this.children = [];
    }
}

export function fillTree(arr: Matrix.Position.AsObject[], position: number) {
    if (arr.length === 0) return new TreeNode(position);

    const root = new TreeNode(position);

    function addChildren(node: TreeNode, arr: Matrix.Position.AsObject[], width: number) {
        let count = 0;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].positionRefId === node.id && count < width) {
                const childNode = new TreeNode(arr[i].positionId);

                node.children.push(childNode);
                count++;
                addChildren(childNode, arr, arr[i].positionWidth);
            }
        }
    }

    addChildren(root, arr, arr[0].positionWidth);
    return root;
}

export function ensureMinimumChildren(node: TreeNode) {
    let count = 0;
    if (count > 2) return;
    console.log('call');
    if (node.children.length < 2) {
        while (node.children.length < 2) {
            node.children.push(new TreeNode(null));
        }
    }

    for (let child of node.children) {
        if (child !== null) {
            ensureMinimumChildren(child);
        }
        count++;
    }
    return count;
}

export function checkUserLoggedIn() {
    const cookies = new Cookies();
    const jwt_token = cookies.get('_tv1');

    if (jwt_token) {
        return true;
    }

    return false;
}

type Group = {
    id: number;
    title: string;
};

type Tree = {
    id: number;
    title: string;
    groupId: number;
    defaultPositionWidth: number;
    amountAssetId: number;
    amountValue: string;
};

type Position = {
    positionId: number;
    positionRefId: number;
    positionRefLine: number;
    positionWidth: number;
    accountId: number;
    treeAccountId: number;
    branchSelectCategory?: Binary.BranchSelectStrategy.Id | undefined;
    drop?: boolean;
    level?: number;
    loginOrId?: string;
    relationship?: {
        kind: number;
        level: number;
        branch: number;
    };
    ava?: {
        collectionId: number;
        entityId: number;
    } | null;
};

export type PositionNode = {
    position: Position;
    children: PositionNode[];
};

export class ViewState {
    private groups: Map<number, Group> = new Map();
    private trees: Map<number, Tree> = new Map();
    private positions: Map<number, Position> = new Map();
    private indexPositionRef: Map<string, number> = new Map();

    constructor() {}

    addGroup(group: Group): void {
        this.groups.set(group.id, group);
    }

    addTree(tree: Tree): void {
        this.trees.set(tree.id, tree);
    }

    addPosition(position: Position): void {
        this.indexPositionRef.set(`${position.positionRefId},${position.positionRefLine}`, position.positionId);
        this.positions.set(position.positionId, position);
    }

    getGroups(): Group[] {
        return Array.from(this.groups.values()).sort((a, b) => a.id - b.id);
    }

    getTrees(): Tree[] {
        return Array.from(this.trees.values()).sort((a, b) => a.id - b.id);
    }

    getTreesByGroupId(groupId: number): Tree[] {
        if (groupId > 0) {
            return Array.from(this.trees.values())
                .filter((tree) => tree.groupId === groupId)
                .sort((a, b) => a.id - b.id);
        }
        return [];
    }

    private defaultPosition(positionRefId: number, positionRefLine: number, positionWidth: number): Position {
        return {
            positionId: 0,
            positionRefId,
            positionRefLine,
            positionWidth,
            accountId: 0,
            treeAccountId: 0,
            branchSelectCategory: undefined,
            drop: false,
            level: 0,
            loginOrId: '',
            ava: {
                collectionId: 1,
                entityId: 1,
            },
            relationship: {
                kind: 0,
                level: 0,
                branch: 0,
            },
        };
    }

    private cacheExists(positionId: number): boolean {
        return this.positions.has(positionId);
    }

    private cacheViewChildren(positionRef: Position, defaultPositionWidth: number, level: number): Position[] {
        if (positionRef.positionWidth > 0) {
            const children: Position[] = [];
            const positionRefId = positionRef.positionId;
            if (positionRefId > 0) {
                for (let positionRefLine = 1; positionRefLine <= positionRef.positionWidth; positionRefLine++) {
                    const key = `${positionRefId},${positionRefLine}`;
                    const nextPositionId = this.indexPositionRef.get(key);

                    children.push(
                        nextPositionId !== undefined && this.positions.has(nextPositionId)
                            ? {
                                  ...this.positions.get(nextPositionId)!,
                                  level,
                                  drop: positionRefLine === positionRef.branchSelectCategory && level && level === 1 ? true : false,
                              }
                            : {
                                  ...this.defaultPosition(positionRefId, positionRefLine, defaultPositionWidth),
                                  level,
                                  drop: positionRefLine === positionRef.branchSelectCategory && level && level === 1 ? true : false,
                              }
                    );
                }
            } else {
                for (let positionRefLine = 1; positionRefLine <= positionRef.positionWidth; positionRefLine++) {
                    children.push({ ...this.defaultPosition(0, positionRefLine, defaultPositionWidth), level });
                }
            }
            return children;
        }
        return [];
    }

    private cacheViewTeam(positionRef: Position, defaultPositionWidth: number, level: number): PositionNode {
        const result: PositionNode = {
            position: positionRef,
            children: [],
        };
        if (level > 0) {
            this.cacheViewChildren(result.position, defaultPositionWidth, level).forEach((position) => {
                result.children.push(this.cacheViewTeam(position, defaultPositionWidth, level - 1));
            });
        }
        return result;
    }

    cacheView(treeId: number, positionId: number, level: number): PositionNode | undefined {
        const tree = this.trees.get(treeId);
        const position = this.positions.get(positionId);

        if (tree && position) {
            return this.cacheViewTeam(position, tree.defaultPositionWidth, level);
        }
        return undefined;
    }
}

export function welcomeTtm() {
    return console.log(
        '%cTTM%cPartners',
        `
                height: 50px;
                display: flex;
                align-items: center;
                border-radius: 37.37px;
                border: 0.84px solid #0462f9;
                font-size: 30px;
                padding: 10px 20px;
                font-family: monospace;
                font-weight: 500;
                border-radius: 37.37px;
                background: linear-gradient(0deg, rgba(72, 160, 247, 0.7) 0%, rgba(72, 160, 247, 0.7) 100%), linear-gradient(93deg, #0462f9 32.99%, #101598 100%);
          `,
        `
                background-color: transparent;
                height: 40px;
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 0 37.37px 37.37px 0;
                border: 0.84px solid #0462f9;
                font-size: 30px;
                padding: 10px 20px 10px 40px;
                border-left: none;
                transform: translateX(-100px);
                margin-left: -25px;
          `
    );
}

export function getColor(kindId: number | undefined, level: number | undefined): string | undefined {
    if (!kindId) return;

    switch (kindId) {
        case Relationship.Kind.OUTSIDER:
            return '#BABABA';
        case Relationship.Kind.PERSONAL:
            return '#FF7A00';
        case Relationship.Kind.REFERRAL:
            return '#E53A3A';
        case Relationship.Kind.TEAM:
            return level === 1 ? '#79CB5C' : '#0075FF';
        case Relationship.Kind.UNKNOWN:
            return '#BABABA';
        default:
            return '#000';
    }
}

const locales = {
    ru: ru,
    en: enGB,
    uk: uk,
    zh: zhCN,
    pt: pt,
    ko: ko,
    ka: ka,
    de: de,
};

export function getDate(date: number): string {
    const regDate = new Date(date * 1000);
    const lng = i18n.resolvedLanguage === 'en' ? 'en' : 'ru';
    const result = format(regDate, 'd MMMM yyyy', { locale: locales[lng] });
    return result;
}

export async function setAvas(itemsList: Account.Info.AsObject[]) {
    if (itemsList.length === 0) return;
    const avatars = store.getState().avatars.value;
    if (!avatars) return undefined;

    const imageCache = JSON.parse(JSON.stringify(avatars));

    const accountCLient = new AccountClient(API_URL, null, null);
    const req = new Account.Profile.Ava.List();
    const avas: Account.Profile.Ava[] = [];
    for (const el of itemsList) {
        const cacheKey = `${el.profile?.ava?.collectionId}-${el.profile?.ava?.entityId}`;
        if (imageCache[cacheKey]) {
            continue;
        }
        const ava = new Account.Profile.Ava();
        if (el.profile?.ava?.collectionId && el.profile?.ava?.entityId) {
            ava.setEntityId(el.profile?.ava?.entityId);
            ava.setCollectionId(el.profile?.ava?.collectionId);
            avas.push(ava);
        }
    }

    if (avas.length > 0) {
        req.setItemsList(avas);

        const response = await accountCLient.getAvaFiles(req, { authorization: getToken() });

        for (const el of response.toObject().itemsList) {
            const cacheKey = `${el.collectionId}-${el.entityId}`;
            if (imageCache[cacheKey]) {
                continue;
            }
            imageCache[cacheKey] = el.value;
        }

        await store.dispatch(setAvatars(imageCache));
    }
}

export async function getAva(collectionId: number, entityId: number): Promise<string | undefined> {
    const cacheKey = `${collectionId}-${entityId}`;
    const avatars = store.getState().avatars.value;
    if (!avatars) return undefined;

    if (avatars[cacheKey]) {
        return avatars[cacheKey];
    }
}

export function filterNonEmptyMatrixTree(matrixTreeAccounts: TreeAccount.AsObject[]): TreeAccount.AsObject[] {
    return matrixTreeAccounts.filter((account) => !(account.bookingQuantity === 0 && account.entitiesList.length === 0));
}

export function filterNonEmptyBinaryTree(matrixTreeAccounts: TreeAccount.AsObject[], treeId: number): TreeAccount.AsObject[] {
    return matrixTreeAccounts.filter((account) => account.treeId === treeId && !(account.bookingQuantity === 0 && account.entitiesList.length === 0));
}

export function getName(channelsList: number[], firstName: string | undefined, lastName: string | undefined, username: string | undefined): string {
    let result = '';

    if (firstName?.length !== 0 || lastName?.length !== 0) {
        return (result = `${firstName} ${' '} ${lastName}`);
    }

    if (username?.length) return (result = `${username}`);

    if (channelsList.includes(2) || channelsList.includes(3)) return (result = i18next.t('unknown_duck'));

    return result;
}

export function getLoginOrId(user: Account.Info.AsObject): string {
    if (user.account?.login) return `@${user.account.login}`;

    if (user.account?.id) return `id${user.account?.id}`;

    return '';
}

export function getLoginOrIdByData(user: Account.Username.AsObject): string {
    if (user.login) return `@${user.login}`;

    if (user.id) return `id${user.id}`;

    return '';
}
const cookies = new Cookies();
export function getToken() {
    return cookies.get('__tt');
    // return store.getState().auth.value.isAuth;
    // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6Miwic3ViIjoxLCJ0Z2lkIjo4MTQyNzI0NDksImV4cCI6MTcyOTA4NTA3MiwiaWF0IjoxNzI4NDgwMjcyfQ.keIP5hzZX-6bBtYJ4_A_hZms7ayB55t5hByOB9f6IIE';
    // return 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6MSwic3ViIjoyLCJ0Z2lkIjo4MTQyNzI0NDksImV4cCI6MTcyODM0MTA4NSwiaWF0IjoxNzI3NjQ5ODg1fQ.7fbrJd6mrwwOI__aC9YtkwXpB0GWqM0TVS_tZfMSDxw';
}

export function setCookies(name: string, value: string) {
    cookies.set(name, value);
}

export function getCookies(name: string) {
    return cookies.get(name);
}

export function isAdmin(id: number) {
    const admins = [1, 2, 85475, 22911, 84245, 86375];
    if (admins.includes(id)) {
        return true;
    }

    return false;
}

export function getIdsByBitMask(num: number, channelIds: number[]): string {
    if (!channelIds.includes(1) && channelIds.includes(2)) return 'Ducks Tap';
    if (!channelIds.includes(1) && channelIds.includes(3)) return 'TTM Casino';
    const ids: number[] = [];
    let position = 0;

    while (num !== 0) {
        if (num & 1) {
            if (position + 1 < 16) ids.push(position + 1); // Увеличиваем позицию на 1
        }
        num >>= 1;
        position++;
    }
    if (ids.length === 0) return t('not_areas');
    return `P${ids.slice(-1)}`;
}
