import authReducer from './auth/auth.slice';
import avatarsReducer from './avatars/avatars.slice';
import schemesReducer from './scheme/schemes.slice';
import userReducer from './user/user.slice';
import walletReducer from './wallet/wallet.slice';

export default {
    auth: authReducer,
    user: userReducer,
    wallet: walletReducer,
    schemes: schemesReducer,
    avatars: avatarsReducer,
};
