import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { deIcon, enIcon, kaIcon, koIcon, ptIcon, ruIcon, ukIcon, zhIcon } from '../../assets';
import { AccountClient } from '../../generated/mlmbox/client/account/AccountServiceClientPb';
import * as mlmbox_types_locale_pb from '../../generated/mlmbox/types/locale_pb';
import { setOpenLocale } from '../../store/user/user.slice';
import { API_URL } from '../../utils/constants';
import { getToken } from '../../utils/helper';
import './Locale.scss';

export interface ILocale {
    icon: string;
    title: string;
    checked: boolean;
    id: string;
}

const lcls: { [key: string]: string } = {
    en: 'en_GB',
    de: 'de_DE',
    ka: 'ka_GE',
    ko: 'ko_KR',
    pt: 'pt_PT',
    ru: 'ru_RU',
    uk: 'uk_UA',
    zh: 'zh_CN',
};

const locales: ILocale[] = [
    {
        icon: enIcon,
        title: 'English',
        checked: false,
        id: 'en',
    },
    {
        icon: deIcon,
        title: 'Deutsch',
        checked: false,
        id: 'de',
    },
    {
        icon: ruIcon,
        title: 'Русский',
        checked: false,
        id: 'ru',
    },
    {
        icon: ukIcon,
        title: 'Українська',
        checked: false,
        id: 'uk',
    },
    {
        icon: ptIcon,
        title: 'Português',
        checked: false,
        id: 'pt',
    },
    {
        icon: zhIcon,
        title: '中文',
        checked: false,
        id: 'zh',
    },
    {
        icon: koIcon,
        title: '한국어',
        checked: false,
        id: 'ko',
    },
    {
        icon: kaIcon,
        title: 'ქართული',
        checked: false,
        id: 'ka',
    },
];

const Locale = () => {
    const { t, i18n } = useTranslation();
    const [localeList, setLocaleList] = useState<ILocale[]>(locales);

    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const result = localeList.map((locale) => {
            if (locale.id === i18n.resolvedLanguage) return { ...locale, checked: true };
            else return locale;
        });

        setLocaleList(result);
    }, []);

    const selectLocale = ({ id }: ILocale) => {
        const accountClient = new AccountClient(API_URL, null, null);
        const lcl = new mlmbox_types_locale_pb.Locale.Id();
        lcl.setLocale(lcls[id]);

        accountClient.setLocale(lcl, { authorization: getToken() }, (err, res) => {
            if (!err) {
                const result = localeList.map((locale) => {
                    if (locale.id === id) return { ...locale, checked: true };
                    else return { ...locale, checked: false };
                });
                setLocaleList(result);
                i18n.changeLanguage(id);
                dispatch(setOpenLocale(false));
            } else {
                const result = localeList.map((locale) => {
                    if (locale.id === id) return { ...locale, checked: true };
                    else return { ...locale, checked: false };
                });
                setLocaleList(result);
                i18n.changeLanguage(id);
                dispatch(setOpenLocale(false));
            }
        });

        setOpen(false);
    };

    return (
        <div className="locale-container">
            <div
                className="icon"
                onClick={() => setOpen((prev) => !prev)}>
                <h1>{i18n.resolvedLanguage}</h1>
            </div>
            {open && (
                <div className="locale">
                    <h1>{t('locale_title')}</h1>
                    {localeList.map((locale) => (
                        <div
                            onClick={() => selectLocale(locale)}
                            key={locale.id}
                            className="locale__item">
                            <div className="locale__item--data">
                                <img
                                    src={locale.icon}
                                    alt=""
                                />
                                <h2>{locale.title}</h2>
                            </div>
                            {locale.checked && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="15"
                                    viewBox="0 0 14 15"
                                    fill="none">
                                    <path
                                        d="M4.86199 12.0948C4.78717 12.0923 4.71366 12.0746 4.64596 12.0426C4.57826 12.0107 4.5178 11.9653 4.46827 11.9091L0.753985 8.19484C0.683167 8.14893 0.623706 8.08752 0.580092 8.01526C0.536478 7.94301 0.509851 7.86178 0.502221 7.77773C0.49459 7.69367 0.506156 7.60898 0.536046 7.53005C0.565935 7.45112 0.613367 7.38001 0.674759 7.3221C0.736151 7.26418 0.8099 7.22097 0.890436 7.19572C0.970973 7.17048 1.05619 7.16386 1.13966 7.17637C1.22313 7.18888 1.30266 7.22019 1.37226 7.26793C1.44186 7.31568 1.4997 7.37861 1.54141 7.45199L4.86199 10.7503L12.6397 2.99484C12.7444 2.92695 12.8689 2.89618 12.9932 2.90746C13.1174 2.91875 13.2343 2.97143 13.3251 3.05706C13.4159 3.1427 13.4753 3.25633 13.4938 3.37975C13.5123 3.50316 13.4888 3.62921 13.4271 3.7377L5.2557 11.9091C5.20618 11.9653 5.14571 12.0107 5.07801 12.0426C5.01031 12.0746 4.9368 12.0923 4.86199 12.0948Z"
                                        fill="#F0F1F4"
                                    />
                                </svg>
                            )}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Locale;
