import { FC, useEffect, useState } from 'react';
import { formatString } from '../../helper';
import './MatrixPictureCircle.scss';

export const MatrixPictureCircle: FC<{ bit: string }> = ({ bit }) => {
    const [filledCircles, setFilledCircles] = useState(Array(6).fill(false));

    useEffect(() => {
        const bits = formatString(bit);
        const newFilledCircles = filledCircles.map((_, index) => bits[index] === '1');

        setFilledCircles(newFilledCircles);
    }, []);

    return (
        <div className="matrix-picture-circle">
            <div className="matrix-picture-circle__low matrix-picture-circle__row">
                {filledCircles.map(
                    (fill, index) =>
                        index <= 3 && (
                            <div className={`elem ${fill ? 'filled' : ''} size-${index > 3 ? '2' : index >= 0 && index < 5 ? '3' : '1'}`}></div>
                        )
                )}
            </div>
            <div className="matrix-picture-circle__medium matrix-picture-circle__row">
                {filledCircles.map(
                    (fill, index) =>
                        index <= 5 &&
                        index > 3 && (
                            <div className={`elem ${fill ? 'filled' : ''} size-${index > 3 ? '2' : index >= 0 && index < 5 ? '3' : '1'}`}></div>
                        )
                )}
            </div>

            <div className="matrix-picture-circle__high matrix-picture-circle__row">
                <div className={`elem size-1 filled`}></div>
            </div>
        </div>
    );
};
