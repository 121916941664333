// source: mlmbox/types/pool.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.mlmbox.types.Pool', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card.Info', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card.Info.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card.Transaction', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Card.Transaction.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.History', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.History.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Pool.Hunter.Info', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.displayName = 'proto.mlmbox.types.Pool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Pool.Hunter.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.displayName = 'proto.mlmbox.types.Pool.Hunter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Info.displayName = 'proto.mlmbox.types.Pool.Hunter.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.History = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.History, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.History.displayName = 'proto.mlmbox.types.Pool.Hunter.History';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.History.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Pool.Hunter.History.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.History.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.History.List.displayName = 'proto.mlmbox.types.Pool.Hunter.History.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Pool.Hunter.Card.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.displayName = 'proto.mlmbox.types.Pool.Hunter.Card';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.Id.displayName = 'proto.mlmbox.types.Pool.Hunter.Card.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.Info.displayName = 'proto.mlmbox.types.Pool.Hunter.Card.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Pool.Hunter.Card.Info.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card.Info.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.Info.List.displayName = 'proto.mlmbox.types.Pool.Hunter.Card.Info.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.Transaction.displayName = 'proto.mlmbox.types.Pool.Hunter.Card.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Pool.Hunter.Card.Transaction.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.displayName = 'proto.mlmbox.types.Pool.Hunter.Card.Transaction.List';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool}
 */
proto.mlmbox.types.Pool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool;
  return proto.mlmbox.types.Pool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool}
 */
proto.mlmbox.types.Pool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Pool.Hunter.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.mlmbox.types.Pool.Hunter.Info.toObject(includeInstance, f),
    cardsList: jspb.Message.toObjectList(msg.getCardsList(),
    proto.mlmbox.types.Pool.Hunter.Card.Info.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter}
 */
proto.mlmbox.types.Pool.Hunter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter;
  return proto.mlmbox.types.Pool.Hunter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter}
 */
proto.mlmbox.types.Pool.Hunter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Pool.Hunter.Info;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Pool.Hunter.Card.Info;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinaryFromReader);
      msg.addCards(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.Pool.Hunter.Info.serializeBinaryToWriter
    );
  }
  f = message.getCardsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.types.Pool.Hunter.Card.Info.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accumulatedValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    nextDistributionAt: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Info}
 */
proto.mlmbox.types.Pool.Hunter.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Info;
  return proto.mlmbox.types.Pool.Hunter.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Info}
 */
proto.mlmbox.types.Pool.Hunter.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccumulatedValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNextDistributionAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAccumulatedValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getNextDistributionAt();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string accumulated_value = 2;
 * @return {string}
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.getAccumulatedValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.setAccumulatedValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 next_distribution_at = 3;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.getNextDistributionAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Info.prototype.setNextDistributionAt = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.History.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.History} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.History.toObject = function(includeInstance, msg) {
  var f, obj = {
    distributionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    distributionValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    quantityCards: jspb.Message.getFieldWithDefault(msg, 3, 0),
    quantityAccounts: jspb.Message.getFieldWithDefault(msg, 4, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.History}
 */
proto.mlmbox.types.Pool.Hunter.History.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.History;
  return proto.mlmbox.types.Pool.Hunter.History.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.History} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.History}
 */
proto.mlmbox.types.Pool.Hunter.History.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDistributionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantityCards(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setQuantityAccounts(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.History.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.History} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.History.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistributionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDistributionValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQuantityCards();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getQuantityAccounts();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Pool.Hunter.History.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.History.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.History.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.History.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.History.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Pool.Hunter.History.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.History.List}
 */
proto.mlmbox.types.Pool.Hunter.History.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.History.List;
  return proto.mlmbox.types.Pool.Hunter.History.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.History.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.History.List}
 */
proto.mlmbox.types.Pool.Hunter.History.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Pool.Hunter.History;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.History.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.History.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.History.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.History.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.History.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Pool.Hunter.History.serializeBinaryToWriter
    );
  }
};


/**
 * repeated History items = 1;
 * @return {!Array<!proto.mlmbox.types.Pool.Hunter.History>}
 */
proto.mlmbox.types.Pool.Hunter.History.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Pool.Hunter.History>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Pool.Hunter.History, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Pool.Hunter.History>} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History.List} returns this
*/
proto.mlmbox.types.Pool.Hunter.History.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Pool.Hunter.History=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Pool.Hunter.History}
 */
proto.mlmbox.types.Pool.Hunter.History.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Pool.Hunter.History, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Pool.Hunter.History.List} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 distribution_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.getDistributionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.setDistributionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string distribution_value = 2;
 * @return {string}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.getDistributionValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.setDistributionValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 quantity_cards = 3;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.getQuantityCards = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.setQuantityCards = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 quantity_accounts = 4;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.getQuantityAccounts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.setQuantityAccounts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 created_at = 5;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.History} returns this
 */
proto.mlmbox.types.Pool.Hunter.History.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Pool.Hunter.Card.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.toObject = function(includeInstance, msg) {
  var f, obj = {
    info: (f = msg.getInfo()) && proto.mlmbox.types.Pool.Hunter.Card.Info.toObject(includeInstance, f),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.mlmbox.types.Pool.Hunter.Card.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card}
 */
proto.mlmbox.types.Pool.Hunter.Card.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card;
  return proto.mlmbox.types.Pool.Hunter.Card.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card}
 */
proto.mlmbox.types.Pool.Hunter.Card.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Pool.Hunter.Card.Info;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Pool.Hunter.Card.Transaction;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Card.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.Pool.Hunter.Card.Info.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.types.Pool.Hunter.Card.Transaction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Id}
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card.Id;
  return proto.mlmbox.types.Pool.Hunter.Card.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Id}
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCardId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 card_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.prototype.getCardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Id} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Id.prototype.setCardId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    createdAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    energyInit: jspb.Message.getFieldWithDefault(msg, 3, 0),
    energyTotal: jspb.Message.getFieldWithDefault(msg, 4, 0),
    energyValue: jspb.Message.getFieldWithDefault(msg, 5, 0),
    earnedTotalValue: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card.Info;
  return proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCardId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEnergyInit(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEnergyTotal(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setEnergyValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarnedTotalValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getEnergyInit();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getEnergyTotal();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getEnergyValue();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getEarnedTotalValue();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.Info.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Pool.Hunter.Card.Info.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info.List}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card.Info.List;
  return proto.mlmbox.types.Pool.Hunter.Card.Info.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info.List}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Pool.Hunter.Card.Info;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Card.Info.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.Info.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Pool.Hunter.Card.Info.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Info items = 1;
 * @return {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Pool.Hunter.Card.Info, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info.List} returns this
*/
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Pool.Hunter.Card.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info.List} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 card_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getCardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setCardId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 created_at = 2;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 energy_init = 3;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getEnergyInit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setEnergyInit = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 energy_total = 4;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getEnergyTotal = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setEnergyTotal = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional uint32 energy_value = 5;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getEnergyValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setEnergyValue = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string earned_total_value = 6;
 * @return {string}
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.getEarnedTotalValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Info.prototype.setEarnedTotalValue = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    distributionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    distributionValue: jspb.Message.getFieldWithDefault(msg, 2, ""),
    earnedValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card.Transaction;
  return proto.mlmbox.types.Pool.Hunter.Card.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDistributionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionValue(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEarnedValue(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDistributionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getDistributionValue();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEarnedValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.mlmbox.types.Pool.Hunter.Card.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Pool.Hunter.Card.Transaction.List;
  return proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Pool.Hunter.Card.Transaction;
      reader.readMessage(value,proto.mlmbox.types.Pool.Hunter.Card.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Pool.Hunter.Card.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Pool.Hunter.Card.Transaction, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List} returns this
*/
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Pool.Hunter.Card.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction.List} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.List.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional uint32 distribution_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.getDistributionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.setDistributionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string distribution_value = 2;
 * @return {string}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.getDistributionValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.setDistributionValue = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string earned_value = 3;
 * @return {string}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.getEarnedValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.setEarnedValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 created_at = 4;
 * @return {number}
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional Info info = 1;
 * @return {?proto.mlmbox.types.Pool.Hunter.Card.Info}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.getInfo = function() {
  return /** @type{?proto.mlmbox.types.Pool.Hunter.Card.Info} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Pool.Hunter.Card.Info, 1));
};


/**
 * @param {?proto.mlmbox.types.Pool.Hunter.Card.Info|undefined} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card} returns this
*/
proto.mlmbox.types.Pool.Hunter.Card.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Transaction transactions = 2;
 * @return {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Pool.Hunter.Card.Transaction, 2));
};


/**
 * @param {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Transaction>} value
 * @return {!proto.mlmbox.types.Pool.Hunter.Card} returns this
*/
proto.mlmbox.types.Pool.Hunter.Card.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Transaction}
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.types.Pool.Hunter.Card.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Pool.Hunter.Card} returns this
 */
proto.mlmbox.types.Pool.Hunter.Card.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional Info info = 1;
 * @return {?proto.mlmbox.types.Pool.Hunter.Info}
 */
proto.mlmbox.types.Pool.Hunter.prototype.getInfo = function() {
  return /** @type{?proto.mlmbox.types.Pool.Hunter.Info} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Pool.Hunter.Info, 1));
};


/**
 * @param {?proto.mlmbox.types.Pool.Hunter.Info|undefined} value
 * @return {!proto.mlmbox.types.Pool.Hunter} returns this
*/
proto.mlmbox.types.Pool.Hunter.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Pool.Hunter} returns this
 */
proto.mlmbox.types.Pool.Hunter.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Pool.Hunter.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated Card.Info cards = 2;
 * @return {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>}
 */
proto.mlmbox.types.Pool.Hunter.prototype.getCardsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Pool.Hunter.Card.Info, 2));
};


/**
 * @param {!Array<!proto.mlmbox.types.Pool.Hunter.Card.Info>} value
 * @return {!proto.mlmbox.types.Pool.Hunter} returns this
*/
proto.mlmbox.types.Pool.Hunter.prototype.setCardsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.types.Pool.Hunter.Card.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Pool.Hunter.Card.Info}
 */
proto.mlmbox.types.Pool.Hunter.prototype.addCards = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.types.Pool.Hunter.Card.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Pool.Hunter} returns this
 */
proto.mlmbox.types.Pool.Hunter.prototype.clearCardsList = function() {
  return this.setCardsList([]);
};


goog.object.extend(exports, proto.mlmbox.types);
