// source: mlmbox/types/asset_pair.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mlmbox_types_asset_pb = require('../../mlmbox/types/asset_pb.js');
goog.object.extend(proto, mlmbox_types_asset_pb);
goog.exportSymbol('proto.mlmbox.types.AssetPair', null, global);
goog.exportSymbol('proto.mlmbox.types.AssetPair.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.AssetPair.Id.Assets', null, global);
goog.exportSymbol('proto.mlmbox.types.AssetPair.Id.QueryCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.AssetPair = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.AssetPair, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.AssetPair.displayName = 'proto.mlmbox.types.AssetPair';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.AssetPair.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.types.AssetPair.Id.oneofGroups_);
};
goog.inherits(proto.mlmbox.types.AssetPair.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.AssetPair.Id.displayName = 'proto.mlmbox.types.AssetPair.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.AssetPair.Id.Assets = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.AssetPair.Id.Assets, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.AssetPair.Id.Assets.displayName = 'proto.mlmbox.types.AssetPair.Id.Assets';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.AssetPair.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.AssetPair.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.AssetPair} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetPairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetPairName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    sourceAssetId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    targetAssetId: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.AssetPair}
 */
proto.mlmbox.types.AssetPair.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.AssetPair;
  return proto.mlmbox.types.AssetPair.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.AssetPair} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.AssetPair}
 */
proto.mlmbox.types.AssetPair.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetPairId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPairName(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourceAssetId(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTargetAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.AssetPair.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.AssetPair.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.AssetPair} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetPairId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetPairName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSourceAssetId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getTargetAssetId();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.types.AssetPair.Id.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.mlmbox.types.AssetPair.Id.QueryCase = {
  QUERY_NOT_SET: 0,
  ASSET_PAIR_ID: 1,
  ASSET_PAIR_NAME: 2,
  ASSETS: 3
};

/**
 * @return {proto.mlmbox.types.AssetPair.Id.QueryCase}
 */
proto.mlmbox.types.AssetPair.Id.prototype.getQueryCase = function() {
  return /** @type {proto.mlmbox.types.AssetPair.Id.QueryCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.AssetPair.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.AssetPair.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.AssetPair.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetPairId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetPairName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assets: (f = msg.getAssets()) && proto.mlmbox.types.AssetPair.Id.Assets.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.AssetPair.Id}
 */
proto.mlmbox.types.AssetPair.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.AssetPair.Id;
  return proto.mlmbox.types.AssetPair.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.AssetPair.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.AssetPair.Id}
 */
proto.mlmbox.types.AssetPair.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetPairId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPairName(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.AssetPair.Id.Assets;
      reader.readMessage(value,proto.mlmbox.types.AssetPair.Id.Assets.deserializeBinaryFromReader);
      msg.setAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.AssetPair.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.AssetPair.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.AssetPair.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssets();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.types.AssetPair.Id.Assets.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.AssetPair.Id.Assets.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.AssetPair.Id.Assets} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.Id.Assets.toObject = function(includeInstance, msg) {
  var f, obj = {
    sourceAsset: (f = msg.getSourceAsset()) && mlmbox_types_asset_pb.Asset.Id.toObject(includeInstance, f),
    targetAsset: (f = msg.getTargetAsset()) && mlmbox_types_asset_pb.Asset.Id.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets}
 */
proto.mlmbox.types.AssetPair.Id.Assets.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.AssetPair.Id.Assets;
  return proto.mlmbox.types.AssetPair.Id.Assets.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.AssetPair.Id.Assets} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets}
 */
proto.mlmbox.types.AssetPair.Id.Assets.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_types_asset_pb.Asset.Id;
      reader.readMessage(value,mlmbox_types_asset_pb.Asset.Id.deserializeBinaryFromReader);
      msg.setSourceAsset(value);
      break;
    case 2:
      var value = new mlmbox_types_asset_pb.Asset.Id;
      reader.readMessage(value,mlmbox_types_asset_pb.Asset.Id.deserializeBinaryFromReader);
      msg.setTargetAsset(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.AssetPair.Id.Assets.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.AssetPair.Id.Assets} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.AssetPair.Id.Assets.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSourceAsset();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_types_asset_pb.Asset.Id.serializeBinaryToWriter
    );
  }
  f = message.getTargetAsset();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_types_asset_pb.Asset.Id.serializeBinaryToWriter
    );
  }
};


/**
 * optional Asset.Id source_asset = 1;
 * @return {?proto.mlmbox.types.Asset.Id}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.getSourceAsset = function() {
  return /** @type{?proto.mlmbox.types.Asset.Id} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_asset_pb.Asset.Id, 1));
};


/**
 * @param {?proto.mlmbox.types.Asset.Id|undefined} value
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets} returns this
*/
proto.mlmbox.types.AssetPair.Id.Assets.prototype.setSourceAsset = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets} returns this
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.clearSourceAsset = function() {
  return this.setSourceAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.hasSourceAsset = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Asset.Id target_asset = 2;
 * @return {?proto.mlmbox.types.Asset.Id}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.getTargetAsset = function() {
  return /** @type{?proto.mlmbox.types.Asset.Id} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_asset_pb.Asset.Id, 2));
};


/**
 * @param {?proto.mlmbox.types.Asset.Id|undefined} value
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets} returns this
*/
proto.mlmbox.types.AssetPair.Id.Assets.prototype.setTargetAsset = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.AssetPair.Id.Assets} returns this
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.clearTargetAsset = function() {
  return this.setTargetAsset(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.AssetPair.Id.Assets.prototype.hasTargetAsset = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 asset_pair_id = 1;
 * @return {number}
 */
proto.mlmbox.types.AssetPair.Id.prototype.getAssetPairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
 */
proto.mlmbox.types.AssetPair.Id.prototype.setAssetPairId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
 */
proto.mlmbox.types.AssetPair.Id.prototype.clearAssetPairId = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.AssetPair.Id.prototype.hasAssetPairId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string asset_pair_name = 2;
 * @return {string}
 */
proto.mlmbox.types.AssetPair.Id.prototype.getAssetPairName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
 */
proto.mlmbox.types.AssetPair.Id.prototype.setAssetPairName = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
 */
proto.mlmbox.types.AssetPair.Id.prototype.clearAssetPairName = function() {
  return jspb.Message.setOneofField(this, 2, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.AssetPair.Id.prototype.hasAssetPairName = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Assets assets = 3;
 * @return {?proto.mlmbox.types.AssetPair.Id.Assets}
 */
proto.mlmbox.types.AssetPair.Id.prototype.getAssets = function() {
  return /** @type{?proto.mlmbox.types.AssetPair.Id.Assets} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.AssetPair.Id.Assets, 3));
};


/**
 * @param {?proto.mlmbox.types.AssetPair.Id.Assets|undefined} value
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
*/
proto.mlmbox.types.AssetPair.Id.prototype.setAssets = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mlmbox.types.AssetPair.Id.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.AssetPair.Id} returns this
 */
proto.mlmbox.types.AssetPair.Id.prototype.clearAssets = function() {
  return this.setAssets(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.AssetPair.Id.prototype.hasAssets = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional uint32 asset_pair_id = 1;
 * @return {number}
 */
proto.mlmbox.types.AssetPair.prototype.getAssetPairId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.AssetPair} returns this
 */
proto.mlmbox.types.AssetPair.prototype.setAssetPairId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_pair_name = 2;
 * @return {string}
 */
proto.mlmbox.types.AssetPair.prototype.getAssetPairName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.AssetPair} returns this
 */
proto.mlmbox.types.AssetPair.prototype.setAssetPairName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional uint32 source_asset_id = 3;
 * @return {number}
 */
proto.mlmbox.types.AssetPair.prototype.getSourceAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.AssetPair} returns this
 */
proto.mlmbox.types.AssetPair.prototype.setSourceAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 target_asset_id = 4;
 * @return {number}
 */
proto.mlmbox.types.AssetPair.prototype.getTargetAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.AssetPair} returns this
 */
proto.mlmbox.types.AssetPair.prototype.setTargetAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


goog.object.extend(exports, proto.mlmbox.types);
