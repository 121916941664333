import { message } from 'antd';
import { FC } from 'react';
import ReactDOM from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Button from './components/Common/Button/Button';
import Modal from './components/Modal/Modal';
import './i18n';
import './index.scss';
import { store } from './store/store';
import './styles/variables.scss';
import { copyToClipboard } from './utils/helper';
import ScrollToTop from './utils/scrollToTop';

const Fallback: FC<{ error: Error; resetErrorBoundary: any }> = ({ error, resetErrorBoundary }) => {
    const { t } = useTranslation();
    return (
        <Modal closeBtn={() => resetErrorBoundary && resetErrorBoundary()}>
            <div className="error">
                <h1>{t('bound_error_header')}</h1>
                <h4>{t('bound_tp')}</h4>
                <p>
                    {error.message}{' '}
                    <button
                        onClick={async () => {
                            message.success(t('copy'));
                            await copyToClipboard(`Error: ${error.message}`);
                        }}>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none">
                            <g opacity="0.8">
                                <path
                                    d="M12.4058 4.12821L8.43555 0.157949C8.33466 0.0569387 8.1978 0.000125678 8.05503 0H6.2817C5.75807 0 5.25588 0.208012 4.88562 0.578276C4.51535 0.948541 4.30734 1.45073 4.30734 1.97436V2.87179H3.40991C2.88627 2.87179 2.38409 3.07981 2.01382 3.45007C1.64356 3.82034 1.43555 4.32252 1.43555 4.84615V12.0256C1.43555 12.5493 1.64356 13.0515 2.01382 13.4217C2.38409 13.792 2.88627 14 3.40991 14H8.43555C8.95918 14 9.46137 13.792 9.83163 13.4217C10.2019 13.0515 10.4099 12.5493 10.4099 12.0256V11.1282H10.5894C11.113 11.1282 11.6152 10.9202 11.9855 10.5499C12.3557 10.1797 12.5638 9.67748 12.5638 9.15385V4.48718C12.5581 4.35192 12.5017 4.22375 12.4058 4.12821ZM8.61503 1.83795L10.7258 3.94872H8.61503V1.83795ZM9.33298 12.0256C9.33298 12.2637 9.23843 12.4919 9.07013 12.6602C8.90183 12.8285 8.67356 12.9231 8.43555 12.9231H3.40991C3.17189 12.9231 2.94362 12.8285 2.77532 12.6602C2.60702 12.4919 2.51247 12.2637 2.51247 12.0256V4.84615C2.51247 4.60814 2.60702 4.37987 2.77532 4.21157C2.94362 4.04327 3.17189 3.94872 3.40991 3.94872H4.30734V9.15385C4.30734 9.67748 4.51535 10.1797 4.88562 10.5499C5.25588 10.9202 5.75807 11.1282 6.2817 11.1282H9.33298V12.0256ZM10.5894 10.0513H6.2817C6.04369 10.0513 5.81542 9.95673 5.64712 9.78843C5.47882 9.62013 5.38426 9.39186 5.38426 9.15385V1.97436C5.38426 1.73634 5.47882 1.50808 5.64712 1.33978C5.81542 1.17147 6.04369 1.07692 6.2817 1.07692H7.53811V4.48718C7.53997 4.62941 7.5973 4.76529 7.69788 4.86587C7.79846 4.96645 7.93434 5.02378 8.07657 5.02564H11.4868V9.15385C11.4868 9.39186 11.3923 9.62013 11.224 9.78843C11.0557 9.95673 10.8274 10.0513 10.5894 10.0513Z"
                                    fill="#89B1FF"
                                />
                            </g>
                        </svg>
                    </button>
                </p>
                {/* <span>{error.stack}</span> */}
                <div className="button">
                    <Button
                        title={t('menu.help')}
                        background="var(--gr-b)"
                        color="#fff"
                        onCick={() => window.Telegram.WebApp.openTelegramLink('https://t.me/TTM_HelpBot')}
                    />
                </div>
            </div>
        </Modal>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <ScrollToTop />
            <ErrorBoundary FallbackComponent={Fallback}>
                <App />
            </ErrorBoundary>
        </BrowserRouter>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
