import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Account } from '../../../generated/mlmbox/types/account_pb';

import { getColor, getIdsByBitMask } from '../../../utils/helper';
import Ava from '../Ava/Ava';
import './UserBanner.scss';

export interface IUserBanner extends Account.Info.AsObject {
    handleClick?: Function;
}

const UserBanner: FC<IUserBanner> = ({ channelsList, account, profile, treeIdsBitMask, handleClick, relationship }) => {
    const { t } = useTranslation();

    function click() {
        if (handleClick) handleClick();
    }

    function getName(): string {
        if (channelsList.includes(1)) {
            if (profile?.firstName.length === 0 && profile?.lastName.length === 0) return t('unknown_duck');
            return `${profile?.firstName} ${' '} ${profile?.lastName}`;
        }

        if (profile?.telegram?.username) return `${profile.telegram.username}`;

        if (channelsList.includes(2) || channelsList.includes(3)) return t('unknown_duck');

        return '';
    }

    return (
        <div
            className="user-banner"
            onClick={click}>
            <Ava
                matrix={true}
                width="40px"
                height="40px"
                collectionId={profile?.ava?.collectionId}
                entityId={profile?.ava?.entityId}
                borderColor={getColor(relationship?.kind, relationship?.level)}
            />
            <div className="text">
                <h3>{getName()}</h3>
                <p>
                    {account?.login && (
                        <>
                            @{account?.login}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="2"
                                height="12"
                                viewBox="0 0 2 12"
                                fill="none">
                                <path
                                    d="M1 0V12"
                                    stroke="#506690"
                                />
                            </svg>
                        </>
                    )}
                    {getIdsByBitMask(treeIdsBitMask, channelsList)}
                </p>
            </div>
        </div>
    );
};

export default UserBanner;
