// source: mlmbox/client/auth/auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mlmbox_types_account_pb = require('../../../mlmbox/types/account_pb.js');
goog.object.extend(proto, mlmbox_types_account_pb);
var mlmbox_types_status_pb = require('../../../mlmbox/types/status_pb.js');
goog.object.extend(proto, mlmbox_types_status_pb);
var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
goog.exportSymbol('proto.mlmbox.client.auth.AuthSignResponse', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.RemoteSignInEvent', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.RemoteSignInEvent.EventCase', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.RemoteSignInEvent.Waiting', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.TelegramBotSignRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.TokenGenerateResponse', null, global);
goog.exportSymbol('proto.mlmbox.client.auth.TokenValidateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.TelegramBotSignRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.TelegramBotSignRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.TelegramBotSignRequest.displayName = 'proto.mlmbox.client.auth.TelegramBotSignRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.AuthSignResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.AuthSignResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.AuthSignResponse.displayName = 'proto.mlmbox.client.auth.AuthSignResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.TokenValidateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.TokenValidateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.TokenValidateResponse.displayName = 'proto.mlmbox.client.auth.TokenValidateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.TokenGenerateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.TokenGenerateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.TokenGenerateResponse.displayName = 'proto.mlmbox.client.auth.TokenGenerateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.RemoteSignInEvent = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_);
};
goog.inherits(proto.mlmbox.client.auth.RemoteSignInEvent, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.RemoteSignInEvent.displayName = 'proto.mlmbox.client.auth.RemoteSignInEvent';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.displayName = 'proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.auth.RemoteSignInEvent.Waiting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.displayName = 'proto.mlmbox.client.auth.RemoteSignInEvent.Waiting';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.TelegramBotSignRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.TelegramBotSignRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    channelId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    initData: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.TelegramBotSignRequest}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.TelegramBotSignRequest;
  return proto.mlmbox.client.auth.TelegramBotSignRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.TelegramBotSignRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.TelegramBotSignRequest}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setChannelId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.TelegramBotSignRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.TelegramBotSignRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChannelId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getInitData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 channel_id = 1;
 * @return {number}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.getChannelId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.auth.TelegramBotSignRequest} returns this
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.setChannelId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string init_data = 2;
 * @return {string}
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.getInitData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.TelegramBotSignRequest} returns this
 */
proto.mlmbox.client.auth.TelegramBotSignRequest.prototype.setInitData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.AuthSignResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.AuthSignResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.AuthSignResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    authStatus: jspb.Message.getFieldWithDefault(msg, 1, 0),
    referralStatus: jspb.Message.getFieldWithDefault(msg, 2, 0),
    accessToken: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.AuthSignResponse}
 */
proto.mlmbox.client.auth.AuthSignResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.AuthSignResponse;
  return proto.mlmbox.client.auth.AuthSignResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.AuthSignResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.AuthSignResponse}
 */
proto.mlmbox.client.auth.AuthSignResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.types.Status.Auth} */ (reader.readEnum());
      msg.setAuthStatus(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.types.Status.Referral} */ (reader.readEnum());
      msg.setReferralStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.AuthSignResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.AuthSignResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.AuthSignResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAuthStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getReferralStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional mlmbox.types.Status.Auth auth_status = 1;
 * @return {!proto.mlmbox.types.Status.Auth}
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.getAuthStatus = function() {
  return /** @type {!proto.mlmbox.types.Status.Auth} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.types.Status.Auth} value
 * @return {!proto.mlmbox.client.auth.AuthSignResponse} returns this
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.setAuthStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional mlmbox.types.Status.Referral referral_status = 2;
 * @return {!proto.mlmbox.types.Status.Referral}
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.getReferralStatus = function() {
  return /** @type {!proto.mlmbox.types.Status.Referral} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.types.Status.Referral} value
 * @return {!proto.mlmbox.client.auth.AuthSignResponse} returns this
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.setReferralStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string access_token = 3;
 * @return {string}
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.AuthSignResponse} returns this
 */
proto.mlmbox.client.auth.AuthSignResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.TokenValidateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.TokenValidateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.TokenValidateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TokenValidateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.TokenValidateResponse}
 */
proto.mlmbox.client.auth.TokenValidateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.TokenValidateResponse;
  return proto.mlmbox.client.auth.TokenValidateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.TokenValidateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.TokenValidateResponse}
 */
proto.mlmbox.client.auth.TokenValidateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.types.Status.TokenValidate} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.TokenValidateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.TokenValidateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.TokenValidateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TokenValidateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional mlmbox.types.Status.TokenValidate status = 1;
 * @return {!proto.mlmbox.types.Status.TokenValidate}
 */
proto.mlmbox.client.auth.TokenValidateResponse.prototype.getStatus = function() {
  return /** @type {!proto.mlmbox.types.Status.TokenValidate} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.types.Status.TokenValidate} value
 * @return {!proto.mlmbox.client.auth.TokenValidateResponse} returns this
 */
proto.mlmbox.client.auth.TokenValidateResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.TokenGenerateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.TokenGenerateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.TokenGenerateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TokenGenerateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.TokenGenerateResponse}
 */
proto.mlmbox.client.auth.TokenGenerateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.TokenGenerateResponse;
  return proto.mlmbox.client.auth.TokenGenerateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.TokenGenerateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.TokenGenerateResponse}
 */
proto.mlmbox.client.auth.TokenGenerateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.TokenGenerateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.TokenGenerateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.TokenGenerateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.TokenGenerateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.mlmbox.client.auth.TokenGenerateResponse.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.TokenGenerateResponse} returns this
 */
proto.mlmbox.client.auth.TokenGenerateResponse.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_ = [[1,2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.EventCase = {
  EVENT_NOT_SET: 0,
  GUEST_ACCESS_TOKEN: 1,
  SECRET_CODE: 2,
  WAITING: 3,
  ACCOUNT_ACCESS_TOKEN: 4,
  CANCELED: 5,
  WAITING_EXPIRED: 6,
  EXPIRED_AT: 7
};

/**
 * @return {proto.mlmbox.client.auth.RemoteSignInEvent.EventCase}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getEventCase = function() {
  return /** @type {proto.mlmbox.client.auth.RemoteSignInEvent.EventCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.RemoteSignInEvent.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.toObject = function(includeInstance, msg) {
  var f, obj = {
    guestAccessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    secretCode: (f = msg.getSecretCode()) && proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.toObject(includeInstance, f),
    waiting: (f = msg.getWaiting()) && proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.toObject(includeInstance, f),
    accountAccessToken: jspb.Message.getFieldWithDefault(msg, 4, ""),
    canceled: (f = msg.getCanceled()) && mlmbox_types_account_pb.Account.Info.toObject(includeInstance, f),
    waitingExpired: (f = msg.getWaitingExpired()) && mlmbox_types_account_pb.Account.Info.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 7, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.RemoteSignInEvent;
  return proto.mlmbox.client.auth.RemoteSignInEvent.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setGuestAccessToken(value);
      break;
    case 2:
      var value = new proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode;
      reader.readMessage(value,proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.deserializeBinaryFromReader);
      msg.setSecretCode(value);
      break;
    case 3:
      var value = new proto.mlmbox.client.auth.RemoteSignInEvent.Waiting;
      reader.readMessage(value,proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.deserializeBinaryFromReader);
      msg.setWaiting(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAccessToken(value);
      break;
    case 5:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.setCanceled(value);
      break;
    case 6:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.setWaitingExpired(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.RemoteSignInEvent.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSecretCode();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.serializeBinaryToWriter
    );
  }
  f = message.getWaiting();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getCanceled();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getWaitingExpired();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 7));
  if (f != null) {
    writer.writeUint32(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode;
  return proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 expired_at = 2;
 * @return {number}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && mlmbox_types_account_pb.Account.Info.toObject(includeInstance, f),
    expiredAt: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.auth.RemoteSignInEvent.Waiting;
  return proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiredAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getExpiredAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional mlmbox.types.Account.Info account = 1;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Info, 1));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} returns this
*/
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 expired_at = 2;
 * @return {number}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.Waiting.prototype.setExpiredAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string guest_access_token = 1;
 * @return {string}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getGuestAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setGuestAccessToken = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearGuestAccessToken = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasGuestAccessToken = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional SecretCode secret_code = 2;
 * @return {?proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getSecretCode = function() {
  return /** @type{?proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode, 2));
};


/**
 * @param {?proto.mlmbox.client.auth.RemoteSignInEvent.SecretCode|undefined} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
*/
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setSecretCode = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearSecretCode = function() {
  return this.setSecretCode(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasSecretCode = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Waiting waiting = 3;
 * @return {?proto.mlmbox.client.auth.RemoteSignInEvent.Waiting}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getWaiting = function() {
  return /** @type{?proto.mlmbox.client.auth.RemoteSignInEvent.Waiting} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.client.auth.RemoteSignInEvent.Waiting, 3));
};


/**
 * @param {?proto.mlmbox.client.auth.RemoteSignInEvent.Waiting|undefined} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
*/
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setWaiting = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearWaiting = function() {
  return this.setWaiting(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasWaiting = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string account_access_token = 4;
 * @return {string}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getAccountAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setAccountAccessToken = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearAccountAccessToken = function() {
  return jspb.Message.setOneofField(this, 4, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasAccountAccessToken = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional mlmbox.types.Account.Info canceled = 5;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getCanceled = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Info, 5));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
*/
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setCanceled = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearCanceled = function() {
  return this.setCanceled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasCanceled = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional mlmbox.types.Account.Info waiting_expired = 6;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getWaitingExpired = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Info, 6));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
*/
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setWaitingExpired = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearWaitingExpired = function() {
  return this.setWaitingExpired(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasWaitingExpired = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional uint32 expired_at = 7;
 * @return {number}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.getExpiredAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.setExpiredAt = function(value) {
  return jspb.Message.setOneofField(this, 7, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.client.auth.RemoteSignInEvent} returns this
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.clearExpiredAt = function() {
  return jspb.Message.setOneofField(this, 7, proto.mlmbox.client.auth.RemoteSignInEvent.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.auth.RemoteSignInEvent.prototype.hasExpiredAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


goog.object.extend(exports, proto.mlmbox.client.auth);
