import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import Ava from '../../components/Common/Ava/Ava';
import Button from '../../components/Common/Button/Button';
import ShortProfile from '../../components/Common/ShortProfile/ShortProfile';
import Locale from '../../components/Locale/Locale';
import UserProfile from '../../components/UserProfile/UserProfile';
import { BinaryClient } from '../../generated/mlmbox/client/binary/BinaryServiceClientPb';
import * as mlmbox_client_network_network_pb from '../../generated/mlmbox/client/network/network_pb';
import { NetworkClient } from '../../generated/mlmbox/client/network/NetworkServiceClientPb';
import * as mlmbox_types_account_pb from '../../generated/mlmbox/types/account_pb';
import * as mlmbox_types_sort_pb from '../../generated/mlmbox/types/sort_pb';
import { setAuth } from '../../store/auth/auth.slice';
import { TRootState } from '../../store/store';
import { API_URL } from '../../utils/constants';
import { filterNonEmptyBinaryTree, getColor, getLoginOrId, getLoginOrIdByData, getToken, PositionNode, setAvas, ViewState } from '../../utils/helper';
import './Areas.scss';
import LoadMatrix from './Components/LoadMatrix';
import { TreeNodes } from './Components/TreeNodes';
const cookies = new Cookies();
const Areas: FC = () => {
    const acc = useSelector((state: TRootState) => state.user.value);
    const schemes = useSelector((state: TRootState) => state.schemes.value);
    const treeAccountsList = useSelector((state: TRootState) => state.user.value.treeAccountsList);
    const [del, setDel] = useState(0.1);
    const [open, setOpen] = useState(false);
    const [tree, setTree] = useState<PositionNode | undefined>();

    const [prevPositionId, setPrevPositionId] = useState<number>(0);

    const [positionId, setPositionId] = useState<number | null>(null);

    const [userView, setUserView] = useState<mlmbox_types_account_pb.Account.Info.AsObject | null>();
    const [shortInfo, setShotInfo] = useState<mlmbox_client_network_network_pb.TeamResponse.AsObject | null>();
    const [info, setInfo] = useState<mlmbox_types_account_pb.Account.Info.AsObject | null>();
    const [userInfoOpenB, setUserInfoOpenB] = useState(false);

    const [matrixView, setMatrixView] = useState<mlmbox_types_account_pb.Binary.View.AsObject>();
    const [positionIdList, setPositionIdList] = useState<mlmbox_types_account_pb.TreeAccount.Entity.AsObject[]>([]);
    const [notHave, setNotHave] = useState(false);

    const [leftBranch, setLeftBranch] = useState<
        (mlmbox_types_account_pb.Account.Info.AsObject & mlmbox_types_account_pb.Binary.Position.AsObject) | null
    >(null);
    const [rightBranch, setRightBranch] = useState<
        (mlmbox_types_account_pb.Account.Info.AsObject & mlmbox_types_account_pb.Binary.Position.AsObject) | null
    >(null);
    const [mediumBranch, setMediumBranch] = useState<
        (mlmbox_types_account_pb.Account.Info.AsObject & mlmbox_types_account_pb.Binary.Position.AsObject) | null
    >(null);
    const [shortInfoB, setShortInfoB] = useState<mlmbox_client_network_network_pb.TeamResponse.AsObject | null>();
    const [infoB, setInfoB] = useState<mlmbox_types_account_pb.Account.Info.AsObject | null>();
    const [up, setUp] = useState(false);
    const [positionBinary, setPositionBinary] = useState<number | null>(null);
    const [fetchBinary, setFetchBinary] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingUser, setLoadingUser] = useState(true);
    const [noBinary, setNoBinary] = useState(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [tab, setTab] = useState<1 | 2>(1);

    useEffect(() => {
        if (userView && userView.account) {
            const accClient = new NetworkClient(API_URL, null, null);
            const req = new mlmbox_client_network_network_pb.TeamRequest();
            const sort = new mlmbox_types_sort_pb.Sort();
            sort.setLimit(10);
            sort.setDirection(mlmbox_types_sort_pb.Sort.Direction.BACKWARD);
            req.setFilterTreeIdsBitMask(0);
            const ids = new mlmbox_types_account_pb.Account.Id();
            ids.setAccountId(userView.account?.id);
            req.setViewPartner(ids);
            req.setPartnersSort(sort);

            accClient.teamInfo(req, { authorization: getToken() }, async (err, res) => {
                // console.log(err, res.toObject());
                if (!err) {
                    const info = res.toObject();
                    if (info) {
                        await setAvas(info.partnersList);
                        await setAvas(info.referralsList);
                    }
                    setShotInfo(res.toObject());
                } else {
                    setInfo(userView);
                }

                setLoadingUser(false);
            });
        } else {
            setInfo(null);
            setShortInfoB(null);
        }
    }, [userView]);

    function getBranchSelectStrategy(
        branchSelectStrategy: mlmbox_types_account_pb.Binary.BranchSelectStrategy.Id,
        branchLeft: mlmbox_types_account_pb.Binary.Position.Branch.AsObject,
        branchRight: mlmbox_types_account_pb.Binary.Position.Branch.AsObject
    ): mlmbox_types_account_pb.Binary.BranchSelectStrategy.Id {
        if (branchSelectStrategy !== mlmbox_types_account_pb.Binary.BranchSelectStrategy.Id.EVENLY) return branchSelectStrategy;

        return branchLeft.teamQuantity < branchRight.teamQuantity || branchRight.teamQuantity === branchLeft.teamQuantity
            ? mlmbox_types_account_pb.Binary.BranchSelectStrategy.Id.LEFT
            : mlmbox_types_account_pb.Binary.BranchSelectStrategy.Id.RIGHT;
    }

    useEffect(() => {
        if (matrixView?.positionsList.length) {
            // const tree = fillTree(matrixView, position);

            const view = new ViewState();

            schemes.treeGroupsList.forEach((el) => {
                view.addGroup({ title: el.title, id: el.id });
            });
            schemes.treesList.forEach((el) => {
                view.addTree({
                    amountValue: String(el.amount?.value),
                    groupId: el.groupId,
                    title: el.title,
                    defaultPositionWidth: el.defaultPositionWidth,
                    id: el.id,
                    amountAssetId: Number(el.amount?.assetId),
                });

                view.addGroup({ id: el.id, title: el.title });
            });

            matrixView.positionsList.forEach((el) => {
                matrixView.accountsList.forEach((account) => {
                    if (el.accountId === account.account?.id) {
                        view.addPosition({
                            positionId: el.positionId,
                            positionRefId: el.positionRefId,
                            positionRefLine: el.positionRefBranch,
                            positionWidth: el.positionWidth,
                            accountId: Number(el.accountId),
                            treeAccountId: el.accountId,
                            ava: account.profile?.ava,
                            relationship: account.relationship,
                            loginOrId: getLoginOrId(account),
                            branchSelectCategory:
                                el.branchLeft && el.branchRight && getBranchSelectStrategy(el.branchSelectStrategy, el.branchLeft, el.branchRight),
                        });
                    }
                });

                //;
            });

            positionBinary
                ? setTree(view.cacheView(tab === 1 ? 16 : 17, Number(positionId), 4))
                : setTree(view.cacheView(tab === 1 ? 16 : 17, Number(positionId), 4));

            setLoading(false);
        }
    }, [matrixView]);

    useEffect(() => {
        if (!filterNonEmptyBinaryTree(treeAccountsList, tab === 1 ? 16 : 17).length) {
            setLoading(false);
            setNoBinary(true);
            return;
        } else {
            setNoBinary(false);
        }

        setShotInfo(null);
        setInfo(null);
        setTree(undefined);
        setLoading(true);
        setLoadingUser(true);

        const binaryClient = new BinaryClient(API_URL, null, null);
        const req = new mlmbox_types_account_pb.PositionView.Team();
        setMediumBranch(null);
        setLeftBranch(null);
        setRightBranch(null);
        // console.log(positionBinary, tab);
        req.setViewLevel(4);
        if (positionBinary) {
            req.setViewPositionId(positionBinary);
        } else {
            const id = new mlmbox_types_account_pb.TreeAccount.Id();
            if (tab === 1) id.setTreeId(16);
            else id.setTreeId(17);
            id.setAccountId(acc.info.account.id);
            req.setTreeAccountId(id);
        }

        // if (positionBinary) {
        //     !up && mediumBranch?.positionId && setPositionBinaryList((prev) => [...prev, mediumBranch?.positionId]);
        // } else {
        //     setPositionBinaryList([]);
        // }
        // setUp(false);

        binaryClient.view(req, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                res.toObject().config?.bonusBinaryPercent && setDel(Number(res.toObject().config?.bonusBinaryPercent));
                const info = res.toObject();

                if (info) {
                    await setAvas(info.accountsList);
                }
                console.log(res.toObject());
                setPositionId(res.toObject().viewPositionId);
                setMatrixView(res.toObject());
                res.toObject().positionsList.forEach((el) => {
                    if (el.positionId === res.toObject().viewPositionId) {
                        if (res.toObject().viewPositionId !== res.toObject().selfPositionId) {
                            setPrevPositionId(el.positionRefId);
                        } else {
                            setPrevPositionId(0);
                            setPositionBinary(null);
                        }
                    }

                    res.toObject().accountsList.forEach((account) => {
                        if (el.positionId === res.toObject().viewPositionId) {
                            if (el.accountId === account.account?.id) setUserView(account);
                        }
                    });
                });
            }

            // setFetchBinary(false);
        });
    }, [positionBinary, fetchBinary]);

    useEffect(() => {
        if (loading) return;
        setPositionBinary(null);
        setFetchBinary((prev) => !prev);
    }, [tab]);

    function getAccount(id: number) {
        const accClient = new NetworkClient(API_URL, null, null);
        const request = new mlmbox_client_network_network_pb.TeamRequest();
        const sort = new mlmbox_types_sort_pb.Sort();
        sort.setLimit(10);
        sort.setDirection(mlmbox_types_sort_pb.Sort.Direction.BACKWARD);
        request.setFilterTreeIdsBitMask(0);
        const ids = new mlmbox_types_account_pb.Account.Id();
        ids.setAccountId(id);
        request.setViewPartner(ids);
        request.setPartnersSort(sort);

        accClient.teamInfo(request, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                const info = res.toObject();
                if (info) {
                    await setAvas(info.partnersList);
                    await setAvas(info.referralsList);
                }
                setShortInfoB(res.toObject());
                setUserInfoOpenB(true);
            } else {
                mediumBranch?.channelsList &&
                    setInfoB({
                        account: mediumBranch?.account,
                        channelsList: mediumBranch?.channelsList,

                        referralBranch: mediumBranch?.referralBranch,
                        referral: mediumBranch?.referral,
                        registrationAt: mediumBranch?.registrationAt,
                        profile: mediumBranch?.profile,
                        relationship: mediumBranch?.relationship,
                        statistics: mediumBranch?.statistics,
                        marketingStartedAt: mediumBranch.marketingStartedAt,
                        treeIdsBitMask: mediumBranch?.treeIdsBitMask,
                    });
                setUserInfoOpenB(true);
            }
        });
    }

    return (
        <>
            <div
                className="areas"
                id="section">
                {noBinary ? (
                    <></>
                ) : (
                    <div
                        className={`areas__user ${open ? 'open' : ''} ${loadingUser ? 'loading' : ''}`}
                        onMouseEnter={(e) => setOpen(true)}
                        // onMouseLeave={() => setOpen(false)}
                        onBlur={() => setOpen(false)}>
                        {loadingUser && (
                            <div className={`load-user `}>
                                <div className="load-user__info">
                                    <SkeletonTheme
                                        width={80}
                                        height={80}
                                        borderRadius={'50%'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton count={1} />
                                    </SkeletonTheme>

                                    <SkeletonTheme
                                        width={220}
                                        height={21}
                                        borderRadius={'16px'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            className="load-user__info--link"
                                            count={1}
                                        />
                                    </SkeletonTheme>
                                    <SkeletonTheme
                                        width={220}
                                        height={21}
                                        borderRadius={'16px'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            className="load-user__info--link"
                                            count={1}
                                        />
                                    </SkeletonTheme>
                                </div>
                            </div>
                        )}
                        {!loadingUser && shortInfo && <UserProfile {...shortInfo} />}
                        {!loadingUser && info && <ShortProfile {...info} />}

                        <svg
                            className={`close-arr ${open ? 'open' : ''}`}
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none">
                            <path
                                d="M6.01744 7.39803C5.91269 7.39852 5.8089 7.37809 5.71215 7.33793C5.61541 7.29778 5.52766 7.2387 5.45405 7.16417L0.139066 1.84919C0.0330583 1.6941 -0.0147788 1.50656 0.00399088 1.31964C0.0227606 1.13273 0.106932 0.958441 0.241659 0.827527C0.376386 0.696613 0.553015 0.617479 0.740393 0.604082C0.92777 0.590685 1.11386 0.643885 1.26584 0.754301L6.01744 5.47401L10.769 0.754301C10.9189 0.657152 11.097 0.613122 11.2748 0.629266C11.4527 0.645411 11.6199 0.720795 11.7498 0.843336C11.8797 0.965878 11.9647 1.12848 11.9912 1.30508C12.0177 1.48168 11.9841 1.66206 11.8958 1.8173L6.58083 7.13228C6.50996 7.2126 6.42344 7.2776 6.32656 7.3233C6.22968 7.369 6.12449 7.39443 6.01744 7.39803Z"
                                fill="#506690"></path>
                        </svg>
                    </div>
                )}
                <div
                    className={`area__main ${loading ? 'loading' : ''} ${noBinary ? 'no-binary' : 'exist-binary'}`}
                    onClick={() => setOpen(false)}>
                    {noBinary && (
                        <div className="no-active">
                            <div className="area__main--header">
                                {' '}
                                <div className="tabs">
                                    <div className="btns">
                                        <button
                                            onClick={() => !loading && setTab(1)}
                                            className={tab === 1 ? 'active' : ''}>
                                            B25
                                        </button>
                                        <button
                                            onClick={() => !loading && setTab(2)}
                                            className={tab === 2 ? 'active' : ''}>
                                            B800
                                        </button>
                                    </div>
                                </div>
                                <div className="sign-out">
                                    <Button
                                        onCick={() => {
                                            cookies.remove('__tt');
                                            dispatch(setAuth({ isAuth: '' }));
                                        }}
                                        background="var(--gr-b)"
                                        color="#fff"
                                        title={t('exit')}
                                    />
                                </div>
                            </div>
                            <h1>{tab === 1 ? t('bin.work_not_have') : t('bin.buisines_not_have')}</h1>
                        </div>
                    )}
                    <div className="version">PC-Binary Beta_v0.1</div>
                    <div className="lcl">
                        <Locale />
                    </div>
                    {!noBinary && (
                        <div className="area__main--header">
                            {' '}
                            <div className="tabs">
                                <div className="btns">
                                    <button
                                        onClick={() => !loading && setTab(1)}
                                        className={tab === 1 ? 'active' : ''}>
                                        B25
                                    </button>
                                    <button
                                        onClick={() => !loading && setTab(2)}
                                        className={tab === 2 ? 'active' : ''}>
                                        B800
                                    </button>
                                </div>
                            </div>
                            <div className="stat">
                                <div className="stat__item">
                                    <p>{t('l_t')}</p>
                                    {!loading && matrixView && (
                                        <h1>
                                            {matrixView?.positionsList[0].branchLeft?.teamQuantity} {t('human')}
                                        </h1>
                                    )}

                                    {loading && (
                                        <SkeletonTheme
                                            height={21}
                                            baseColor="rgba(56, 56, 57, 0.3)"
                                            highlightColor="rgba(56, 56, 57, 0.5)">
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                                <div className="stat__item">
                                    <p>{t('pereves')}</p>
                                    {!loading && matrixView && (
                                        <h1>
                                            {matrixView?.positionsList[0]?.branchRight?.balanceValue &&
                                            matrixView?.positionsList[0]?.branchLeft?.balanceValue &&
                                            matrixView?.positionsList[0]?.branchLeft?.balanceValue >
                                                matrixView?.positionsList[0]?.branchRight?.balanceValue
                                                ? (Number(matrixView?.positionsList[0]?.branchLeft?.balanceValue) * del).toLocaleString()
                                                : (Number(matrixView?.positionsList[0]?.branchRight?.balanceValue) * del).toLocaleString()}
                                            <span>$</span>
                                        </h1>
                                    )}{' '}
                                    {loading && (
                                        <SkeletonTheme
                                            height={21}
                                            baseColor="rgba(56, 56, 57, 0.3)"
                                            highlightColor="rgba(56, 56, 57, 0.5)">
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                                <div className="stat__item">
                                    <p>{t('r_t')}</p>
                                    {!loading && matrixView && (
                                        <h1>
                                            {matrixView?.positionsList[0].branchRight?.teamQuantity} {t('human')}
                                        </h1>
                                    )}

                                    {loading && (
                                        <SkeletonTheme
                                            height={21}
                                            baseColor="rgba(56, 56, 57, 0.3)"
                                            highlightColor="rgba(56, 56, 57, 0.5)">
                                            <Skeleton count={1} />
                                        </SkeletonTheme>
                                    )}
                                </div>
                            </div>
                            <div className="sign-out">
                                <Button
                                    onCick={() => {
                                        cookies.remove('__tt');
                                        dispatch(setAuth({ isAuth: '' }));
                                    }}
                                    background="var(--gr-b)"
                                    color="#fff"
                                    title={t('exit')}
                                />
                            </div>
                        </div>
                    )}
                    {!noBinary && loading && !tree && <LoadMatrix />}
                    {!noBinary && !loading && tree && (
                        <>
                            <div className="border-circle">
                                {prevPositionId > 0 && (
                                    <div
                                        className="up"
                                        onClick={() => {
                                            setPositionBinary(prevPositionId);
                                        }}>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="10"
                                            height="6"
                                            viewBox="0 0 10 6"
                                            fill="none">
                                            <path
                                                d="M9 4.75L5.25 1L1.5 4.75"
                                                stroke="#A2D8FF"
                                                stroke-width="1.5"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            />
                                        </svg>
                                        <p>{t('up')}</p>
                                    </div>
                                )}
                                <div className="circle">
                                    <Ava
                                        width="84px"
                                        height="84px"
                                        borderColor={getColor(tree.position.relationship?.kind, tree.position.relationship?.level)}
                                        collectionId={tree.position.ava?.collectionId}
                                        entityId={tree.position.ava?.entityId}
                                    />
                                </div>
                                {info && info.account && (
                                    <div className="login-or-id">
                                        <h1>{getLoginOrIdByData(info.account)}</h1>
                                    </div>
                                )}
                                {shortInfo && shortInfo.account?.account && (
                                    <div className="login-or-id">
                                        <h1>{getLoginOrIdByData(shortInfo.account.account)}</h1>
                                    </div>
                                )}
                            </div>
                            <div className="circle-group">
                                {tree && (
                                    <TreeNodes
                                        setViewPosotionId={setPositionBinary}
                                        setFetching={setLoading}
                                        node={tree}
                                    />
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Areas;
