import { createSlice } from '@reduxjs/toolkit';

type Avatars = {
    value: { [key: string]: string };
};

const initialState: Avatars = {
    value: {
        '': '',
    },
};

export const avatarsSlice = createSlice({
    name: 'avatars',
    initialState,
    reducers: {
        setAvatars: (state, action: { payload: { [key: string]: string } }) => {
            state.value = action.payload;
        },
    },
});

export const { setAvatars } = avatarsSlice.actions;

export default avatarsSlice.reducer;
