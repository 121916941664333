import { FC } from 'react';
import { useNavigate } from 'react-router';
import { refreshIcon } from '../../../assets';
import { MatrixPictureCircle } from './Components/MatrixPictureCircle/MatrixPictureCircle';
import './MatrixPicture.scss';
import { IMatrixPicture } from './types';

const MatrixPicture: FC<IMatrixPicture> = ({ setOpenMatrix, entity, treeId, index }) => {
    const navigate = useNavigate();

    return (
        <div
            className={`matrix-picture ${!entity?.treeAccountEntityId ? 'disabled' : ''}`}
            onClick={() => entity?.positionId && setOpenMatrix({ position: entity?.positionId, treeId })}>
            <div className="matrix-picture__info">
                <h1 className="info__matrix">P{index + 1}</h1>
                <p className="info__tree-account-entity-id">
                    <img
                        src={refreshIcon}
                        alt=""
                    />
                    {entity?.treeAccountEntityId}
                </p>
            </div>
            <div className="matrix-picture__picture">
                <MatrixPictureCircle bit={entity ? entity?.filled2w2lBitMask.toString(2) : ''} />
            </div>
        </div>
    );
};

export default MatrixPicture;
