import React, { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router';
import './Modal.scss';

const Modal: React.FC<{
    title?: string;
    text?: string;
    btnTitle?: string;
    btnTo?: Function;
    closeBtn?: Function;
    classModal?: string;
    children?: ReactNode;
}> = ({ title, text, btnTitle, btnTo, closeBtn, children, classModal = '' }) => {
    const navigate = useNavigate();
    const [close, setClose] = useState(false);
    function closing() {
        if (!closeBtn) return;
        setClose(true);
        setTimeout(() => closeBtn(), 200);
    }
    return (
        <div className={`modal ${classModal}`}>
            <div
                className="out"
                onClick={closing}></div>
            <div
                className="modal__dialog"
                id={close ? 'close' : 'open'}>
                <span
                    className="modal__dialog--close-btn"
                    onClick={closing}>
                    {/* <img src={crossMini} alt="" /> */}
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none">
                        <path
                            d="M6.01744 7.39803C5.91269 7.39852 5.8089 7.37809 5.71215 7.33793C5.61541 7.29778 5.52766 7.2387 5.45405 7.16417L0.139066 1.84919C0.0330583 1.6941 -0.0147788 1.50656 0.00399088 1.31964C0.0227606 1.13273 0.106932 0.958441 0.241659 0.827527C0.376386 0.696613 0.553015 0.617479 0.740393 0.604082C0.92777 0.590685 1.11386 0.643885 1.26584 0.754301L6.01744 5.47401L10.769 0.754301C10.9189 0.657152 11.097 0.613122 11.2748 0.629266C11.4527 0.645411 11.6199 0.720795 11.7498 0.843336C11.8797 0.965878 11.9647 1.12848 11.9912 1.30508C12.0177 1.48168 11.9841 1.66206 11.8958 1.8173L6.58083 7.13228C6.50996 7.2126 6.42344 7.2776 6.32656 7.3233C6.22968 7.369 6.12449 7.39443 6.01744 7.39803Z"
                            fill="#506690"
                        />
                    </svg>
                </span>

                <div className="modal__dialog--content">
                    {children}
                    {btnTitle && btnTo && <button onClick={() => btnTo()}>{btnTitle}</button>}
                </div>
            </div>
        </div>
    );
};

export default Modal;
