import { createSlice } from '@reduxjs/toolkit';
import { Account, TreeAccount } from '../../generated/mlmbox/types/account_pb';
import { GoogleAuthenticator } from '../../generated/mlmbox/types/google_authenticator_pb';

export enum adminModeStatus {
    ON = 'on',
    OFF = 'off',
}

export interface IUserValue {
    value: {
        adminMode: adminModeStatus;
        loadingAmount: number;
        info: {
            profile: {
                firstName: string;
                lastName: string;
                telegram: {
                    id: number;
                    username: string;
                };
                ava: {
                    collectionId: number;
                    entityId: number;
                };
            };
            account: {
                id: number;
                login: string;
                refLinkId: string;
            };
            referral: {
                id: number;
                login: string;
                refLinkId: string;
            };
            relationship: {
                kind: number;
                level: number;
                branch: number;
            };
            registrationAt: number;
            marketingStartedAt: number;
            treeIdsBitMask: number;
            referralBranch: number;
            statistics: Account.Statistics.AsObject;
        };
        googleAuthenticator: GoogleAuthenticator.AsObject;
        matrixTreeAccountsList: TreeAccount.AsObject[];
        treeAccountsList: TreeAccount.AsObject[];
        locale: string;
        openLocale: boolean;
        isAdmin: boolean;
    };
}

const initialState: IUserValue = {
    value: {
        adminMode: adminModeStatus.OFF,
        loadingAmount: 0,
        info: {
            profile: {
                firstName: '',
                lastName: '',
                telegram: {
                    id: 0,
                    username: '',
                },
                ava: {
                    collectionId: 0,
                    entityId: 0,
                },
            },
            account: {
                id: 0,
                login: '',
                refLinkId: '',
            },
            referral: {
                id: 0,
                login: '',
                refLinkId: '',
            },
            relationship: {
                kind: 0,
                level: 0,
                branch: 0,
            },
            registrationAt: 0,
            marketingStartedAt: 0,
            treeIdsBitMask: 0,
            referralBranch: 0,
            statistics: {
                partnersQuantity: 0,
                teamQuantity: 0,
                marketingPartnersQuantity: 0,
                marketingTeamQuantity: 0,
            },
        },
        googleAuthenticator: {
            status: 0,
            modifiedAt: 0,
        },
        matrixTreeAccountsList: [],
        treeAccountsList: [],
        locale: '',
        openLocale: false,
        isAdmin: false,
    },
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        resetUser: (state, { payload }) => {
            state.value = { ...state.value, ...payload };
        },
        setLoadingAmount: (state, { payload }) => {
            state.value.loadingAmount = state.value.loadingAmount + 1;
        },
        setAdminMode: (state, { payload }: { payload: { status: adminModeStatus } }) => {
            state.value.adminMode = payload.status;
        },
        resetProfile: (state, { payload }) => {
            state.value = payload.state;
            state.value.info.profile = payload.profile;
        },
        resetLogin: (state, { payload }: { payload: { login: string } }) => {
            state.value.info.account.login = payload.login;
        },
        resetTwoFa: (state, { payload }: { payload: GoogleAuthenticator.AsObject | undefined }) => {
            if (!payload) return;
            state.value.googleAuthenticator = payload;
        },
        setOpenLocale: (state, action: { payload: boolean }) => {
            state.value.openLocale = action.payload;
        },
    },
});

export const { resetUser, resetProfile, resetLogin, resetTwoFa, setLoadingAmount, setOpenLocale, setAdminMode } = userSlice.actions;

export default userSlice.reducer;
