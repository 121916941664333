import { TreeAccount } from '../../../generated/mlmbox/types/account_pb';

export function formatString(s: string): string {
    return s.padStart(6, '0');
}

export function filledTreeAccountsList(treeAccountsList: TreeAccount.Set.AsObject[]): TreeAccount.Set.AsObject[] {
    const resultValue: TreeAccount.Set.AsObject[] = [];

    [...new Array(15)].map((_, index) => {
        let result: TreeAccount.Set.AsObject | null = null;
        treeAccountsList.map((el) => {
            if (index + 1 === el.treeId && el.entity?.treeAccountEntityId) {
                result = el;
            }
        });

        if (result) {
            resultValue.push(result);
        } else {
            resultValue.push({
                treeId: index + 1,
                quantity: 0,
                activatedQuantity: 0,
                activatedCapacity: 0,
                poolValue: '0',
                bookingQuantity: 0,
                entity: {
                    filled: false,
                    treeEntityId: 0,
                    filled2w2lBitMask: 0,
                    positionId: 0,
                    treeAccountEntityId: 0,
                    treeChainId: 0,
                    viewAccess: false,
                },
            });
        }
    });

    return resultValue;
}
