import { createSlice } from '@reduxjs/toolkit';

interface IAuth {
    isAuth: string;
    authStatus?: number | null;
    referralStatus?: number | null;
}

const initialState: { value: IAuth } = {
    value: {
        // isAuth: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6Mywic3ViIjoyMjkxMSwidGdpZCI6ODE0MjcyNDQ5LCJleHAiOjE3MzE1MjA0NjgsImlhdCI6MTcyODkyODQ2OH0.GCkr4fjre5zwhKVO51Y3a9x4dSvHqH9II7SLrUTFIsQ',
        // isAuth: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6Mywic3ViIjo4NTQ3NSwidGdpZCI6MCwiZXhwIjoxNzMxNTIwNDY4LCJpYXQiOjE3Mjg5Mjg0Njh9.Ibox5hRdL5OTW26OBSTztZJoCPKd0NwIVjmEZAOzPts',
        // isAuth: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6Mywic3ViIjoxLCJ0Z2lkIjo4MTQyNzI0NDksImV4cCI6MTczMTUyMDQ2OCwiaWF0IjoxNzI4OTI4NDY4fQ.KCQwjCU3o0xyHvm9hmFUoxwqWfxRFUJc-95IEmpVSh4',
        // isAuth: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwaWQiOiJUVE1fSE9MRElORyIsImlzcyI6MTAwLCJzdWIiOjg1NDc1LCJ0Z2lkIjo4MTQyNzI0NDksImV4cCI6MTczNDE3MjM1MiwiaWF0IjoxNzMxNTgwMzUyfQ.05uCGdGMSx-rfAyhe8fk6fzF6lGwzpMccdNEefIsFm0',
        isAuth: '',
        authStatus: null,
        referralStatus: null,
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuth: (state, { payload }: { payload: IAuth }) => {
            payload.authStatus && payload.referralStatus ? (state.value = payload) : (state.value.isAuth = payload.isAuth);
        },
    },
});

export const { setAuth } = authSlice.actions;

export default authSlice.reducer;
