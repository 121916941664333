// import * as ru from '@static/locales/ru/translation.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// const lng = navigator ? navigator.language.substring(0, 2) : 'ru';

i18n.use(initReactI18next)
    .use(Backend)
    .use(LanguageDetector)
    .init({
        // debug: true,
        fallbackLng: window.navigator.language.slice(0, 2),
        compatibilityJSON: 'v3',
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;
