const AUTH_STATUS = {
    0: 'AUTH_GUEST',
    1: 'AUTH_SIGN_IN',
    2: 'AUTH_SIGN_UP',
};
const REFERRAL_STATUS = {
    0: 'REFERRAL_UNKNOWN',
    1: 'REFERRAL_WRONG_LINK',
    2: 'REFERRAL_BUSY',
    3: 'REFERRAL_EXISTS',
};

// const INIT_DATA_MOCK = 'user=%7B%22id%22%3A6833595412%2C%22first_name%22%3A%22Denis%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ora9p%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%7D&chat_instance=1081555871651414207&chat_type=sender&start_param=r-cL&auth_date=1727791594&hash=2084cc4a2241a7d62d3a12b31912f99e3af9a2c4815669146966d9c663db8284';
const INIT_DATA_MOCK =
    'user=%7B%22id%22%3A6833595412%2C%22first_name%22%3A%22Denis%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22ora9p%22%2C%22language_code%22%3A%22ru%22%2C%22allows_write_to_pm%22%3Atrue%2C%22photo_url%22%3A%22https%3A%5C%2F%5C%2Ft.me%5C%2Fi%5C%2Fuserpic%5C%2F320%5C%2FumNZEv1KYISBFZLjmIUw3f2BDmVfBKfr2IL1LQR0rA7fO8flOPA8-Un4kalKYTiw.svg%22%7D&chat_instance=1486195195398509075&chat_type=private&auth_date=1731522207&hash=37f693169475b934b5011d381abd37efb97663ac0cee680c06b9e0a3fe1382dd';
const API_URL = 'https://ttmpartners.net';
// const API_URL = 'https://ttm-partners-staging.biconom.com';

export { API_URL, AUTH_STATUS, INIT_DATA_MOCK, REFERRAL_STATUS };
