import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TRootState } from '../../store/store';
import Logo from '../Common/Logo/Logo';
import './LoadingApp.scss';

export interface ILoadingSetting {
    target: number;
    close: Function;
}

const LoadingApp: FC<ILoadingSetting> = ({ target, close }) => {
    const [widthProgress, setWidthProgress] = useState(0);
    const loadingAmount = useSelector((state: TRootState) => state.user.value.loadingAmount);
    const { t } = useTranslation();

    function changeWidth() {
        const result = (224 / target) * loadingAmount;
        setWidthProgress(result);
    }
    useEffect(() => {
        if ((widthProgress * 100) / 224 < 100) {
            changeWidth();
        }
    }, [loadingAmount]);

    useEffect(() => {
        if ((widthProgress * 100) / 224 >= 100) {
            setTimeout(() => {
                close();
            }, 310);
        }
    }, [widthProgress]);

    return (
        <div className="loading-app">
            <div className="loading-app__head">
                <Logo
                    width="245px"
                    height="50px"
                    fontSize="20px"
                    colorLogo="#fff"
                    colorTitle="var(--t)"
                />
                <div className="loading-app__head--title">
                    <h2>{t('loadings.starting_app.header.title')}</h2>
                    <h1>{t('loadings.starting_app.header.text')}</h1>
                </div>
            </div>
            <div className="loading-app__progress">
                <p>{t('loadings.starting_app.progress.title')}</p>
                <div className="loading-app__progress--bar">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="224"
                        height="53"
                        viewBox="0 0 224 53"
                        fill="none">
                        <rect
                            id="main"
                            x="0"
                            y="21.0049"
                            width="224"
                            height="11"
                            rx="5.5"
                            fill="#D9D9D9"
                            stroke="white"
                        />
                        <g filter="url(#filter0_d_587_4109)">
                            <rect
                                x="0"
                                y="20.5049"
                                width={widthProgress}
                                height="12"
                                rx="6"
                                fill="url(#paint0_linear_587_4109)"
                            />
                            <rect
                                x="0"
                                y="20.5049"
                                width={widthProgress}
                                height="12"
                                rx="6"
                                fill="#0075FF"
                                fill-opacity="0.5"
                            />
                            <rect
                                x="0"
                                y="21.0049"
                                width={widthProgress}
                                height="11"
                                rx="5.5"
                                stroke="#0B8AFF"
                            />
                        </g>
                        <defs>
                            <filter
                                id="filter0_d_587_4109"
                                x="0.5"
                                y="0.504883"
                                width={widthProgress}
                                height="52"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB">
                                <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                />
                                <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                />
                                <feOffset />
                                <feGaussianBlur stdDeviation="10" />
                                <feComposite
                                    in2="hardAlpha"
                                    operator="out"
                                />
                                <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0.46 0 0 0 0 1 0 0 0 0.7 0"
                                />
                                <feBlend
                                    mode="lighten"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_587_4109"
                                />
                                <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_587_4109"
                                    result="shape"
                                />
                            </filter>
                            <linearGradient
                                id="paint0_linear_587_4109"
                                x1="62.4839"
                                y1="20.5049"
                                x2="124.892"
                                y2="58.2866"
                                gradientUnits="userSpaceOnUse">
                                <stop stop-color="#0462F9" />
                                <stop
                                    offset="1"
                                    stop-color="#101598"
                                />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default LoadingApp;
