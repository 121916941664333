import { createSlice } from '@reduxjs/toolkit';
import { Scheme } from '../../generated/mlmbox/types/scheme_pb';

const initialState: { value: Scheme.AsObject } = {
    value: {
        assetsList: [],
        localesList: [],
        treeGroupsList: [],
        treesList: [],
        walletAssetsList: [],
        walletProcessingsList: [],
        assetPairExchangeRatesList: [],
    },
};

const schemesSlice = createSlice({
    name: 'schemes',
    initialState,
    reducers: {
        setSchemes: (state, action: { payload: Scheme.AsObject }) => {
            state.value = action.payload;
        },
    },
});

export const { setSchemes } = schemesSlice.actions;

export default schemesSlice.reducer;
