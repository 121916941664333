import { message } from 'antd';

import { de, enGB, ka, ko, pt, ru, uk, zhCN } from 'date-fns/locale';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { tgIcon } from '../../assets';
import * as mlmbox_client_network_network_pb from '../../generated/mlmbox/client/network/network_pb';
import { TeamResponse } from '../../generated/mlmbox/client/network/network_pb';
import { NetworkClient } from '../../generated/mlmbox/client/network/NetworkServiceClientPb';
import * as mlmbox_types_account_pb from '../../generated/mlmbox/types/account_pb';
import * as mlmbox_types_sort_pb from '../../generated/mlmbox/types/sort_pb';
import { API_URL } from '../../utils/constants';
import { copyToClipboard, getColor, getDate, getToken, setAvas } from '../../utils/helper';
import Ava from '../Common/Ava/Ava';
import Button from '../Common/Button/Button';
import { filledTreeAccountsList } from '../Common/MatrixPicture/helper';
import MatrixPicture from '../Common/MatrixPicture/MatrixPicture';
import ShortProfile from '../Common/ShortProfile/ShortProfile';
import UserBanner from '../Common/UserBanner/UserBanner';
import Matrix from '../Matrix/Matrix';
import Modal from '../Modal/Modal';
import Title from '../Title/Title';
import './UserProfile.scss';

export const locales = {
    ru: ru,
    en: enGB,
    uk: uk,
    zh: zhCN,
    pt: pt,
    ko: ko,
    ka: ka,
    de: de,
};

export type TMatrix = {
    position: number;
    treeId: number;
};

const UserProfile: FC<TeamResponse.AsObject> = ({ accountTreeAccountsList, partnersList, referralsList, account }) => {
    const [openProfile, setOpenProfile] = useState<mlmbox_client_network_network_pb.TeamResponse.AsObject | null>(null);
    const [openProfileShort, setOpenProfileShort] = useState<mlmbox_types_account_pb.Account.Info.AsObject | null>(null);
    const [teamList, setTeamList] = useState<mlmbox_types_account_pb.Account.Info.AsObject[]>([]);
    const [fetching, setFetching] = useState(true);
    const [sponsor, setSponsor] = useState<mlmbox_types_account_pb.Account.Info.AsObject>();
    const [fetchingP, setFetchingP] = useState(false);
    const [fetchingS, setFetchingS] = useState(true);
    const [openMatrix, setOpenMatrix] = useState<TMatrix | null>(null);
    const { t, i18n } = useTranslation();

    function getAccount(id: number) {
        const accClient = new NetworkClient(API_URL, null, null);
        const req = new mlmbox_client_network_network_pb.TeamRequest();
        const sort = new mlmbox_types_sort_pb.Sort();
        sort.setLimit(10);
        sort.setDirection(mlmbox_types_sort_pb.Sort.Direction.BACKWARD);
        req.setFilterTreeIdsBitMask(0);
        const ids = new mlmbox_types_account_pb.Account.Id();
        ids.setAccountId(id);
        req.setViewPartner(ids);
        req.setPartnersSort(sort);

        accClient.teamInfo(req, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                const info = res.toObject();

                if (info) {
                    await setAvas(info.partnersList);
                    await setAvas(info.referralsList);
                }
                setOpenProfile(res.toObject());
            }
        });
    }

    function getSponsor(id: number) {
        const accClient = new NetworkClient(API_URL, null, null);
        const req = new mlmbox_types_account_pb.Account.Id();
        req.setAccountId(id);

        accClient.memberGet(req, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                const info = res.toObject();
                if (info) {
                    await setAvas([info]);
                }
                setOpenProfileShort(res.toObject());
            }
        });
    }

    useEffect(() => {
        if (!referralsList.length && account?.referral) {
            const accClient = new NetworkClient(API_URL, null, null);
            const req = new mlmbox_types_account_pb.Account.Id();
            req.setAccountId(account?.referral?.id);

            accClient.memberGet(req, { authorization: getToken() }, async (err, res) => {
                if (!err) {
                    const info = res.toObject();
                    if (info) {
                        await setAvas([info]);
                    }
                    setSponsor(res.toObject());
                    setFetchingS(false);
                }
                setFetchingS(false);
            });
        } else {
            setFetchingS(false);
        }
    }, []);

    function getName(): string {
        if (!account) return '';
        if (account.channelsList.includes(1)) {
            if (account.profile?.firstName.length === 0 && account.profile?.lastName.length === 0) return t('unknown_duck');
            return `${account.profile?.firstName} ${' '} ${account.profile?.lastName}`;
        }

        if (account.profile?.telegram?.username) return `${account.profile.telegram.username}`;

        if (account.channelsList.includes(2) || account.channelsList.includes(3)) return t('unknown_duck');

        return '';
    }

    function loadPartners() {
        if (!account?.account) return;
        if (account.statistics?.partnersQuantity && teamList.length >= account.statistics?.partnersQuantity) return;
        const teamClient = new NetworkClient(API_URL, null, null);
        const teamRequest = new mlmbox_client_network_network_pb.TeamRequest();
        const request = new mlmbox_client_network_network_pb.TeamPartnerListRequest();

        const request_view_oartners = new mlmbox_types_account_pb.Account.Id();
        request_view_oartners.setAccountId(account?.account?.id);

        // set sort for partners
        const request_sort_partners = new mlmbox_types_sort_pb.Sort();
        request_sort_partners.setDirection(mlmbox_types_sort_pb.Sort.Direction.FORWARD); // FORWARD, BACKWARD
        request_sort_partners.setLimit(50);

        // set request
        teamRequest.setViewPartner(request_view_oartners);
        teamRequest.setPartnersSort(request_sort_partners);
        teamRequest.setFilterTreeIdsBitMask(0);

        request.setRequest(teamRequest);

        request.setStepReferralBranch(teamList.length);

        teamClient.teamPartnerList(request, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                await setAvas(res.toObject().itemsList);
                setTeamList((prev) => [...prev, ...res.toObject().itemsList]);
                setFetching(false);
                setFetchingP(false);
            }
        });
    }

    useEffect(() => {
        loadPartners();
    }, []);

    function onScrollList(event: React.UIEvent<HTMLElement>) {
        const scrollBottom = event.currentTarget.scrollTop + event.currentTarget.offsetHeight >= event.currentTarget.scrollHeight - 500;

        if (scrollBottom && !fetchingP) {
            setFetchingP(true);
            loadPartners();
        }
    }

    return (
        <>
            {!openProfile && (
                <div
                    className="account-profile children"
                    onScroll={(event) => account?.statistics && account.statistics.partnersQuantity > teamList.length && onScrollList(event)}>
                    <h2>{t('modals.profile.header.title')}</h2>
                    <div className="account-profile__info conteiner">
                        <div className="account-profile__info--data">
                            <Ava
                                matrix={true}
                                width="80px"
                                borderColor={getColor(account?.relationship?.kind, account?.relationship?.level)}
                                height="80px"
                                collectionId={account?.profile?.ava?.collectionId}
                                entityId={account?.profile?.ava?.entityId}
                            />
                            <h1>{getName()}</h1>
                            {account?.account?.login && (
                                <p
                                    onClick={async () => {
                                        if (!account?.account?.login) return;

                                        await copyToClipboard(account?.account?.login);
                                        message.success(t('copy'));
                                    }}>
                                    TTM Login:{' '}
                                    <span>
                                        @{account?.account?.login}{' '}
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none">
                                            <g opacity="0.8">
                                                <path
                                                    d="M12.4058 4.12821L8.43555 0.157949C8.33466 0.0569387 8.1978 0.000125678 8.05503 0H6.2817C5.75807 0 5.25588 0.208012 4.88562 0.578276C4.51535 0.948541 4.30734 1.45073 4.30734 1.97436V2.87179H3.40991C2.88627 2.87179 2.38409 3.07981 2.01382 3.45007C1.64356 3.82034 1.43555 4.32252 1.43555 4.84615V12.0256C1.43555 12.5493 1.64356 13.0515 2.01382 13.4217C2.38409 13.792 2.88627 14 3.40991 14H8.43555C8.95918 14 9.46137 13.792 9.83163 13.4217C10.2019 13.0515 10.4099 12.5493 10.4099 12.0256V11.1282H10.5894C11.113 11.1282 11.6152 10.9202 11.9855 10.5499C12.3557 10.1797 12.5638 9.67748 12.5638 9.15385V4.48718C12.5581 4.35192 12.5017 4.22375 12.4058 4.12821ZM8.61503 1.83795L10.7258 3.94872H8.61503V1.83795ZM9.33298 12.0256C9.33298 12.2637 9.23843 12.4919 9.07013 12.6602C8.90183 12.8285 8.67356 12.9231 8.43555 12.9231H3.40991C3.17189 12.9231 2.94362 12.8285 2.77532 12.6602C2.60702 12.4919 2.51247 12.2637 2.51247 12.0256V4.84615C2.51247 4.60814 2.60702 4.37987 2.77532 4.21157C2.94362 4.04327 3.17189 3.94872 3.40991 3.94872H4.30734V9.15385C4.30734 9.67748 4.51535 10.1797 4.88562 10.5499C5.25588 10.9202 5.75807 11.1282 6.2817 11.1282H9.33298V12.0256ZM10.5894 10.0513H6.2817C6.04369 10.0513 5.81542 9.95673 5.64712 9.78843C5.47882 9.62013 5.38426 9.39186 5.38426 9.15385V1.97436C5.38426 1.73634 5.47882 1.50808 5.64712 1.33978C5.81542 1.17147 6.04369 1.07692 6.2817 1.07692H7.53811V4.48718C7.53997 4.62941 7.5973 4.76529 7.69788 4.86587C7.79846 4.96645 7.93434 5.02378 8.07657 5.02564H11.4868V9.15385C11.4868 9.39186 11.3923 9.62013 11.224 9.78843C11.0557 9.95673 10.8274 10.0513 10.5894 10.0513Z"
                                                    fill="#89B1FF"
                                                />
                                            </g>
                                        </svg>
                                    </span>
                                </p>
                            )}
                        </div>
                        <div className="account-profile__info--links">
                            {account?.profile?.telegram?.username && (
                                <Button
                                    title={t('btns.send_to_tg')}
                                    background="var(--gr-b)"
                                    color="#fff"
                                    icon={tgIcon}
                                    onCick={() => window.Telegram.WebApp.openTelegramLink(`https://t.me/${account?.profile?.telegram?.username}`)}
                                />
                            )}
                            <span
                                onClick={async () => {
                                    message.success(t('team.copy'));
                                    await copyToClipboard(`https://t.me/TTM_partners_bot/TTM?startapp=r-${account?.account?.refLinkId}`);
                                }}>
                                {t('modals.profile.link_title')}{' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none">
                                    <path
                                        d="M12.4062 4.12821L8.43591 0.157949C8.33503 0.0569387 8.19816 0.000125678 8.0554 0H6.28207C5.75843 0 5.25625 0.208012 4.88598 0.578276C4.51572 0.948541 4.30771 1.45073 4.30771 1.97436V2.87179H3.41027C2.88664 2.87179 2.38445 3.07981 2.01419 3.45007C1.64393 3.82034 1.43591 4.32252 1.43591 4.84615V12.0256C1.43591 12.5493 1.64393 13.0515 2.01419 13.4217C2.38445 13.792 2.88664 14 3.41027 14H8.43591C8.95955 14 9.46173 13.792 9.832 13.4217C10.2023 13.0515 10.4103 12.5493 10.4103 12.0256V11.1282H10.5898C11.1134 11.1282 11.6156 10.9202 11.9858 10.5499C12.3561 10.1797 12.5641 9.67748 12.5641 9.15385V4.48718C12.5585 4.35192 12.5021 4.22375 12.4062 4.12821ZM8.6154 1.83795L10.7262 3.94872H8.6154V1.83795ZM9.33335 12.0256C9.33335 12.2637 9.2388 12.4919 9.0705 12.6602C8.90219 12.8285 8.67393 12.9231 8.43591 12.9231H3.41027C3.17226 12.9231 2.94399 12.8285 2.77569 12.6602C2.60739 12.4919 2.51284 12.2637 2.51284 12.0256V4.84615C2.51284 4.60814 2.60739 4.37987 2.77569 4.21157C2.94399 4.04327 3.17226 3.94872 3.41027 3.94872H4.30771V9.15385C4.30771 9.67748 4.51572 10.1797 4.88598 10.5499C5.25625 10.9202 5.75843 11.1282 6.28207 11.1282H9.33335V12.0256ZM10.5898 10.0513H6.28207C6.04405 10.0513 5.81579 9.95673 5.64748 9.78843C5.47918 9.62013 5.38463 9.39186 5.38463 9.15385V1.97436C5.38463 1.73634 5.47918 1.50808 5.64748 1.33978C5.81579 1.17147 6.04405 1.07692 6.28207 1.07692H7.53848V4.48718C7.54034 4.62941 7.59766 4.76529 7.69825 4.86587C7.79883 4.96645 7.93471 5.02378 8.07694 5.02564H11.4872V9.15385C11.4872 9.39186 11.3926 9.62013 11.2243 9.78843C11.056 9.95673 10.8278 10.0513 10.5898 10.0513Z"
                                        fill="var(--accent)"
                                    />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div className="about-meta">
                        <p>
                            {t('reg')} {account?.registrationAt && getDate(account?.registrationAt)}
                        </p>
                        <p
                            onClick={async () => {
                                await copyToClipboard(String(account?.account?.id));
                                message.success(t('copy'));
                            }}>
                            Account ID: <span>{account?.account?.id}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none">
                                <g opacity="0.8">
                                    <path
                                        d="M12.4063 4.12821L8.43604 0.157949C8.33515 0.0569387 8.19828 0.000125678 8.05552 0H6.28219C5.75856 0 5.25637 0.208012 4.88611 0.578276C4.51584 0.948541 4.30783 1.45073 4.30783 1.97436V2.87179H3.41039C2.88676 2.87179 2.38458 3.07981 2.01431 3.45007C1.64405 3.82034 1.43604 4.32252 1.43604 4.84615V12.0256C1.43604 12.5493 1.64405 13.0515 2.01431 13.4217C2.38458 13.792 2.88676 14 3.41039 14H8.43604C8.95967 14 9.46185 13.792 9.83212 13.4217C10.2024 13.0515 10.4104 12.5493 10.4104 12.0256V11.1282H10.5899C11.1135 11.1282 11.6157 10.9202 11.986 10.5499C12.3562 10.1797 12.5642 9.67748 12.5642 9.15385V4.48718C12.5586 4.35192 12.5022 4.22375 12.4063 4.12821ZM8.61552 1.83795L10.7263 3.94872H8.61552V1.83795ZM9.33347 12.0256C9.33347 12.2637 9.23892 12.4919 9.07062 12.6602C8.90232 12.8285 8.67405 12.9231 8.43604 12.9231H3.41039C3.17238 12.9231 2.94411 12.8285 2.77581 12.6602C2.60751 12.4919 2.51296 12.2637 2.51296 12.0256V4.84615C2.51296 4.60814 2.60751 4.37987 2.77581 4.21157C2.94411 4.04327 3.17238 3.94872 3.41039 3.94872H4.30783V9.15385C4.30783 9.67748 4.51584 10.1797 4.88611 10.5499C5.25637 10.9202 5.75856 11.1282 6.28219 11.1282H9.33347V12.0256ZM10.5899 10.0513H6.28219C6.04417 10.0513 5.81591 9.95673 5.64761 9.78843C5.4793 9.62013 5.38475 9.39186 5.38475 9.15385V1.97436C5.38475 1.73634 5.4793 1.50808 5.64761 1.33978C5.81591 1.17147 6.04417 1.07692 6.28219 1.07692H7.5386V4.48718C7.54046 4.62941 7.59779 4.76529 7.69837 4.86587C7.79895 4.96645 7.93483 5.02378 8.07706 5.02564H11.4873V9.15385C11.4873 9.39186 11.3928 9.62013 11.2245 9.78843C11.0562 9.95673 10.8279 10.0513 10.5899 10.0513Z"
                                        fill="#89B1FF"
                                    />
                                </g>
                            </svg>
                        </p>
                    </div>

                    {account?.account?.id !== 1 && referralsList.length > 0 && (
                        <>
                            <div className="line"></div>

                            <Title text={t('modals.profile.ref_title')} />

                            <div className="partners">
                                {referralsList.map((el, i) => (
                                    <UserBanner
                                        key={el.account?.id + 'rf'}
                                        {...el}
                                        handleClick={() => el.account?.id && getAccount(el.account?.id)}
                                    />
                                ))}
                            </div>
                        </>
                    )}

                    {referralsList.length === 0 && sponsor && !fetchingS && (
                        <>
                            <div className="line"></div>

                            <Title text={t('modals.profile.ref_title')} />

                            <div className="partners">
                                <UserBanner
                                    {...sponsor}
                                    handleClick={() => sponsor.account?.id && getSponsor(sponsor.account?.id)}
                                />
                            </div>
                        </>
                    )}

                    {referralsList.length === 0 && !sponsor && !fetchingS && (
                        <>
                            <div className="line"></div>

                            <Title text={t('modals.profile.ref_title')} />

                            <div className="block">
                                <p className="not">{t('not_sponsors')}</p>
                            </div>
                        </>
                    )}

                    {fetchingS && (
                        <>
                            <div className="line"></div>

                            <Title text={t('modals.profile.ref_title')} />

                            <SkeletonTheme
                                width={328}
                                height={72}
                                borderRadius={'16px'}
                                baseColor="rgba(56, 56, 57, 0.3)"
                                highlightColor="rgba(56, 56, 57, 0.5)">
                                <Skeleton count={1} />
                            </SkeletonTheme>
                        </>
                    )}

                    <>
                        <div className="line"></div>
                        <Title text={t('modals.profile.matrix_title')} />{' '}
                        <div className="places">
                            {filledTreeAccountsList(accountTreeAccountsList).map((el, index) => (
                                <MatrixPicture
                                    setOpenMatrix={setOpenMatrix}
                                    index={index}
                                    {...el}
                                    key={el.entity?.positionId}
                                />
                            ))}
                        </div>
                        {/* {accountTreeAccountsList.length === 0 && (
                            <>
                                <div className="line"></div>

                                <Title text={t('modals.profile.matrix_title')} />

                                <div className="block">
                                    <p className="not">{t('not_matrix')}</p>
                                </div>
                            </>
                        )} */}
                    </>

                    <>
                        {teamList.length > 0 && (
                            <>
                                <div className="line"></div>
                                <Title text={t('modals.profile.team_title')} />
                                <div className="partners">
                                    {teamList.map((el, i) => (
                                        <UserBanner
                                            key={el.account?.id + 'pr'}
                                            {...el}
                                            handleClick={() => el.account?.id && getAccount(el.account?.id)}
                                        />
                                    ))}
                                </div>
                                {fetchingP && (
                                    <>
                                        <div className="load">
                                            <div className="spinner small"></div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                        {fetching && (
                            <>
                                <div className="line"></div>
                                <Title text={t('modals.profile.team_title')} />
                                <SkeletonTheme
                                    width={328}
                                    height={72}
                                    borderRadius={'16px'}
                                    baseColor="rgba(56, 56, 57, 0.3)"
                                    highlightColor="rgba(56, 56, 57, 0.5)">
                                    <Skeleton count={1} />
                                </SkeletonTheme>
                            </>
                        )}
                        {teamList.length === 0 && !fetching && (
                            <>
                                <div className="line"></div>
                                <Title text={t('modals.profile.team_title')} />

                                <div className="block">
                                    <p className="not">{t('not_team')}</p>
                                </div>
                            </>
                        )}
                    </>
                </div>
            )}

            {openProfile && (
                <Modal
                    closeBtn={() => setOpenProfile(null)}
                    classModal="profile-modal">
                    <UserProfile {...openProfile} />
                </Modal>
            )}
            {openProfileShort && (
                <Modal
                    closeBtn={() => setOpenProfileShort(null)}
                    classModal="profile-modal">
                    <ShortProfile
                        {...openProfileShort}
                        view={true}
                    />
                </Modal>
            )}
            {/* {openMatrix && (
                <div className="modal-matrix">
                    <div
                        className="modal-matrix__backdrop"
                        onClick={() => setOpenMatrix(null)}></div>
                    <div className="modal-matrix__content">
                        <div className="modal-matrix__content--close">
                            <svg
                                onClick={() => {
                                    setOpenMatrix(null);
                                }}
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none">
                                <path
                                    d="M1.17282 12C0.514893 12 0 11.4702 0 10.8115C0 10.4964 0.114414 10.1957 0.343257 9.98084L4.3051 5.99999L0.343257 2.0334C0.114414 1.8043 0 1.51789 0 1.20286C0 0.529821 0.514893 0.0286375 1.17282 0.0286375C1.50178 0.0286375 1.75923 0.143189 1.98807 0.357995L5.97852 4.3389L9.99761 0.343668C10.2407 0.100239 10.4982 0 10.8128 0C11.4707 0 12 0.515509 12 1.17422C12 1.50358 11.8998 1.76133 11.6423 2.01909L7.66626 5.99999L11.6281 9.96661C11.8713 10.1814 11.9856 10.482 11.9856 10.8115C11.9856 11.4702 11.4565 12 10.7842 12C10.4553 12 10.1549 11.8855 9.94034 11.6563L5.97852 7.67544L2.03097 11.6563C1.80214 11.8855 1.50178 12 1.17282 12Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <Matrix
                            treeId={openMatrix.treeId}
                            positionIdView={openMatrix.position}
                        />
                    </div>
                </div>
            )} */}
            {openMatrix && (
                <Modal closeBtn={() => setOpenMatrix(null)}>
                    <Matrix
                        treeId={openMatrix.treeId}
                        positionIdView={openMatrix.position}
                    />
                </Modal>
            )}
        </>
    );
};

export default UserProfile;
