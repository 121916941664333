import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TRootState } from '../../../store/store';
import './Ava.scss';

export interface IAva {
    link?: string;
    width: string;
    borderColor?: string;
    collectionId?: number;
    entityId?: number;
    height?: string;
    click?: Function;
    matrix?: boolean;
}

const Ava: FC<IAva> = ({ link, width, borderColor, click, collectionId, entityId, height, matrix }) => {
    const [base64, setBase64] = useState<string | undefined>();
    const avatars = useSelector((state: TRootState) => state.avatars.value);

    useEffect(() => {
        if (!collectionId || !entityId) return;
        // getAva(collectionId, entityId).then((img) => setBase64(img));
        const value = avatars[`${collectionId}-${entityId}`];
        if (!value) return;
        setBase64(value);
    }, [avatars[`${collectionId}-${entityId}`]]);
    const style: React.CSSProperties = {
        borderColor,
        height,
        border: borderColor ? `2px solid ${borderColor}` : 'none',
        '--width': width,
        '--height': height,
    } as React.CSSProperties;
    return (
        <div
            onClick={() => click && click()}
            className={`ava ${matrix ? 'matrix' : 'no-matrix'}`}
            style={style}>
            {base64 && (
                <img
                    src={`data:image/jpeg;base64,${base64}`}
                    alt=""
                />
            )}
        </div>
    );
};

export default Ava;
