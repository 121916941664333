// source: mlmbox/client/network/network.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mlmbox_types_account_pb = require('../../../mlmbox/types/account_pb.js');
goog.object.extend(proto, mlmbox_types_account_pb);
var mlmbox_types_sort_pb = require('../../../mlmbox/types/sort_pb.js');
goog.object.extend(proto, mlmbox_types_sort_pb);
goog.exportSymbol('proto.mlmbox.client.network.AccountsSearchRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.TeamPartnerListRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.TeamRequest', null, global);
goog.exportSymbol('proto.mlmbox.client.network.TeamResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.AccountsSearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.network.AccountsSearchRequest.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.network.AccountsSearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.AccountsSearchRequest.displayName = 'proto.mlmbox.client.network.AccountsSearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.TeamRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.network.TeamRequest.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.network.TeamRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.TeamRequest.displayName = 'proto.mlmbox.client.network.TeamRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.TeamResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.client.network.TeamResponse.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.client.network.TeamResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.TeamResponse.displayName = 'proto.mlmbox.client.network.TeamResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.client.network.TeamPartnerListRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.client.network.TeamPartnerListRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.client.network.TeamPartnerListRequest.displayName = 'proto.mlmbox.client.network.TeamPartnerListRequest';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.network.AccountsSearchRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.AccountsSearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.AccountsSearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.AccountsSearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginPrefix: jspb.Message.getFieldWithDefault(msg, 1, ""),
    limit: jspb.Message.getFieldWithDefault(msg, 2, 0),
    filterChannelsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest}
 */
proto.mlmbox.client.network.AccountsSearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.AccountsSearchRequest;
  return proto.mlmbox.client.network.AccountsSearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.AccountsSearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest}
 */
proto.mlmbox.client.network.AccountsSearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginPrefix(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLimit(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterChannels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.AccountsSearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.AccountsSearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.AccountsSearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginPrefix();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLimit();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFilterChannelsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
};


/**
 * optional string login_prefix = 1;
 * @return {string}
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.getLoginPrefix = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.setLoginPrefix = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 limit = 2;
 * @return {number}
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated uint32 filter_channels = 3;
 * @return {!Array<number>}
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.getFilterChannelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.setFilterChannelsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.addFilterChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.AccountsSearchRequest} returns this
 */
proto.mlmbox.client.network.AccountsSearchRequest.prototype.clearFilterChannelsList = function() {
  return this.setFilterChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.network.TeamRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.TeamRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.TeamRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.TeamRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    viewPartner: (f = msg.getViewPartner()) && mlmbox_types_account_pb.Account.Id.toObject(includeInstance, f),
    partnersSort: (f = msg.getPartnersSort()) && mlmbox_types_sort_pb.Sort.toObject(includeInstance, f),
    filterTreeIdsBitMask: jspb.Message.getFieldWithDefault(msg, 3, 0),
    filterChannelsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.TeamRequest}
 */
proto.mlmbox.client.network.TeamRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.TeamRequest;
  return proto.mlmbox.client.network.TeamRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.TeamRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.TeamRequest}
 */
proto.mlmbox.client.network.TeamRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_types_account_pb.Account.Id;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Id.deserializeBinaryFromReader);
      msg.setViewPartner(value);
      break;
    case 2:
      var value = new mlmbox_types_sort_pb.Sort;
      reader.readMessage(value,mlmbox_types_sort_pb.Sort.deserializeBinaryFromReader);
      msg.setPartnersSort(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setFilterTreeIdsBitMask(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFilterChannels(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.TeamRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.TeamRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.TeamRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getViewPartner();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_types_account_pb.Account.Id.serializeBinaryToWriter
    );
  }
  f = message.getPartnersSort();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      mlmbox_types_sort_pb.Sort.serializeBinaryToWriter
    );
  }
  f = message.getFilterTreeIdsBitMask();
  if (f !== 0) {
    writer.writeInt32(
      3,
      f
    );
  }
  f = message.getFilterChannelsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
};


/**
 * optional mlmbox.types.Account.Id view_partner = 1;
 * @return {?proto.mlmbox.types.Account.Id}
 */
proto.mlmbox.client.network.TeamRequest.prototype.getViewPartner = function() {
  return /** @type{?proto.mlmbox.types.Account.Id} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Id, 1));
};


/**
 * @param {?proto.mlmbox.types.Account.Id|undefined} value
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
*/
proto.mlmbox.client.network.TeamRequest.prototype.setViewPartner = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.clearViewPartner = function() {
  return this.setViewPartner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.TeamRequest.prototype.hasViewPartner = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional mlmbox.types.Sort partners_sort = 2;
 * @return {?proto.mlmbox.types.Sort}
 */
proto.mlmbox.client.network.TeamRequest.prototype.getPartnersSort = function() {
  return /** @type{?proto.mlmbox.types.Sort} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_sort_pb.Sort, 2));
};


/**
 * @param {?proto.mlmbox.types.Sort|undefined} value
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
*/
proto.mlmbox.client.network.TeamRequest.prototype.setPartnersSort = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.clearPartnersSort = function() {
  return this.setPartnersSort(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.TeamRequest.prototype.hasPartnersSort = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional int32 filter_tree_ids_bit_mask = 3;
 * @return {number}
 */
proto.mlmbox.client.network.TeamRequest.prototype.getFilterTreeIdsBitMask = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.setFilterTreeIdsBitMask = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated uint32 filter_channels = 4;
 * @return {!Array<number>}
 */
proto.mlmbox.client.network.TeamRequest.prototype.getFilterChannelsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.setFilterChannelsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.addFilterChannels = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.TeamRequest} returns this
 */
proto.mlmbox.client.network.TeamRequest.prototype.clearFilterChannelsList = function() {
  return this.setFilterChannelsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.client.network.TeamResponse.repeatedFields_ = [2,3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.TeamResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.TeamResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.TeamResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    account: (f = msg.getAccount()) && mlmbox_types_account_pb.Account.Info.toObject(includeInstance, f),
    partnersList: jspb.Message.toObjectList(msg.getPartnersList(),
    mlmbox_types_account_pb.Account.Info.toObject, includeInstance),
    referralsList: jspb.Message.toObjectList(msg.getReferralsList(),
    mlmbox_types_account_pb.Account.Info.toObject, includeInstance),
    accountTreeAccountsList: jspb.Message.toObjectList(msg.getAccountTreeAccountsList(),
    mlmbox_types_account_pb.TreeAccount.Set.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.TeamResponse}
 */
proto.mlmbox.client.network.TeamResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.TeamResponse;
  return proto.mlmbox.client.network.TeamResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.TeamResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.TeamResponse}
 */
proto.mlmbox.client.network.TeamResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 2:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.addPartners(value);
      break;
    case 3:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.addReferrals(value);
      break;
    case 4:
      var value = new mlmbox_types_account_pb.TreeAccount.Set;
      reader.readMessage(value,mlmbox_types_account_pb.TreeAccount.Set.deserializeBinaryFromReader);
      msg.addAccountTreeAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.TeamResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.TeamResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.TeamResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getPartnersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getReferralsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = message.getAccountTreeAccountsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      mlmbox_types_account_pb.TreeAccount.Set.serializeBinaryToWriter
    );
  }
};


/**
 * optional mlmbox.types.Account.Info account = 1;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.network.TeamResponse.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Info, 1));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
*/
proto.mlmbox.client.network.TeamResponse.prototype.setAccount = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
 */
proto.mlmbox.client.network.TeamResponse.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.TeamResponse.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated mlmbox.types.Account.Info partners = 2;
 * @return {!Array<!proto.mlmbox.types.Account.Info>}
 */
proto.mlmbox.client.network.TeamResponse.prototype.getPartnersList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_types_account_pb.Account.Info, 2));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Info>} value
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
*/
proto.mlmbox.client.network.TeamResponse.prototype.setPartnersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.network.TeamResponse.prototype.addPartners = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.types.Account.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
 */
proto.mlmbox.client.network.TeamResponse.prototype.clearPartnersList = function() {
  return this.setPartnersList([]);
};


/**
 * repeated mlmbox.types.Account.Info referrals = 3;
 * @return {!Array<!proto.mlmbox.types.Account.Info>}
 */
proto.mlmbox.client.network.TeamResponse.prototype.getReferralsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Account.Info>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_types_account_pb.Account.Info, 3));
};


/**
 * @param {!Array<!proto.mlmbox.types.Account.Info>} value
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
*/
proto.mlmbox.client.network.TeamResponse.prototype.setReferralsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.types.Account.Info=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.client.network.TeamResponse.prototype.addReferrals = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.types.Account.Info, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
 */
proto.mlmbox.client.network.TeamResponse.prototype.clearReferralsList = function() {
  return this.setReferralsList([]);
};


/**
 * repeated mlmbox.types.TreeAccount.Set account_tree_accounts = 4;
 * @return {!Array<!proto.mlmbox.types.TreeAccount.Set>}
 */
proto.mlmbox.client.network.TeamResponse.prototype.getAccountTreeAccountsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.TreeAccount.Set>} */ (
    jspb.Message.getRepeatedWrapperField(this, mlmbox_types_account_pb.TreeAccount.Set, 4));
};


/**
 * @param {!Array<!proto.mlmbox.types.TreeAccount.Set>} value
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
*/
proto.mlmbox.client.network.TeamResponse.prototype.setAccountTreeAccountsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.mlmbox.types.TreeAccount.Set=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.TreeAccount.Set}
 */
proto.mlmbox.client.network.TeamResponse.prototype.addAccountTreeAccounts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.mlmbox.types.TreeAccount.Set, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.client.network.TeamResponse} returns this
 */
proto.mlmbox.client.network.TeamResponse.prototype.clearAccountTreeAccountsList = function() {
  return this.setAccountTreeAccountsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.client.network.TeamPartnerListRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.client.network.TeamPartnerListRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamPartnerListRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    request: (f = msg.getRequest()) && proto.mlmbox.client.network.TeamRequest.toObject(includeInstance, f),
    stepReferralBranch: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.client.network.TeamPartnerListRequest}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.client.network.TeamPartnerListRequest;
  return proto.mlmbox.client.network.TeamPartnerListRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.client.network.TeamPartnerListRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.client.network.TeamPartnerListRequest}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.client.network.TeamRequest;
      reader.readMessage(value,proto.mlmbox.client.network.TeamRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setStepReferralBranch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.client.network.TeamPartnerListRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.client.network.TeamPartnerListRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.client.network.TeamPartnerListRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.client.network.TeamRequest.serializeBinaryToWriter
    );
  }
  f = message.getStepReferralBranch();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional TeamRequest request = 1;
 * @return {?proto.mlmbox.client.network.TeamRequest}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.getRequest = function() {
  return /** @type{?proto.mlmbox.client.network.TeamRequest} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.client.network.TeamRequest, 1));
};


/**
 * @param {?proto.mlmbox.client.network.TeamRequest|undefined} value
 * @return {!proto.mlmbox.client.network.TeamPartnerListRequest} returns this
*/
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.client.network.TeamPartnerListRequest} returns this
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint32 step_referral_branch = 2;
 * @return {number}
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.getStepReferralBranch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.client.network.TeamPartnerListRequest} returns this
 */
proto.mlmbox.client.network.TeamPartnerListRequest.prototype.setStepReferralBranch = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


goog.object.extend(exports, proto.mlmbox.client.network);
