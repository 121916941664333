import React from 'react';
import './Title.scss';

const Title = ({ text }: { text: string }) => {
  return (
    <div className="page-title">
      <h1>{text}</h1>
    </div>
  );
};

export default Title;
