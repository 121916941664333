import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const LoadMatrix = () => {
    return (
        <>
            {' '}
            <SkeletonTheme
                width={80}
                height={80}
                borderRadius={'50%'}
                baseColor="rgba(56, 56, 57, 0.3)"
                highlightColor="rgba(56, 56, 57, 0.5)">
                <Skeleton
                    className="circle-big"
                    count={1}
                />
            </SkeletonTheme>
            <div className="circle-group">
                <div className="tree-node">
                    <div className="tree-col">
                        <div className="circle-group__circle1 ">
                            {' '}
                            <SkeletonTheme
                                width={80}
                                height={80}
                                borderRadius={'50%'}
                                baseColor="rgba(56, 56, 57, 0.3)"
                                highlightColor="rgba(56, 56, 57, 0.5)">
                                <Skeleton
                                    count={1}
                                    className="circle-big"
                                />
                            </SkeletonTheme>
                        </div>
                        <div className="tree-node">
                            <div className="tree-col">
                                <div className="circle-group__circle1 ">
                                    {' '}
                                    <SkeletonTheme
                                        width={80}
                                        height={80}
                                        borderRadius={'50%'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            count={1}
                                            className="circle-big"
                                        />
                                    </SkeletonTheme>
                                </div>
                                <div className="tree-node">
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tree-col">
                                <div className="circle-group__circle1 ">
                                    {' '}
                                    <SkeletonTheme
                                        width={80}
                                        height={80}
                                        borderRadius={'50%'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            count={1}
                                            className="circle-big"
                                        />
                                    </SkeletonTheme>
                                </div>
                                <div className="tree-node">
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tree-col">
                        <div className="circle-group__circle1 ">
                            {' '}
                            <SkeletonTheme
                                width={80}
                                height={80}
                                borderRadius={'50%'}
                                baseColor="rgba(56, 56, 57, 0.3)"
                                highlightColor="rgba(56, 56, 57, 0.5)">
                                <Skeleton
                                    count={1}
                                    className="circle-big"
                                />
                            </SkeletonTheme>
                        </div>
                        <div className="tree-node">
                            <div className="tree-col">
                                <div className="circle-group__circle1 ">
                                    {' '}
                                    <SkeletonTheme
                                        width={80}
                                        height={80}
                                        borderRadius={'50%'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            count={1}
                                            className="circle-big"
                                        />
                                    </SkeletonTheme>
                                </div>
                                <div className="tree-node">
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tree-col">
                                <div className="circle-group__circle1 ">
                                    {' '}
                                    <SkeletonTheme
                                        width={80}
                                        height={80}
                                        borderRadius={'50%'}
                                        baseColor="rgba(56, 56, 57, 0.3)"
                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                        <Skeleton
                                            count={1}
                                            className="circle-big"
                                        />
                                    </SkeletonTheme>
                                </div>
                                <div className="tree-node">
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tree-col">
                                        <div className="circle-group__circle1 ">
                                            {' '}
                                            <SkeletonTheme
                                                width={80}
                                                height={80}
                                                borderRadius={'50%'}
                                                baseColor="rgba(56, 56, 57, 0.3)"
                                                highlightColor="rgba(56, 56, 57, 0.5)">
                                                <Skeleton
                                                    count={1}
                                                    className="circle-big"
                                                />
                                            </SkeletonTheme>
                                        </div>
                                        <div className="tree-node">
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                            <div className="tree-col">
                                                <div className="circle-group__circle1 ">
                                                    {' '}
                                                    <SkeletonTheme
                                                        width={60}
                                                        height={60}
                                                        borderRadius={'50%'}
                                                        baseColor="rgba(56, 56, 57, 0.3)"
                                                        highlightColor="rgba(56, 56, 57, 0.5)">
                                                        <Skeleton
                                                            count={1}
                                                            className="circle-small"
                                                        />
                                                    </SkeletonTheme>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoadMatrix;
