import { message } from 'antd';

import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { tgIcon } from '../../../assets';
import * as mlmbox_types_account_pb from '../../../generated/mlmbox/types/account_pb';
import { Relationship } from '../../../generated/mlmbox/types/relationship_pb';
import { copyToClipboard, getDate } from '../../../utils/helper';
import Ava from '../Ava/Ava';
import Button from '../Button/Button';
import './ShortProfile.scss';

interface ISHortProfile extends mlmbox_types_account_pb.Account.Info.AsObject {
    view?: boolean;
    dont?: boolean;
}

const ShortProfile: FC<ISHortProfile> = (props) => {
    const { t } = useTranslation();

    function getColor(kindId: number | undefined, level: number | undefined): string | undefined {
        if (!kindId || !level) return;
        switch (kindId) {
            case Relationship.Kind.OUTSIDER:
                return '#BABABA';
            case Relationship.Kind.PERSONAL:
                return '#FF7A00';
            case Relationship.Kind.REFERRAL:
                return '#E53A3A';
            case Relationship.Kind.TEAM:
                return level === 1 ? '#79CB5C' : '0075FF';
            case Relationship.Kind.UNKNOWN:
                return '#BABABA';
            default:
                return '#000';
        }
    }

    function getName(): string {
        if (!props) return '';
        if (props.channelsList.includes(1)) {
            if (props.profile?.firstName.length === 0 && props.profile?.lastName.length === 0) return t('unknown_duck');
            return `${props.profile?.firstName} ${' '} ${props.profile?.lastName}`;
        }

        if (props.profile?.telegram?.username) return `${props.profile.telegram.username}`;

        if (props.channelsList.includes(2) || props.channelsList.includes(3)) return t('unknown_duck');

        return '';
    }

    return (
        <>
            {props.view && <h1 style={{ fontSize: '14px', margin: '4px 0 8px 0' }}>{t('modals.profile.header.title')}</h1>}
            <div className="short-profile user-profile">
                <div className="short-profile__info">
                    <Ava
                        matrix={true}
                        width="80px"
                        height="80px"
                        collectionId={props.profile?.ava?.collectionId}
                        entityId={props.profile?.ava?.entityId}
                        borderColor={getColor(props.relationship?.kind, props.relationship?.kind)}
                    />
                    <h1>{getName()}</h1>

                    {props?.account?.login && (
                        <p
                            onClick={async () => {
                                if (!props?.account?.login) return;

                                await copyToClipboard(props?.account?.login);
                                message.success(t('copy'));
                            }}>
                            TTM Login:{' '}
                            <span>
                                @{props.account.login}{' '}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none">
                                    <g opacity="0.8">
                                        <path
                                            d="M12.4058 4.12821L8.43555 0.157949C8.33466 0.0569387 8.1978 0.000125678 8.05503 0H6.2817C5.75807 0 5.25588 0.208012 4.88562 0.578276C4.51535 0.948541 4.30734 1.45073 4.30734 1.97436V2.87179H3.40991C2.88627 2.87179 2.38409 3.07981 2.01382 3.45007C1.64356 3.82034 1.43555 4.32252 1.43555 4.84615V12.0256C1.43555 12.5493 1.64356 13.0515 2.01382 13.4217C2.38409 13.792 2.88627 14 3.40991 14H8.43555C8.95918 14 9.46137 13.792 9.83163 13.4217C10.2019 13.0515 10.4099 12.5493 10.4099 12.0256V11.1282H10.5894C11.113 11.1282 11.6152 10.9202 11.9855 10.5499C12.3557 10.1797 12.5638 9.67748 12.5638 9.15385V4.48718C12.5581 4.35192 12.5017 4.22375 12.4058 4.12821ZM8.61503 1.83795L10.7258 3.94872H8.61503V1.83795ZM9.33298 12.0256C9.33298 12.2637 9.23843 12.4919 9.07013 12.6602C8.90183 12.8285 8.67356 12.9231 8.43555 12.9231H3.40991C3.17189 12.9231 2.94362 12.8285 2.77532 12.6602C2.60702 12.4919 2.51247 12.2637 2.51247 12.0256V4.84615C2.51247 4.60814 2.60702 4.37987 2.77532 4.21157C2.94362 4.04327 3.17189 3.94872 3.40991 3.94872H4.30734V9.15385C4.30734 9.67748 4.51535 10.1797 4.88562 10.5499C5.25588 10.9202 5.75807 11.1282 6.2817 11.1282H9.33298V12.0256ZM10.5894 10.0513H6.2817C6.04369 10.0513 5.81542 9.95673 5.64712 9.78843C5.47882 9.62013 5.38426 9.39186 5.38426 9.15385V1.97436C5.38426 1.73634 5.47882 1.50808 5.64712 1.33978C5.81542 1.17147 6.04369 1.07692 6.2817 1.07692H7.53811V4.48718C7.53997 4.62941 7.5973 4.76529 7.69788 4.86587C7.79846 4.96645 7.93434 5.02378 8.07657 5.02564H11.4868V9.15385C11.4868 9.39186 11.3923 9.62013 11.224 9.78843C11.0557 9.95673 10.8274 10.0513 10.5894 10.0513Z"
                                            fill="#89B1FF"
                                        />
                                    </g>
                                </svg>
                            </span>
                        </p>
                    )}

                    {props?.profile?.telegram?.username && (
                        <Button
                            title={t('btns.send_to_tg')}
                            background="var(--gr-b)"
                            color="#fff"
                            icon={tgIcon}
                            onCick={() => window.Telegram.WebApp.openTelegramLink(`https://t.me/${props?.profile?.telegram?.username}`)}
                        />
                    )}
                </div>

                {!props.dont && (
                    <div className="about-meta">
                        <p>
                            {t('reg')} {getDate(props.registrationAt)}
                        </p>
                        <p
                            onClick={async () => {
                                await copyToClipboard(String(props.account?.id));
                                message.success(t('copy'));
                            }}>
                            Account ID: <span>{props.account?.id}</span>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none">
                                <g opacity="0.8">
                                    <path
                                        d="M12.4063 4.12821L8.43604 0.157949C8.33515 0.0569387 8.19828 0.000125678 8.05552 0H6.28219C5.75856 0 5.25637 0.208012 4.88611 0.578276C4.51584 0.948541 4.30783 1.45073 4.30783 1.97436V2.87179H3.41039C2.88676 2.87179 2.38458 3.07981 2.01431 3.45007C1.64405 3.82034 1.43604 4.32252 1.43604 4.84615V12.0256C1.43604 12.5493 1.64405 13.0515 2.01431 13.4217C2.38458 13.792 2.88676 14 3.41039 14H8.43604C8.95967 14 9.46185 13.792 9.83212 13.4217C10.2024 13.0515 10.4104 12.5493 10.4104 12.0256V11.1282H10.5899C11.1135 11.1282 11.6157 10.9202 11.986 10.5499C12.3562 10.1797 12.5642 9.67748 12.5642 9.15385V4.48718C12.5586 4.35192 12.5022 4.22375 12.4063 4.12821ZM8.61552 1.83795L10.7263 3.94872H8.61552V1.83795ZM9.33347 12.0256C9.33347 12.2637 9.23892 12.4919 9.07062 12.6602C8.90232 12.8285 8.67405 12.9231 8.43604 12.9231H3.41039C3.17238 12.9231 2.94411 12.8285 2.77581 12.6602C2.60751 12.4919 2.51296 12.2637 2.51296 12.0256V4.84615C2.51296 4.60814 2.60751 4.37987 2.77581 4.21157C2.94411 4.04327 3.17238 3.94872 3.41039 3.94872H4.30783V9.15385C4.30783 9.67748 4.51584 10.1797 4.88611 10.5499C5.25637 10.9202 5.75856 11.1282 6.28219 11.1282H9.33347V12.0256ZM10.5899 10.0513H6.28219C6.04417 10.0513 5.81591 9.95673 5.64761 9.78843C5.4793 9.62013 5.38475 9.39186 5.38475 9.15385V1.97436C5.38475 1.73634 5.4793 1.50808 5.64761 1.33978C5.81591 1.17147 6.04417 1.07692 6.28219 1.07692H7.5386V4.48718C7.54046 4.62941 7.59779 4.76529 7.69837 4.86587C7.79895 4.96645 7.93483 5.02378 8.07706 5.02564H11.4873V9.15385C11.4873 9.39186 11.3928 9.62013 11.2245 9.78843C11.0562 9.95673 10.8279 10.0513 10.5899 10.0513Z"
                                        fill="#89B1FF"
                                    />
                                </g>
                            </svg>
                        </p>
                    </div>
                )}
            </div>
        </>
    );
};

export default ShortProfile;
