import { FC } from 'react';
import './Button.scss';

export interface IButton {
    background: string;
    color?: string;
    title?: string;
    icon?: string;
    onCick: Function;
    width?: string;
}

const Button: FC<IButton> = (props) => {
    return (
        <button
            onClick={() => props.onCick()}
            className="button"
            style={{ color: props.color, background: props.background, width: props.width ? props.width : '100%' }}>
            {props.icon && <img src={props.icon} />}
            {props.title}
        </button>
    );
};

export default Button;
