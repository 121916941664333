import { QRCode } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './App.css';
import { AccountClient } from './generated/mlmbox/client/account/AccountServiceClientPb';
import { SetProfileRequest } from './generated/mlmbox/client/account/account_pb';
import { AuthClient } from './generated/mlmbox/client/auth/AuthServiceClientPb';

import { setAuth } from './store/auth/auth.slice';
import { AppDispatch, TRootState } from './store/store';
import { resetUser, setLoadingAmount } from './store/user/user.slice';
import { API_URL } from './utils/constants';
import { getToken, setAvas, setCookies } from './utils/helper';

import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';
import Cookies from 'universal-cookie';
import Logo from './components/Common/Logo/Logo';
import LoadingApp from './components/LoadingApp/LoadingApp';
import Locale from './components/Locale/Locale';
import { Empty } from './generated/google/protobuf/empty_pb';
import { AppClient } from './generated/mlmbox/client/app/AppServiceClientPb';
import { publicRoutes } from './routes';
import { setSchemes } from './store/scheme/schemes.slice';

window.Telegram.WebApp.disableVerticalSwipes();
const cookies = new Cookies();

const App: React.FC = () => {
    const dispatch = useDispatch() as AppDispatch;
    const isAuth = useSelector((store: TRootState) => store.auth.value.isAuth);
    const [finishLOading, setFinishLoading] = useState(false);
    const [expiredAt, setExpiredAt] = useState(false);
    const [secretCode, setSecretCode] = useState<string | undefined>(undefined);
    const [next, setNext] = useState(false);
    const { t } = useTranslation();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Функция для обновления ширины окна
    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    // Добавляем слушателя событий на изменение размера окна
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        // Убираем слушателя при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (!isAuth) {
            if (getToken()) {
                dispatch(setAuth({ isAuth: getToken() }));
                setNext(true);
                return;
            } else {
                setNext(true);
                return;
            }
        }
    }, []);

    useEffect(() => {
        if (!isAuth || !next) return;
        const shemes = new AppClient(API_URL, null, null);
        shemes.schemeInfo(new Empty(), { authorization: getToken() }, (err, res) => {
            if (!err) {
                dispatch(setSchemes(res.toObject()));
                dispatch(setLoadingAmount({ loadingAmount: 1 }));
            }
        });
        const account = new AccountClient(API_URL, null, null);
        const req = new SetProfileRequest();
        account.info(req, { authorization: getToken() }, async (err, res) => {
            if (!err) {
                dispatch(resetUser(res.toObject()));
                const info = res?.toObject().info;
                if (info) {
                    await setAvas([info]);
                }
                // if (res.toObject().locale) {
                //     i18n.changeLanguage(res.toObject().locale.slice(0, 2));
                // }
                dispatch(setLoadingAmount({ loadingAmount: 2 }));
            }
        });

        // const finance = new WalletClient(API_URL, null, null);
        // finance.info(new Empty(), { authorization: getToken() }, (err, res) => {
        //     if (!err) {
        //         dispatch(resetBalance(res.toObject().assetsList));
        //         dispatch(setLoadingWallet({ loading: false }));
        //         dispatch(setLoadingAmount({ loadingAmount: 3 }));
        //     }
        // });
    }, [isAuth, next]);

    useEffect(() => {
        if (isAuth) return;

        setExpiredAt(false);

        const authClient = new AuthClient(API_URL, null, null);

        if (!expiredAt) {
            authClient
                .remoteSignIn(new Empty(), {})
                .on('data', async (e) => {
                    console.log(e.toObject());

                    if (e.toObject().guestAccessToken) setCookies('__gg', e.toObject().guestAccessToken);
                    if (e.toObject().secretCode?.value) setSecretCode(e.toObject().secretCode?.value);
                    if (e.toObject().expiredAt) {
                        setExpiredAt(true);
                        setSecretCode(undefined);
                    }

                    if (e.toObject().waitingExpired) {
                        setExpiredAt(true);
                        setSecretCode(undefined);
                    }
                    if (e.toObject().canceled) {
                        setExpiredAt(true);
                        setSecretCode(undefined);
                    }

                    if (e.toObject().accountAccessToken) {
                        const jwtToken = e.toObject().accountAccessToken;

                        const decodedJWT = jwtDecode(jwtToken);
                        decodedJWT.exp &&
                            cookies.set('__tt', jwtToken, {
                                expires: new Date(decodedJWT.exp * 1000),
                                secure: true,
                                sameSite: 'none',
                            });
                        dispatch(
                            setAuth({
                                isAuth: e.toObject().accountAccessToken,
                            })
                        );
                    }
                })
                .on('error', (e) => {
                    console.log(e);
                });
        }
    }, [expiredAt, isAuth]);
    return (
        <>
            {!next ? (
                <></>
            ) : (
                <>
                    {' '}
                    {!isAuth && (
                        <div className="lcla">
                            <Locale />
                        </div>
                    )}
                    {windowWidth >= 1040 && (
                        <>
                            {!isAuth && (
                                <>
                                    <div className="sign-in">
                                        <Logo
                                            width="142px"
                                            height="31px"
                                            fontSize="13px"
                                            colorLogo="#fff"
                                            colorTitle="#fff"
                                        />
                                        <p>{t('sign_in')}</p>
                                        <div className={`value ${secretCode ? '' : 'loading'}`}>{secretCode && <QRCode value={secretCode} />}</div>
                                    </div>
                                </>
                            )}
                            {!finishLOading && isAuth && (
                                <LoadingApp
                                    target={2}
                                    close={() => setFinishLoading(true)}
                                />
                            )}
                            {isAuth && finishLOading && (
                                <div id="layout">
                                    <Routes>
                                        {publicRoutes.map((route) => (
                                            <Route
                                                key={route.path}
                                                path={route.path}
                                                Component={route.element}
                                            />
                                        ))}
                                    </Routes>
                                </div>
                            )}
                        </>
                    )}
                    {windowWidth < 1040 && (
                        <>
                            <div className="sign-in">
                                <Logo
                                    width="142px"
                                    height="31px"
                                    fontSize="13px"
                                    colorLogo="#fff"
                                    colorTitle="#fff"
                                />
                                <p className="nots">
                                    {t('s_s_t')}
                                    <br />
                                    <br />
                                    <span> {t('s_s_p')}</span>
                                </p>
                                {/* <div className={`value ${secretCode ? '' : 'loading'}`}>{secretCode && <QRCode value={secretCode} />}</div> */}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default App;
