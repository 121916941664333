import { FC } from 'react';
import './Logo.scss';

export interface ILogo {
    width: string;
    height: string;
    fontSize: string;
    colorLogo: string;
    colorTitle: string;
}

const Logo: FC<ILogo> = (props) => {
    return (
        <div
            className="logo"
            style={props}>
            <div className="logo__badge">
                <h1 style={{ fontSize: props.fontSize, color: props.colorLogo }}>TTM</h1>
            </div>
            <div className="logo__text">
                <h1 style={{ fontSize: props.fontSize, color: props.colorTitle }}>Partners</h1>
            </div>
        </div>
    );
};

export default Logo;
