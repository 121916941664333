import React from 'react';
import Areas from '../pages/Areas/Areas';

export interface IRouter {
    path: string;
    element: React.ComponentType;
}

export enum RoutName {
    HOME = '*',
}

export const publicRoutes: IRouter[] = [{ path: RoutName.HOME, element: Areas }];
