import { createSlice } from '@reduxjs/toolkit';
import { Wallet } from '../../generated/mlmbox/types/wallet_pb';

export interface IWalletState extends Wallet.AsObject {
    loading: boolean;
}

const initialState: { value: IWalletState } = {
    value: {
        loading: true,
        processingsList: [
            {
                assetsList: [
                    {
                        assetId: 1,
                        settingsDepositEnabled: false,
                        settingsWithdrawalEnabled: false,
                        settingsWithdrawalFeeBase: '0',
                        settingsWithdrawalFeePercent: '0',
                        settingsWithdrawalOperationValueMax: '',
                        settingsWithdrawalOperationValueMin: '',
                    },
                ],
                processingId: 0,
                processingName: '',
            },
        ],
        assetsList: [
            {
                assetId: 1,
                assetBalance: '0',
                transactionsList: [],
                settingsTransferEnabled: false,
                settingsTransferLimitMin: '',
            },
            {
                assetId: 2,
                assetBalance: '0',
                transactionsList: [],
                settingsTransferEnabled: false,
                settingsTransferLimitMin: '',
            },
        ],
    },
};

export const walletSlice = createSlice({
    name: 'walelt',
    initialState,
    reducers: {
        resetBalance: (state, { payload }) => {
            state.value.assetsList = payload;
        },
        resetBalanceById: (state, { payload }: { payload: { assetId: number; balance: string } }) => {
            state.value.assetsList[payload.assetId - 1].assetBalance = payload.balance;
        },
        setLoadingWallet: (state, { payload }: { payload: { loading: boolean } }) => {
            state.value.loading = payload.loading;
        },
    },
});

export const { resetBalance, resetBalanceById, setLoadingWallet } = walletSlice.actions;

export default walletSlice.reducer;
