// source: mlmbox/types/wallet.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var mlmbox_types_asset_pb = require('../../mlmbox/types/asset_pb.js');
goog.object.extend(proto, mlmbox_types_asset_pb);
var mlmbox_types_account_pb = require('../../mlmbox/types/account_pb.js');
goog.object.extend(proto, mlmbox_types_account_pb);
var mlmbox_types_bonus_pb = require('../../mlmbox/types/bonus_pb.js');
goog.object.extend(proto, mlmbox_types_bonus_pb);
var mlmbox_types_status_pb = require('../../mlmbox/types/status_pb.js');
goog.object.extend(proto, mlmbox_types_status_pb);
goog.exportSymbol('proto.mlmbox.types.Wallet', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Kind', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.List', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceCase', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.UniqueFieldCase', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.Asset', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.Asset.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.Id', null, global);
goog.exportSymbol('proto.mlmbox.types.Wallet.Processing.List', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.displayName = 'proto.mlmbox.types.Wallet';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Asset.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.displayName = 'proto.mlmbox.types.Wallet.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Id.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Kind, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Kind.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Kind';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Asset.Transaction.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.List.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.displayName = 'proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.displayName = 'proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Asset.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Asset.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Asset.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Asset.List.displayName = 'proto.mlmbox.types.Wallet.Asset.List';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Processing.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.displayName = 'proto.mlmbox.types.Wallet.Processing';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.Id.displayName = 'proto.mlmbox.types.Wallet.Processing.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.Asset = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.Asset, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.Asset.displayName = 'proto.mlmbox.types.Wallet.Processing.Asset';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.Asset.Id, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.Asset.Id.displayName = 'proto.mlmbox.types.Wallet.Processing.Asset.Id';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.displayName = 'proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.displayName = 'proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.mlmbox.types.Wallet.Processing.List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.mlmbox.types.Wallet.Processing.List.repeatedFields_, null);
};
goog.inherits(proto.mlmbox.types.Wallet.Processing.List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.mlmbox.types.Wallet.Processing.List.displayName = 'proto.mlmbox.types.Wallet.Processing.List';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.mlmbox.types.Wallet.Asset.toObject, includeInstance),
    processingsList: jspb.Message.toObjectList(msg.getProcessingsList(),
    proto.mlmbox.types.Wallet.Processing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet}
 */
proto.mlmbox.types.Wallet.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet;
  return proto.mlmbox.types.Wallet.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet}
 */
proto.mlmbox.types.Wallet.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Asset;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Wallet.Processing;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Processing.deserializeBinaryFromReader);
      msg.addProcessings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Asset.serializeBinaryToWriter
    );
  }
  f = message.getProcessingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.mlmbox.types.Wallet.Processing.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Asset.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.mlmbox.types.Wallet.Asset.Transaction.toObject, includeInstance),
    incomeBonusesStatistics: (f = msg.getIncomeBonusesStatistics()) && mlmbox_types_bonus_pb.Bonus.Summary.toObject(includeInstance, f),
    withdrawalCapacity: (f = msg.getWithdrawalCapacity()) && proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.toObject(includeInstance, f),
    settingsTransferEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    settingsTransferLimitMin: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset}
 */
proto.mlmbox.types.Wallet.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset;
  return proto.mlmbox.types.Wallet.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset}
 */
proto.mlmbox.types.Wallet.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetBalance(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 4:
      var value = new mlmbox_types_bonus_pb.Bonus.Summary;
      reader.readMessage(value,mlmbox_types_bonus_pb.Bonus.Summary.deserializeBinaryFromReader);
      msg.setIncomeBonusesStatistics(value);
      break;
    case 5:
      var value = new proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.deserializeBinaryFromReader);
      msg.setWithdrawalCapacity(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettingsTransferEnabled(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsTransferLimitMin(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getIncomeBonusesStatistics();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      mlmbox_types_bonus_pb.Bonus.Summary.serializeBinaryToWriter
    );
  }
  f = message.getWithdrawalCapacity();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.serializeBinaryToWriter
    );
  }
  f = message.getSettingsTransferEnabled();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getSettingsTransferLimitMin();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    kindId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    statusId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    amount: jspb.Message.getFieldWithDefault(msg, 4, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    balance: jspb.Message.getFieldWithDefault(msg, 6, ""),
    createdAt: jspb.Message.getFieldWithDefault(msg, 7, 0),
    metadata: (f = msg.getMetadata()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction;
  return proto.mlmbox.types.Wallet.Asset.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    case 2:
      var value = /** @type {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} */ (reader.readEnum());
      msg.setKindId(value);
      break;
    case 3:
      var value = /** @type {!proto.mlmbox.types.Status.Transaction} */ (reader.readEnum());
      msg.setStatusId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKindId();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getStatusId();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAmount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBalance();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCreatedAt();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    transactionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Id}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Id;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Id}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTransactionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Id} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 transaction_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Id} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Id.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Kind.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.toObject = function(includeInstance, msg) {
  var f, obj = {
    kindId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Kind;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Kind.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} */ (reader.readEnum());
      msg.setKindId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Kind.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKindId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id = {
  UNKNOWN: 0,
  CANCELED: 1,
  EARNED: 2,
  SPENT: 3,
  DEPOSIT: 4,
  WITHDRAWAL: 5,
  DEPOSIT_WALLET_TRANSFER: 6,
  WITHDRAWAL_WALLET_TRANSFER: 7,
  EXCHANGE: 8,
  WITHDRAWAL_FEE: 9,
  BURNED: 10,
  HOLD: 11,
  UNHOLD: 12
};

/**
 * optional Id kind_id = 1;
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.prototype.getKindId = function() {
  return /** @type {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Kind.prototype.setKindId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Wallet.Asset.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.List}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.List;
  return proto.mlmbox.types.Wallet.Asset.Transaction.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.List}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction items = 1;
 * @return {!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.List} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Wallet.Asset.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.List} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_ = [[1,2,3,4,5,6,7,8,9,10,11,12]];

/**
 * @enum {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.UniqueFieldCase = {
  UNIQUE_FIELD_NOT_SET: 0,
  DESCRIPTION: 1,
  WITHDRAWAL: 2,
  KEEPER_INITIAL_TREE_ID: 3,
  KEEPER_REINVEST: 4,
  BONUS: 5,
  ACCOUNT_ID: 6,
  POSITION_CREATED: 7,
  AMOUNT: 8,
  KEEPER_BOOKING_TREE_ID: 9,
  DEPOSIT: 10,
  ACCOUNT: 11,
  TRANSACTION_ID: 12
};

/**
 * @return {proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.UniqueFieldCase}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getUniqueFieldCase = function() {
  return /** @type {proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.UniqueFieldCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    withdrawal: (f = msg.getWithdrawal()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.toObject(includeInstance, f),
    keeperInitialTreeId: jspb.Message.getFieldWithDefault(msg, 3, 0),
    keeperReinvest: (f = msg.getKeeperReinvest()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject(includeInstance, f),
    bonus: (f = msg.getBonus()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 6, 0),
    positionCreated: (f = msg.getPositionCreated()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject(includeInstance, f),
    amount: (f = msg.getAmount()) && mlmbox_types_asset_pb.Asset.Amount.toObject(includeInstance, f),
    keeperBookingTreeId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    deposit: (f = msg.getDeposit()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.toObject(includeInstance, f),
    account: (f = msg.getAccount()) && mlmbox_types_account_pb.Account.Info.toObject(includeInstance, f),
    transactionId: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.deserializeBinaryFromReader);
      msg.setWithdrawal(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKeeperInitialTreeId(value);
      break;
    case 4:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader);
      msg.setKeeperReinvest(value);
      break;
    case 5:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.deserializeBinaryFromReader);
      msg.setBonus(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAccountId(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader);
      msg.setPositionCreated(value);
      break;
    case 8:
      var value = new mlmbox_types_asset_pb.Asset.Amount;
      reader.readMessage(value,mlmbox_types_asset_pb.Asset.Amount.deserializeBinaryFromReader);
      msg.setAmount(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setKeeperBookingTreeId(value);
      break;
    case 10:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.deserializeBinaryFromReader);
      msg.setDeposit(value);
      break;
    case 11:
      var value = new mlmbox_types_account_pb.Account.Info;
      reader.readMessage(value,mlmbox_types_account_pb.Account.Info.deserializeBinaryFromReader);
      msg.setAccount(value);
      break;
    case 12:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTransactionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getWithdrawal();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getKeeperReinvest();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter
    );
  }
  f = message.getBonus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeUint32(
      6,
      f
    );
  }
  f = message.getPositionCreated();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter
    );
  }
  f = message.getAmount();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      mlmbox_types_asset_pb.Asset.Amount.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 9));
  if (f != null) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getDeposit();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      mlmbox_types_account_pb.Account.Info.serializeBinaryToWriter
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 12));
  if (f != null) {
    writer.writeUint32(
      12,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    hash: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string hash = 2;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    address: jspb.Message.getFieldWithDefault(msg, 2, ""),
    usedVerifiedCode: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    hash: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUsedVerifiedCode(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setHash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUsedVerifiedCode();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getHash();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string address = 2;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool used_verified_code = 3;
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.getUsedVerifiedCode = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.setUsedVerifiedCode = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string hash = 4;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.getHash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal.prototype.setHash = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    treeAccountEntityId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    positionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeAccountEntityId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTreeAccountEntityId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 tree_account_entity_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.getTreeAccountEntityId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.setTreeAccountEntityId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 position_id = 3;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_ = [[2,3,4,5,6,7]];

/**
 * @enum {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceCase = {
  SOURCE_NOT_SET: 0,
  MATRIX: 2,
  CARD_PARTNER_DAO: 3,
  CARD_BONUS_HUNTER: 4,
  DESCRIPTION: 5,
  BINARY: 6,
  BINARY_MATCHING: 7
};

/**
 * @return {proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceCase}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getSourceCase = function() {
  return /** @type {proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceCase} */(jspb.Message.computeOneofCase(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.toObject = function(includeInstance, msg) {
  var f, obj = {
    bonusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    matrix: (f = msg.getMatrix()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.toObject(includeInstance, f),
    cardPartnerDao: (f = msg.getCardPartnerDao()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.toObject(includeInstance, f),
    cardBonusHunter: (f = msg.getCardBonusHunter()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    binary: (f = msg.getBinary()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.toObject(includeInstance, f),
    binaryMatching: (f = msg.getBinaryMatching()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.mlmbox.types.Bonus.Kind.Id} */ (reader.readEnum());
      msg.setBonusId(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.deserializeBinaryFromReader);
      msg.setMatrix(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.deserializeBinaryFromReader);
      msg.setCardPartnerDao(value);
      break;
    case 4:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.deserializeBinaryFromReader);
      msg.setCardBonusHunter(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.deserializeBinaryFromReader);
      msg.setBinary(value);
      break;
    case 7:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.deserializeBinaryFromReader);
      msg.setBinaryMatching(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBonusId();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getMatrix();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.serializeBinaryToWriter
    );
  }
  f = message.getCardPartnerDao();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.serializeBinaryToWriter
    );
  }
  f = message.getCardBonusHunter();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBinary();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.serializeBinaryToWriter
    );
  }
  f = message.getBinaryMatching();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.toObject = function(includeInstance, msg) {
  var f, obj = {
    target: (f = msg.getTarget()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject(includeInstance, f),
    source: (f = msg.getSource()) && proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.toObject(includeInstance, f),
    level: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader);
      msg.setTarget(value);
      break;
    case 2:
      var value = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.deserializeBinaryFromReader);
      msg.setSource(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLevel(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTarget();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter
    );
  }
  f = message.getSource();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader.serializeBinaryToWriter
    );
  }
  f = message.getLevel();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional PositionHeader target = 1;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.getTarget = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader, 1));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.setTarget = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.clearTarget = function() {
  return this.setTarget(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.hasTarget = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional PositionHeader source = 2;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.getSource = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader, 2));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.setSource = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.clearSource = function() {
  return this.setSource(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.hasSource = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 level = 3;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.getLevel = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix.prototype.setLevel = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    positionId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPositionId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 position_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.getPositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary.prototype.setPositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.toObject = function(includeInstance, msg) {
  var f, obj = {
    treeId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    sourceAccountId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    sourcePositionId: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTreeId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourceAccountId(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setSourcePositionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTreeId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSourceAccountId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getSourcePositionId();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 tree_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.getTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.setTreeId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 source_account_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.getSourceAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.setSourceAccountId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 source_position_id = 3;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.getSourcePositionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching.prototype.setSourcePositionId = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.toObject = function(includeInstance, msg) {
  var f, obj = {
    cardId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    poolPartId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool;
  return proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCardId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPoolPartId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCardId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getPoolPartId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 card_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.getCardId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.setCardId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 pool_part_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.getPoolPartId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool.prototype.setPoolPartId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional Bonus.Kind.Id bonus_id = 1;
 * @return {!proto.mlmbox.types.Bonus.Kind.Id}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getBonusId = function() {
  return /** @type {!proto.mlmbox.types.Bonus.Kind.Id} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.mlmbox.types.Bonus.Kind.Id} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setBonusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional SourceMatrix matrix = 2;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getMatrix = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix, 2));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceMatrix|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setMatrix = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearMatrix = function() {
  return this.setMatrix(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasMatrix = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CardPool card_partner_dao = 3;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getCardPartnerDao = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool, 3));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setCardPartnerDao = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearCardPartnerDao = function() {
  return this.setCardPartnerDao(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasCardPartnerDao = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional CardPool card_bonus_hunter = 4;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getCardBonusHunter = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool, 4));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.CardPool|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setCardBonusHunter = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearCardBonusHunter = function() {
  return this.setCardBonusHunter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasCardBonusHunter = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setDescription = function(value) {
  return jspb.Message.setOneofField(this, 5, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearDescription = function() {
  return jspb.Message.setOneofField(this, 5, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional SourceBinary binary = 6;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getBinary = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary, 6));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinary|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setBinary = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearBinary = function() {
  return this.setBinary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasBinary = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional SourceBinaryMatching binary_matching = 7;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.getBinaryMatching = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching, 7));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.SourceBinaryMatching|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.setBinaryMatching = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.clearBinaryMatching = function() {
  return this.setBinaryMatching(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus.prototype.hasBinaryMatching = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setDescription = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearDescription = function() {
  return jspb.Message.setOneofField(this, 1, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasDescription = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Withdrawal withdrawal = 2;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getWithdrawal = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal, 2));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Withdrawal|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setWithdrawal = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearWithdrawal = function() {
  return this.setWithdrawal(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasWithdrawal = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional uint32 keeper_initial_tree_id = 3;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getKeeperInitialTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setKeeperInitialTreeId = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearKeeperInitialTreeId = function() {
  return jspb.Message.setOneofField(this, 3, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasKeeperInitialTreeId = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional PositionHeader keeper_reinvest = 4;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getKeeperReinvest = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader, 4));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setKeeperReinvest = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearKeeperReinvest = function() {
  return this.setKeeperReinvest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasKeeperReinvest = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Bonus bonus = 5;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getBonus = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus, 5));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Bonus|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setBonus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearBonus = function() {
  return this.setBonus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasBonus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint32 account_id = 6;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getAccountId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setAccountId = function(value) {
  return jspb.Message.setOneofField(this, 6, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearAccountId = function() {
  return jspb.Message.setOneofField(this, 6, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasAccountId = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional PositionHeader position_created = 7;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getPositionCreated = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader, 7));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.PositionHeader|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setPositionCreated = function(value) {
  return jspb.Message.setOneofWrapperField(this, 7, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearPositionCreated = function() {
  return this.setPositionCreated(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasPositionCreated = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Asset.Amount amount = 8;
 * @return {?proto.mlmbox.types.Asset.Amount}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getAmount = function() {
  return /** @type{?proto.mlmbox.types.Asset.Amount} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_asset_pb.Asset.Amount, 8));
};


/**
 * @param {?proto.mlmbox.types.Asset.Amount|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setAmount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 8, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearAmount = function() {
  return this.setAmount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasAmount = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional uint32 keeper_booking_tree_id = 9;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getKeeperBookingTreeId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setKeeperBookingTreeId = function(value) {
  return jspb.Message.setOneofField(this, 9, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearKeeperBookingTreeId = function() {
  return jspb.Message.setOneofField(this, 9, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasKeeperBookingTreeId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Deposit deposit = 10;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getDeposit = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit, 10));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.Deposit|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setDeposit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 10, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearDeposit = function() {
  return this.setDeposit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasDeposit = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional Account.Info account = 11;
 * @return {?proto.mlmbox.types.Account.Info}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getAccount = function() {
  return /** @type{?proto.mlmbox.types.Account.Info} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_account_pb.Account.Info, 11));
};


/**
 * @param {?proto.mlmbox.types.Account.Info|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setAccount = function(value) {
  return jspb.Message.setOneofWrapperField(this, 11, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearAccount = function() {
  return this.setAccount(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasAccount = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional uint32 transaction_id = 12;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.setTransactionId = function(value) {
  return jspb.Message.setOneofField(this, 12, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.clearTransactionId = function() {
  return jspb.Message.setOneofField(this, 12, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.Metadata.prototype.hasTransactionId = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional uint32 transaction_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getTransactionId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setTransactionId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional Kind.Id kind_id = 2;
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getKindId = function() {
  return /** @type {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction.Kind.Id} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setKindId = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional Status.Transaction status_id = 3;
 * @return {!proto.mlmbox.types.Status.Transaction}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getStatusId = function() {
  return /** @type {!proto.mlmbox.types.Status.Transaction} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.mlmbox.types.Status.Transaction} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setStatusId = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string amount = 4;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string fee = 5;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string balance = 6;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 created_at = 7;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getCreatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setCreatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional Metadata metadata = 8;
 * @return {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.getMetadata = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction.Metadata, 8));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.Transaction.Metadata|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
*/
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction} returns this
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.Transaction.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 8) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.toObject = function(includeInstance, msg) {
  var f, obj = {
    used: jspb.Message.getFieldWithDefault(msg, 1, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 2, ""),
    available: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity;
  return proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsed(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapacity(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAvailable(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsed();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCapacity();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAvailable();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string used = 1;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.getUsed = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} returns this
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.setUsed = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string capacity = 2;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} returns this
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string available = 3;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.getAvailable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} returns this
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.setAvailable = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool enabled = 4;
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} returns this
 */
proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Asset.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Asset.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Asset.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Asset.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Wallet.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Asset.List}
 */
proto.mlmbox.types.Wallet.Asset.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Asset.List;
  return proto.mlmbox.types.Wallet.Asset.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Asset.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Asset.List}
 */
proto.mlmbox.types.Wallet.Asset.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Asset;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Asset.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Asset.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Asset.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Asset.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Asset.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Asset.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Asset items = 1;
 * @return {!Array<!proto.mlmbox.types.Wallet.Asset>}
 */
proto.mlmbox.types.Wallet.Asset.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Asset, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Asset>} value
 * @return {!proto.mlmbox.types.Wallet.Asset.List} returns this
*/
proto.mlmbox.types.Wallet.Asset.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Asset}
 */
proto.mlmbox.types.Wallet.Asset.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Wallet.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Asset.List} returns this
 */
proto.mlmbox.types.Wallet.Asset.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string asset_balance = 2;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getAssetBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.setAssetBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Transaction transactions = 3;
 * @return {!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Asset.Transaction, 3));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Asset.Transaction>} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
*/
proto.mlmbox.types.Wallet.Asset.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Asset.Transaction}
 */
proto.mlmbox.types.Wallet.Asset.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.types.Wallet.Asset.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional Bonus.Summary income_bonuses_statistics = 4;
 * @return {?proto.mlmbox.types.Bonus.Summary}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getIncomeBonusesStatistics = function() {
  return /** @type{?proto.mlmbox.types.Bonus.Summary} */ (
    jspb.Message.getWrapperField(this, mlmbox_types_bonus_pb.Bonus.Summary, 4));
};


/**
 * @param {?proto.mlmbox.types.Bonus.Summary|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
*/
proto.mlmbox.types.Wallet.Asset.prototype.setIncomeBonusesStatistics = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.clearIncomeBonusesStatistics = function() {
  return this.setIncomeBonusesStatistics(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.prototype.hasIncomeBonusesStatistics = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional WithdrawalCapacity withdrawal_capacity = 5;
 * @return {?proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getWithdrawalCapacity = function() {
  return /** @type{?proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity} */ (
    jspb.Message.getWrapperField(this, proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity, 5));
};


/**
 * @param {?proto.mlmbox.types.Wallet.Asset.WithdrawalCapacity|undefined} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
*/
proto.mlmbox.types.Wallet.Asset.prototype.setWithdrawalCapacity = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.clearWithdrawalCapacity = function() {
  return this.setWithdrawalCapacity(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.prototype.hasWithdrawalCapacity = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bool settings_transfer_enabled = 6;
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getSettingsTransferEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.setSettingsTransferEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional string settings_transfer_limit_min = 7;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Asset.prototype.getSettingsTransferLimitMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Asset} returns this
 */
proto.mlmbox.types.Wallet.Asset.prototype.setSettingsTransferLimitMin = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Processing.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    processingName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    assetsList: jspb.Message.toObjectList(msg.getAssetsList(),
    proto.mlmbox.types.Wallet.Processing.Asset.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing}
 */
proto.mlmbox.types.Wallet.Processing.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing;
  return proto.mlmbox.types.Wallet.Processing.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing}
 */
proto.mlmbox.types.Wallet.Processing.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessingName(value);
      break;
    case 3:
      var value = new proto.mlmbox.types.Wallet.Processing.Asset;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Processing.Asset.deserializeBinaryFromReader);
      msg.addAssets(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getProcessingName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAssetsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.mlmbox.types.Wallet.Processing.Asset.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.Id}
 */
proto.mlmbox.types.Wallet.Processing.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.Id;
  return proto.mlmbox.types.Wallet.Processing.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.Id}
 */
proto.mlmbox.types.Wallet.Processing.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.Id.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Id} returns this
 */
proto.mlmbox.types.Wallet.Processing.Id.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.Asset.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.toObject = function(includeInstance, msg) {
  var f, obj = {
    assetId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingsDepositEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    settingsWithdrawalEnabled: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    settingsWithdrawalFeeBase: jspb.Message.getFieldWithDefault(msg, 4, ""),
    settingsWithdrawalFeePercent: jspb.Message.getFieldWithDefault(msg, 5, ""),
    settingsWithdrawalOperationValueMin: jspb.Message.getFieldWithDefault(msg, 6, ""),
    settingsWithdrawalOperationValueMax: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset}
 */
proto.mlmbox.types.Wallet.Processing.Asset.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.Asset;
  return proto.mlmbox.types.Wallet.Processing.Asset.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset}
 */
proto.mlmbox.types.Wallet.Processing.Asset.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettingsDepositEnabled(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettingsWithdrawalEnabled(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalFeeBase(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalFeePercent(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalOperationValueMin(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettingsWithdrawalOperationValueMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.Asset.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSettingsDepositEnabled();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getSettingsWithdrawalEnabled();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSettingsWithdrawalFeeBase();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSettingsWithdrawalFeePercent();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSettingsWithdrawalOperationValueMin();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSettingsWithdrawalOperationValueMax();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.Asset.Id.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.Id} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.toObject = function(includeInstance, msg) {
  var f, obj = {
    processingId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    assetId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.Id}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.Asset.Id;
  return proto.mlmbox.types.Wallet.Processing.Asset.Id.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.Id} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.Id}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setProcessingId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setAssetId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.Asset.Id.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.Id} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProcessingId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getAssetId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.Id} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 asset_id = 2;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.Id} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.Id.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory;
  return proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    address: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastUsedAt: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value;
  return proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddress(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastUsedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAddress();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastUsedAt();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
};


/**
 * optional string address = 3;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.getAddress = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.setAddress = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional uint32 last_used_at = 4;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.getLastUsedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value.prototype.setLastUsedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * repeated Value items = 1;
 * @return {!Array<!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value>}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value>} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory} returns this
*/
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value}
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.WithdrawalAddressHistory.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 asset_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getAssetId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setAssetId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional bool settings_deposit_enabled = 2;
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsDepositEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsDepositEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool settings_withdrawal_enabled = 3;
 * @return {boolean}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsWithdrawalEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsWithdrawalEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string settings_withdrawal_fee_base = 4;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsWithdrawalFeeBase = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsWithdrawalFeeBase = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string settings_withdrawal_fee_percent = 5;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsWithdrawalFeePercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsWithdrawalFeePercent = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string settings_withdrawal_operation_value_min = 6;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsWithdrawalOperationValueMin = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsWithdrawalOperationValueMin = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string settings_withdrawal_operation_value_max = 7;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.getSettingsWithdrawalOperationValueMax = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset} returns this
 */
proto.mlmbox.types.Wallet.Processing.Asset.prototype.setSettingsWithdrawalOperationValueMax = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.mlmbox.types.Wallet.Processing.List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.mlmbox.types.Wallet.Processing.List.prototype.toObject = function(opt_includeInstance) {
  return proto.mlmbox.types.Wallet.Processing.List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.mlmbox.types.Wallet.Processing.List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.List.toObject = function(includeInstance, msg) {
  var f, obj = {
    itemsList: jspb.Message.toObjectList(msg.getItemsList(),
    proto.mlmbox.types.Wallet.Processing.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.mlmbox.types.Wallet.Processing.List}
 */
proto.mlmbox.types.Wallet.Processing.List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.mlmbox.types.Wallet.Processing.List;
  return proto.mlmbox.types.Wallet.Processing.List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.mlmbox.types.Wallet.Processing.List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.mlmbox.types.Wallet.Processing.List}
 */
proto.mlmbox.types.Wallet.Processing.List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.mlmbox.types.Wallet.Processing;
      reader.readMessage(value,proto.mlmbox.types.Wallet.Processing.deserializeBinaryFromReader);
      msg.addItems(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.mlmbox.types.Wallet.Processing.List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.mlmbox.types.Wallet.Processing.List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.mlmbox.types.Wallet.Processing.List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.mlmbox.types.Wallet.Processing.List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getItemsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.mlmbox.types.Wallet.Processing.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Processing items = 1;
 * @return {!Array<!proto.mlmbox.types.Wallet.Processing>}
 */
proto.mlmbox.types.Wallet.Processing.List.prototype.getItemsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Processing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Processing, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Processing>} value
 * @return {!proto.mlmbox.types.Wallet.Processing.List} returns this
*/
proto.mlmbox.types.Wallet.Processing.List.prototype.setItemsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Processing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Processing}
 */
proto.mlmbox.types.Wallet.Processing.List.prototype.addItems = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Wallet.Processing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Processing.List} returns this
 */
proto.mlmbox.types.Wallet.Processing.List.prototype.clearItemsList = function() {
  return this.setItemsList([]);
};


/**
 * optional uint32 processing_id = 1;
 * @return {number}
 */
proto.mlmbox.types.Wallet.Processing.prototype.getProcessingId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.mlmbox.types.Wallet.Processing} returns this
 */
proto.mlmbox.types.Wallet.Processing.prototype.setProcessingId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string processing_name = 2;
 * @return {string}
 */
proto.mlmbox.types.Wallet.Processing.prototype.getProcessingName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.mlmbox.types.Wallet.Processing} returns this
 */
proto.mlmbox.types.Wallet.Processing.prototype.setProcessingName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated Asset assets = 3;
 * @return {!Array<!proto.mlmbox.types.Wallet.Processing.Asset>}
 */
proto.mlmbox.types.Wallet.Processing.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Processing.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Processing.Asset, 3));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Processing.Asset>} value
 * @return {!proto.mlmbox.types.Wallet.Processing} returns this
*/
proto.mlmbox.types.Wallet.Processing.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Processing.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Processing.Asset}
 */
proto.mlmbox.types.Wallet.Processing.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.mlmbox.types.Wallet.Processing.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet.Processing} returns this
 */
proto.mlmbox.types.Wallet.Processing.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Asset assets = 1;
 * @return {!Array<!proto.mlmbox.types.Wallet.Asset>}
 */
proto.mlmbox.types.Wallet.prototype.getAssetsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Asset>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Asset, 1));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Asset>} value
 * @return {!proto.mlmbox.types.Wallet} returns this
*/
proto.mlmbox.types.Wallet.prototype.setAssetsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Asset=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Asset}
 */
proto.mlmbox.types.Wallet.prototype.addAssets = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.mlmbox.types.Wallet.Asset, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet} returns this
 */
proto.mlmbox.types.Wallet.prototype.clearAssetsList = function() {
  return this.setAssetsList([]);
};


/**
 * repeated Processing processings = 2;
 * @return {!Array<!proto.mlmbox.types.Wallet.Processing>}
 */
proto.mlmbox.types.Wallet.prototype.getProcessingsList = function() {
  return /** @type{!Array<!proto.mlmbox.types.Wallet.Processing>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.mlmbox.types.Wallet.Processing, 2));
};


/**
 * @param {!Array<!proto.mlmbox.types.Wallet.Processing>} value
 * @return {!proto.mlmbox.types.Wallet} returns this
*/
proto.mlmbox.types.Wallet.prototype.setProcessingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.mlmbox.types.Wallet.Processing=} opt_value
 * @param {number=} opt_index
 * @return {!proto.mlmbox.types.Wallet.Processing}
 */
proto.mlmbox.types.Wallet.prototype.addProcessings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.mlmbox.types.Wallet.Processing, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.mlmbox.types.Wallet} returns this
 */
proto.mlmbox.types.Wallet.prototype.clearProcessingsList = function() {
  return this.setProcessingsList([]);
};


goog.object.extend(exports, proto.mlmbox.types);
